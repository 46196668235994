import React, { useEffect, useState } from 'react';
import { config } from '../Constants'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import './Navbar.css'
import StorefrontIcon from '@mui/icons-material/Storefront';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import LanguageIcon from '@mui/icons-material/Language';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LogoutIcon from '@mui/icons-material/Logout';
import ShareIcon from '@mui/icons-material/Share';
import WidgetsIcon from '@mui/icons-material/Widgets';
import PollIcon from '@mui/icons-material/Poll';
import { PersonOutlineOutlined } from '@mui/icons-material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import InsightsIcon from '@mui/icons-material/Insights';
import Box from '@mui/material/Box';

const navListItemIconStyle = {
    minWidth: "38px"
}

const navListStyle = {
    padding: "0.5rem"
}

const drawerWidth = 260;

function NavLink({ to, label, pathname, icon }) {
    return <Link to={to} className={pathname === to ? "selected" : ""}>
        <ListItem disablePadding>
            <ListItemButton>
                <ListItemIcon sx={navListItemIconStyle}>{icon}</ListItemIcon>
                <ListItemText primary={label} />
            </ListItemButton>
        </ListItem>
    </Link>
}

function ExpandableList({ icon, label, items, pathname, listId }) {
    const [expanded, setExpanded] = useState(false)

    useEffect(() => {
        setExpanded(items.findIndex(ele => ele.to === pathname) > -1)
    }, [pathname])

    return <List id={listId} sx={navListStyle} className={'nav-list' + (expanded ? " expanded" : "")} style={{ minHeight: expanded ? `${62 + 46.5 * items.length}px` : "62px" }}>
        <ListItem disablePadding className='label' onClick={() => setExpanded(!expanded)}>
            <ListItemButton>
                <ListItemIcon sx={navListItemIconStyle}>{icon}</ListItemIcon>
                <ListItemText primary={label} />
            </ListItemButton>
        </ListItem>
        {items.map(item => <Link key={item.to} to={item.to} className={pathname === item.to ? "selected" : ""}>
            <ListItem disablePadding>
                <ListItemButton disabled={!expanded}>
                    <ListItemText primary={item.label} />
                </ListItemButton>
            </ListItem>
        </Link>)}
    </List>
}

const appVersion = "5.4.0"

function RefreshVersionButton({ version }) {
    const { t } = useTranslation('app');

    if (version && version > appVersion) return <div style={{
        width: "100%",
        background: "white",
        padding: "1rem"
    }} onClick={() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                if (registrations.length) {
                    Promise.all(registrations.map(r => r.unregister())).then((values) => {
                        caches.delete(`workbox-precache-v2-${window.location.origin}/`).then(_ => document.location.reload(true))
                    });
                } else {
                    caches.delete(`workbox-precache-v2-${window.location.origin}/`).then(_ => document.location.reload(true))
                }
            });
        } else {
            caches.delete(`workbox-precache-v2-${window.location.origin}/`).then(_ => document.location.reload(true))
        }
    }}>
        <div style={{ width: "100%", padding: "0.5rem", textAlign: "center", background: "#FF4E60", borderRadius: "4px" }} className="clickable">
            <b>{t('update')}</b>
        </div>
    </div>

    return <></>
}

function Navbar({ metadata, solutions, pathname, onLogout }) {
    const { t } = useTranslation('app');
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [version, setVersion] = useState(null)


    const largeScreen = window.innerWidth > 1023

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const name = (function () {
        if (metadata.name) return metadata.name
        if (metadata.domain) return metadata.domain
        if (metadata.alias) return metadata.alias
        if (!metadata.alias) return "Take a Seat"
    })()

    useEffect(() => {
        fetch(`${config.url.ADMIN}/static/version.json`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(resp => {
            if (resp.status === 200) resp.json().then(data => setVersion(data["version"]))
        })
    }, [])

    // On new page, scoll to the top
    useEffect(() => {
        document.getElementById("root").scrollTo(0, 0)
        setMobileOpen(false)
    }, [pathname])

    return <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    >
        {!largeScreen && <div style={{ padding: "1rem", position: "fixed", top: 0, display: "flex", zIndex: "1001", fontWeight: "bold", width: "100%", background: "white" }}>
            <div onClick={handleDrawerToggle}><MenuIcon /></div>
            <div style={{ margin: "auto" }}>{t(`sidebar${pathname.replace(/[\/]/ig, ".")}-t`)}</div>
            {/* {(metadata.logo && metadata.logo.src) && <div style={{ background: `url("${config.url.PUBLIC}/restaurants/${metadata.id}/${metadata.logo.src}") center center / contain no-repeat`, padding: "0", height: "1.5rem", width: "1.5rem", filter: "none" }}> </div>} */}
        </div>}
        <Drawer
            id="nav"
            sx={{
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            variant={largeScreen ? "permanent" : "temporary"}
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <RefreshVersionButton version={version} />
            <div style={{ padding: "1rem", paddingLeft: "2rem" }}>
                <li style={{ display: "flex" }}>
                    {(metadata.logo?.src) && <div style={{ background: `url("${config.url.PUBLIC}/restaurants/${metadata.id}/${metadata.logo.src}") center center / contain no-repeat`, padding: "0", margin: "0 1rem", height: "2.5rem", width: "2.5rem", filter: "none" }}></div>}
                    <div style={{ fontSize: "125%" }}><b>{name}</b></div>
                </li>
            </div>
            <List sx={navListStyle}>
                <NavLink to={"/account"} label={t('sidebar.account-t')} icon={<PersonOutlineOutlined />} pathname={pathname} />
            </List>
            <List sx={navListStyle}>
                <NavLink to={"/business-info"} label={t('sidebar.business-info-t')} icon={<StorefrontIcon />} pathname={pathname} />
            </List>
            {
                solutions.includes('digital-menu') && <>
                    <ExpandableList listId={'digital-menu'} pathname={pathname} icon={<RestaurantMenuIcon />} label={t('sidebar.title-t')} items={["edit", "appearance", "qrcode", "messages", "tracing", "paper-menu", "analytics"].map(ele => ({
                        to: `/${ele}`,
                        label: t(`sidebar.${ele}-t`)
                    })
                    )} />
                </>
            }
            {
                solutions.includes('public-page') && <>
                    <ExpandableList listId={'public-page'} pathname={pathname} icon={<LanguageIcon />} label={t('sidebar.website.title-t')} items={["edit", "design", "qrcode"].map(ele => ({
                        to: `/website/${ele}`,
                        label: t(`sidebar.website.${ele}-t`)
                    })
                    )} />
                </>
            }
            {
                solutions.includes('ordering') && <>
                    <ExpandableList listId={'ordering'} pathname={pathname} icon={<ReceiptIcon />} label={t('sidebar.orders-t')} items={[
                        {
                            to: `/orders`,
                            label: t(`sidebar.orders-t`)
                        },
                        ...["settings", "reporting", "coupons"].map(ele => ({
                            to: `/orders/${ele}`,
                            label: t(`sidebar.orders.${ele}-t`)
                        })
                        ),
                    ]
                        .concat((metadata.id === "30bab728-6e1b-4f4a-9042-2ef50113e2db" || metadata.id === "a7b5b839-5de9-4d8c-bff1-36ee30eb5034") ? ["tags", "invoices"].map(ele => ({
                            to: `/orders/${ele}`,
                            label: t(`sidebar.orders.${ele}-t`)
                        })) : [])
                        .concat((metadata.id === "4a4ca98c-9a6e-487c-ba6f-317eb9b03c2d") ? ({
                            to: `/orders/promotions`,
                            label: "Promotions"
                        }) : [])
                    } />
                </>
            }
            {
                solutions.includes('table-reservation') && <>
                    <ExpandableList listId={'table-reservation'} pathname={pathname} icon={<TableRestaurantIcon />} label={t('sidebar.reservations-t')} items={[
                        {
                            to: `/reservations`,
                            label: t(`sidebar.reservations-t`)
                        },
                        ...["tables", "settings"].map(ele => ({
                            to: `/reservations/${ele}`,
                            label: t(`sidebar.reservations.${ele}-t`)
                        })
                        )
                    ]} />
                </>
            }
            {
                solutions.includes('marketing') && <>
                    <List sx={navListStyle}>
                        <NavLink listId={'marketing'} to={"/marketing"} label={"Marketing BETA"} icon={<ShareIcon />} pathname={pathname} />
                    </List>
                </>
            }
            {
                solutions.includes('surveys') && <>
                    <ExpandableList listId={'surveys'} pathname={pathname} icon={<PollIcon />} label={"Surveys"} items={[
                        {
                            to: `/surveys`,
                            label: "Edit surveys"
                        },
                        {
                            to: `/surveys/qrcode`,
                            label: "QR codes & Links"
                        },
                        {
                            to: `/surveys/results`,
                            label: "Survey results"
                        },
                    ]} />
                </>
            }
            {/* <ExpandableList pathname={pathname} icon={<InsightsIcon />} label={"Customer insights"} items={[
                {
                    to: `/insights/activity`,
                    label: "Customer activity"
                },
                {
                    to: `/insights/accounts`,
                    label: "Customer accounts"
                },
            ]} /> */}
            <List sx={navListStyle}>
                <NavLink to={"/appstore"} label={t('sidebar.functionalities-t')} icon={<WidgetsIcon />} pathname={pathname} />
            </List>
            <Divider style={{ marginTop: "auto" }} />
            <List sx={navListStyle}>
                <NavLink to={"/support"} label={t('sidebar.support-t')} icon={<SupportAgentIcon />} pathname={pathname} />
                <ListItem disablePadding onClick={onLogout}>
                    <ListItemButton>
                        <ListItemIcon sx={navListItemIconStyle}>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('sidebar.logout')} />
                    </ListItemButton>
                </ListItem>
                <ListItem style={{ color: "grey", textAlign: "center", fontSize: "80%" }}>
                    Version: {appVersion}
                </ListItem>
            </List>
        </Drawer >
    </Box>
}

export default Navbar;
