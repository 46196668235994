import React, { useEffect, useState } from 'react';
import { config } from '../Constants'
import { useTranslation } from "react-i18next";
import { ContentCopy } from '@mui/icons-material';


export default function PreviewView({ restaurantId, websiteData, show, refresh, saveCallback, alias, language, customDomain }) {
    const [iref, setIref] = useState(null)
    const [showWebsite, setShowWebsite] = useState(true)
    const { t } = useTranslation('app');

    const websiteUrl = config.url.PREVIEW + (language === "en" ? '' : `/${language}`) + `/?utm_source=takeaseat-admin-preview&context=preview&id=${alias || restaurantId}`

    useEffect(() => {
        if (iref) iref.contentWindow.postMessage({ nature: "website-metadata", content: websiteData }, config.url.PREVIEW)
    }, [websiteData, show, refresh])

    useEffect(() => setShowWebsite(show), [show])

    return <div className={"preview-content"}>
        <div className={"preview-device"}>
            <div className={"smartphone"}>
                <img src="/static/media/phone-facing-2.png" alt="Phone case" />
                <div>
                    <iframe
                        src={websiteUrl}
                        width="351px"
                        height="720px"
                        display="initial"
                        position="relative"
                        ref={(iframeRef) => setIref(iframeRef)} />
                </div>
            </div>
            {!showWebsite && <div className="save-button unsaved" onClick={saveCallback}>{t('edit.save')}</div>}
        </div>
        <div style={{ textAlign: "center" }}>
            <a href={customDomain ? customDomain : config.url.SITES.replace('$1', alias || restaurantId)} target="_blank">
                {customDomain ? customDomain : config.url.SITES.replace('$1', alias || restaurantId)}
            </a>
            &#160;
            <ContentCopy sx={{ cursor: "pointer" }} onClick={() => {
                navigator.clipboard.writeText(config.url.SITES.replace('$1', alias || restaurantId))
                alert("Link copied!")
            }} />
        </div>
    </div>
}