import React, { Component } from 'react';
import { Formik } from "formik";
import qs from "qs";
import { config } from './Constants'
import { withTranslation } from "react-i18next";
import LanguagePicker from "./LanguagePicker";
import validator from 'validator'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import './RegisterPage.css';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Link } from 'react-router-dom';


const DuplicateEmail = ({ t }) => {
	return <div className="email requirements">
		<div>{t('create-account.errors.duplicated-email')} <Link to='/login'>Login</Link></div>
	</div>
}

class RegisterPage extends Component {
	constructor(props) {
		super(props);

		this.solution = qs.parse(window.location.search, { ignoreQueryPrefix: true }).solution || 'all';
		// this.solutions = [this.solution]

		this.referral = qs.parse(window.location.search, { ignoreQueryPrefix: true }).referral || null;

		this.state = {
			registered: false,
			showPassword: false,
			email: "",
			defaultEmail: "",
			tier: qs.parse(window.location.search, { ignoreQueryPrefix: true }).tier || "standard",
			view: "solutions",
			menuSolution: ["digital-menu", "all"].includes(this.solution),
			websiteSolution: this.solution === "website",
			orderingSolution: false,
			tableReservationSolution: this.solution === "table-reservation",
			surveysSolution: false
		};
	}

	componentDidMount() {
		window.gtag('event', 'start_sign_up', { 'product': this.solution || 'none' });
		const forcedEmail = qs.parse(window.location.search, { ignoreQueryPrefix: true }).email;
		if (forcedEmail != null) {
			this.setState({ defaultEmail: forcedEmail })
		}
	}

	render() {
		const { t, i18n } = this.props;

		const validationFunction = function accountFormValidation(values) {
			const errors = {};
			if (!values.email) {
				errors.email = t('create-account.errors.required');
			} else if (!validator.isEmail(values.email)) {
				errors.email = t('create-account.errors.invalid-email');
			}
			if (!values.password) {
				errors.password = "Required";
			} else if (!validator.isStrongPassword(values.password, { minLength: 0, minLowercase: 0, minUppercase: 0, minNumbers: 0, minSymbols: 0 })) {
				errors.password = "weak"
			}

			return errors;
		}

		const EmailRequirements = ({ email, show }) => {
			return <div className={"email requirements" + (show ? "" : " hide")}>
				{(!email) ?
					<div className="requirement">{t('create-account.errors.required')}</div>
					:
					<div className="requirement">{t('create-account.errors.invalid-email')}</div>
				}
			</div>
		}

		return <div id="login-page" className="register">
			<div className="lg-screen" style={{ position: "absolute", width: "100%", zIndex: "1", top: "1rem", paddingLeft: "1rem", display: "flex", justifyContent: "space-between" }}>
				<a className="appbar-logo" href={config.url.LANDING}>
					<img alt="Take a Seat logo" src={`https://takeaseat.io/media/icons/tas-230526.svg`} />
					<div>Take a Seat</div>
				</a>
				<LanguagePicker />
			</div>
			<div>
				<picture>
					<source srcSet={"/static/media/register-bg.webp"} type="image/webp" width="0" height="0" loading="lazy" />
					<source srcSet={"/static/media/register-bg.jpg"} type="image/jpeg" width="0" height="0" loading="lazy" />
					<img alt="Setup process" style={{ width: "100vw", height: "100vh", filter: "blur(7px)", objectFit: "cover" }} width="0" height="0" loading="lazy" />
				</picture>
			</div>
			<div className={"login-page-content"}>
				<div className="logo"></div>
				{this.state.view === "solutions" ? <>
					<h1>{t('solutions.title')}</h1>
					<div style={{ minHeight: "15rem", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
						<div className={"solution-selector" + (this.state.menuSolution ? " selected" : "")} onClick={() => {
							this.setState(prev => { return { ...prev, menuSolution: !prev.menuSolution } })
						}}>
							<div>{t('solutions.menus')}</div>
							<div>{this.state.menuSolution ? <TaskAltIcon /> : <RadioButtonUncheckedIcon />} </div>
						</div>
						<div className={"solution-selector" + (this.state.orderingSolution ? " selected" : "")} onClick={() => {
							this.setState(prev => { return { ...prev, orderingSolution: !prev.orderingSolution } })
						}}>
							<div>{t('solutions.order')}</div>
							<div>{this.state.orderingSolution ? <TaskAltIcon /> : <RadioButtonUncheckedIcon />} </div>
						</div>
						<div className={"solution-selector" + (this.state.websiteSolution ? " selected" : "")} onClick={() => {
							this.setState(prev => { return { ...prev, websiteSolution: !prev.websiteSolution } })
						}}>
							<div>{t('solutions.website')}</div>
							<div>{this.state.websiteSolution ? <TaskAltIcon /> : <RadioButtonUncheckedIcon />} </div>
						</div>
						<div className={"solution-selector" + (this.state.tableReservationSolution ? " selected" : "")} onClick={() => {
							this.setState(prev => { return { ...prev, tableReservationSolution: !prev.tableReservationSolution } })
						}}>
							<div>{t('solutions.resa')}</div>
							<div>{this.state.tableReservationSolution ? <TaskAltIcon /> : <RadioButtonUncheckedIcon />} </div>
						</div>
						<div className={"solution-selector" + (this.state.surveysSolution ? " selected" : "")} onClick={() => {
							this.setState(prev => { return { ...prev, surveysSolution: !prev.surveysSolution } })
						}}>
							<div>{t('solutions.surveys')}</div>
							<div>{this.state.surveysSolution ? <TaskAltIcon /> : <RadioButtonUncheckedIcon />} </div>
						</div>
						<div className="tas-button" style={{ width: "100%", padding: "1rem" }}
							onClick={() => this.setState({ view: "email" })}>{t('create')}</div>
					</div>
				</> :
					(this.state.registered ? <>
						<h1>{t('create-account.validation-title')}</h1>
						<div style={{ minHeight: "15rem", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
							<div style={{ margin: "1rem", textAlign: "center" }}>
								<p style={{ marginBottom: "0" }}>{t('sent-verification-email')}</p>
							</div>
							{this.state.email.endsWith("gmail.com") && <a href="https://gmail.com" target="_blank" style={{ display: "flex" }}> <div>{t('to-emails')}</div> <div>&#160;Gmail&#160;</div> <MailOutlineIcon /></a>}
							{this.state.email.endsWith("yahoo.com") && <a href="https://login.yahoo.com" target="_blank" style={{ display: "flex" }}> <div>{t('to-emails')}</div> <div>&#160;Yahoo mails&#160;</div><MailOutlineIcon /></a>}
							{this.state.email.endsWith("hotmail.com") && <a href="https://outlook.live.com/mail" target="_blank" style={{ display: "flex" }}> <div>{t('to-emails')}</div> <div>&#160;Hotmail&#160;</div><MailOutlineIcon /></a>}
							{this.state.email.endsWith("icloud.com") && <a href="https://www.icloud.com/mail" target="_blank" style={{ display: "flex" }}> <div>{t('to-emails')}</div> <div>&#160;iCloud emails&#160;</div> <MailOutlineIcon /></a>}
						</div>
						<div style={{ fontSize: "80%", color: "grey" }}>
							<div>{t('sent-to')}{this.state.email}</div>
							<div><a href="javascript:void(0);" onClick={() => this.setState({ registered: false, defaultEmail: this.state.email, defaultPassword: this.state.password })}>{t('update-email')}</a></div>
						</div>
					</> : <>
						<h1>{t('create-account.title')}</h1>
						<Formik
							initialValues={{
								email: this.state.defaultEmail,
								password: this.state.defaultPassword || ''
							}}
							validate={validationFunction}
							onSubmit={(values, { setTouched, setSubmitting, setStatus }) => {
								const tasTier = (this.state.tier === 'professional') ? 'gold' : 'standard'
								var xhr = new XMLHttpRequest();
								xhr.open("POST", `${config.url.API}/register`);
								xhr.setRequestHeader('Content-Type', 'application/json');
								xhr.withCredentials = true;
								xhr.onload = function (e) {
									if (xhr.readyState === 4) {
										if (xhr.status === 200) {
											const ga_user = JSON.parse(xhr.responseText)["ga_user"];
											// First set the user_id to associate this session and future sessions to the
											// same underlying TaS user.
											window._env_.GA_CONFIGS.forEach((c) => {
												window.gtag("config", c, { 'user_id': ga_user });
											});

											// Method tag can be things like Facebook account, Google account, email.
											window.gtag('event', 'sign_up_unverified', { 'product': this.solution || 'none' });
											// We must set the default tier user_properties at some point for
											// the Google Analytics user. Even though the tier cannot be
											// changed during registration, it makes sense to set it when the
											// user first signs up.
											window.gtag('set', 'user_properties', { 'tier': 'standard' });
											window.gtag('event', 'conversion', { 'send_to': 'AW-642020249/wS2kCLeW4MsCEJnnkbIC' });

											// const restaurantId = JSON.parse(xhr.responseText)["id"];
											this.setState({ registered: true });
										} else if (xhr.status === 400) {
											const reason = JSON.parse(xhr.responseText)["reason"];
											if (reason === "duplicate_email") {
												setStatus({ "email": "duplicate_email" });
											} else {
												alert('unexpected error: ' + xhr.statusText);
											}
											setSubmitting(false);
										} else {
											alert('unexpected error: ' + xhr.statusText);
											setSubmitting(false);
										}
									}
								}.bind(this);
								xhr.onerror = function (e) {

									console.error(xhr.statusText);
								};
								xhr.send(JSON.stringify({
									"email": values.email,
									"password": values.password,
									"lang": (i18n.language) ? i18n.language : "",
									"solution": 'all',
									"solutions": [
										this.state.menuSolution ? "digital-menu" : null,
										this.state.websiteSolution ? "public-page" : null,
										this.state.orderingSolution ? "ordering" : null,
										this.state.tableReservationSolution ? "table-reservation" : null,
										this.state.surveysSolution ? "surveys" : null,
									].filter(ele => ele != null),
									"referral": this.referral,
									"tier": tasTier
								}));
							}}
						>{({
							values,
							errors,
							touched,
							handleChange,
							handleSubmit,
							handleBlur,
							status,
							setStatus,
							isSubmitting,
						}) => {
							const emailError = errors.email && touched.email
							const passwordError = errors.password && touched.password
							return <form onSubmit={handleSubmit} autoComplete="off">
								<fieldset>
									<label htmlFor="email">{t('create-account.email')} <EmailRequirements email={values.email} show={emailError} /></label>
									<input type="email"
										name="email"
										id="email"
										onChange={e => {
											setStatus('');
											this.setState({ email: e.target.value })
											handleChange(e);
										}}
										style={{ border: "1px solid lightgrey", borderRadius: "4px" }}
										onBlur={handleBlur}
										value={values.email}
										className={emailError ? "has-error" : ""}
										tabIndex="1" autoFocus="true" />

									{(status && status.email === "duplicate_email") && <DuplicateEmail t={t} />}
									<label htmlFor="password">
										{t('create-account.password')}
										{(errors.password === "Required" && passwordError) &&
											<div className="requirements">
												<div className="requirement">{t("create-account.errors.required")}</div>
											</div>
										}</label>
									<div className="pwd-field">
										<input
											type={this.state.showPassword ? "text" : "password"}
											name="password"
											id="password"
											onChange={e => {
												this.setState({ password: e.target.value })
												handleChange(e);
											}}
											onBlur={handleBlur}
											value={values.password}
											className={passwordError ? "has-error" : ""}
											tabIndex="2" />
										<div className="visibility-icon">{
											(this.state.showPassword) ?
												<VisibilityIcon onClick={() => this.setState({ showPassword: false })} />
												:
												<VisibilityOffIcon onClick={() => this.setState({ showPassword: true })} />
										}</div>
									</div>
									{this.referral && <>
										<label htmlFor="referral">Referral ID:</label>
										<div className="pwd-field">
											<input
												type="text"
												name="referral"
												id="referral"
												value={this.referral}
												disabled={true}
												tabIndex="2" />
										</div>
									</>}
									<button type="submit" className="tas-button" disabled={isSubmitting}> {isSubmitting ? <div className="loader">Loading...</div> : t('create')}</button>
								</fieldset>
							</form>
						}}
						</Formik>
						<div className="login-sep"><span>OR</span></div>
						<div className="login-google" onClick={() => {
							const tasTier = (this.state.tier === 'professional') ? 'gold' : 'standard'

							this.setState({ isSubmittingGoogle: true })
							window.gtag('event', 'sign_up', { 'product': this.solution || 'none' });
							// We must set the default tier user_properties at some point for
							// the Google Analytics user. Even though the tier cannot be
							// changed during registration, it makes sense to set it when the
							// user first signs up.
							window.gtag('set', 'user_properties', { 'tier': 'standard' });
							window.gtag('event', 'conversion', { 'send_to': 'AW-642020249/qccQCNyj0e4YEJnnkbIC' });
							var window_location_href = `${config.url.API}/auth/google?tier=${tasTier}&solution=${this.solution}`
							if (this.state.menuSolution) window_location_href += "&solutions[]=digital-menu"
							if (this.state.websiteSolution) window_location_href += "&solutions[]=public-page"
							if (this.state.orderingSolution) window_location_href += "&solutions[]=ordering"
							if (this.state.tableReservationSolution) window_location_href += "&solutions[]=table-reservation"
							if (this.state.surveysSolution) window_location_href += "&solutions[]=surveys"

							window.location.href = window_location_href
						}} >
							{this.state.isSubmittingGoogle ? <div className="loader">Loading...</div> :
								<>
									<img src='/static/media/icons/google-icon.png' alt="Google icon" />
									<span>{t("google-sign-in")}</span>
								</>}
						</div>
						<div className="login-sep"></div>
						<div> {t("already-user")} <a href="/login">{t("login")}</a> </div>
					</>
					)}
			</div>
		</div >
	}
}

const RegisterPageTranslated = withTranslation('register')(RegisterPage)

export default RegisterPageTranslated;
