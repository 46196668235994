import React, { Component, useState, useEffect } from 'react';
import { config } from '../Constants';
import { useTranslation } from "react-i18next";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { contrastingColorBw } from '../utils/ColorUtils';
import { CustomDesign, Design, DesignLogo } from '../utils/Design';
import { Link } from 'react-router-dom';
import { PricingModalTrigger } from '../utils/Disclaimers'
import colors from 'nice-color-palettes'
import { SaveButton } from '../utils/Data';
import ColorThief from 'colorthief'
import { ColorPicker } from '../utils/ColorUtils'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { uploadLogo } from '../utils/Images';
import { AddPhotoAlternate, TaskAlt, RadioButtonUnchecked, Clear } from '@mui/icons-material';

const cssParse = require('css/lib/parse');
const colorThief = new ColorThief();


const themes = {
    "default": { "titleDecoration": "arrow", "name": "Take a Seat", "bgColor": "#023810", "secondaryColor": "#8fb374", "cardColor": "#ffffff", "sectionsFontColor": "#ffffff", "itemsFontColor": "#000000", "fontFamilySelected": "Nunito", "fontSizeSelected": 15, "showMenuLogo": true, "showSidebarLogo": true, specialEffects: "" },
    "light": { "titleDecoration": "wavy", "name": "Light", "bgColor": "#ffffff", "secondaryColor": "#ffffff", "cardColor": "#f2e9e1", "sectionsFontColor": "#121212", "itemsFontColor": "#121212", "fontFamilySelected": "Montserrat", "fontSizeSelected": 15, "showMenuLogo": true, "showSidebarLogo": true, specialEffects: "" },
    "wood": { "titleDecoration": "arrow", "name": "Wood", "bgColor": "#FFEFD3", "secondaryColor": "#FFEFD3", "cardColor": "undefined", "sectionsFontColor": "#000000", "itemsFontColor": "#000000", "fontFamilySelected": 'Arial, Courier, monospace', "fontSizeSelected": 16, "showMenuLogo": true, "showSidebarLogo": true, specialEffects: "" },
    "deepblue": { "titleDecoration": "wavy", "name": "Deep blue", "bgColor": "#040273", "secondaryColor": "#040273", "cardColor": "#ffffff", "sectionsFontColor": "#ffffff", "itemsFontColor": "#040273", "fontFamilySelected": '"Dancing Script", cursive', "fontSizeSelected": 20, "showMenuLogo": true, "showSidebarLogo": true, specialEffects: "" },
    "ocean-1": { "titleDecoration": "arrow", "name": "Ocean", "bgColor": "#ffffff", "secondaryColor": "#A6C6FF", "cardColor": "undefined", "sectionsFontColor": "#064273", "itemsFontColor": "#000000", "fontFamilySelected": "Montserrat", "fontSizeSelected": 15, "showMenuLogo": false, "showSidebarLogo": true, specialEffects: "" },
    "discreet": { "titleDecoration": "none", "name": "Smooth", "bgColor": "#ffffff", "secondaryColor": "#DFDFDF", "cardColor": "undefined", "sectionsFontColor": "#80a46d", "itemsFontColor": "#000000", "fontFamilySelected": "Montserrat", "fontSizeSelected": 15, "showMenuLogo": true, "showSidebarLogo": true, specialEffects: "" },
    "carbon": { "titleDecoration": "none", "name": "Carbon", "bgColor": "#000000", "secondaryColor": "#000000", "cardColor": "#56667A", "sectionsFontColor": "#ffffff", "itemsFontColor": "#ffffff", "fontFamilySelected": "Montserrat", "fontSizeSelected": 15, "showMenuLogo": true, "showSidebarLogo": true, specialEffects: "" },
    "halloween-2": { "titleDecoration": "wavy", "name": "Halloween", "bgColor": "#161513", "secondaryColor": "#eb6123", "cardColor": "#23252B", "sectionsFontColor": "#eb6123", "itemsFontColor": "#ffffff", "fontFamilySelected": "Montserrat", "fontSizeSelected": 15, "showMenuLogo": true, "showSidebarLogo": true, specialEffects: "" },
    "bonbon": { "titleDecoration": "none", "name": "Bonbon", "bgColor": "#FE4365", "secondaryColor": "#FE4365", "cardColor": "#FE4365", "sectionsFontColor": "#ffffff", "itemsFontColor": "#ffffff", "fontFamilySelected": "Nunito", "fontSizeSelected": 15, "showMenuLogo": true, "showSidebarLogo": true, specialEffects: "" },
    // "custom": { "titleDecoration": "none", "name": "Custom", "bgColor": "#FE4365", "secondaryColor": "#FE4365", "cardColor": "#FE4365", "sectionsFontColor": "#ffffff", "itemsFontColor": "#ffffff", "fontFamilySelected": "Montserrat", "fontSizeSelected": 15, "showMenuLogo": true, "showSidebarLogo": true, specialEffects: "" },
    // "custom": { "titleDecoration": this.state.titleDecoration, "name": "Custom", "bgColor": this.state.bgColorCustom, "secondaryColor": this.state.secondaryColorCustom, "cardColor": this.state.cardColorCustom, "sectionsFontColor": this.state.sectionsFontColorCustom, "itemsFontColor": this.state.itemsFontColorCustom, "fontFamilySelected": this.state.fontFamilySelectedCustom, "fontSizeSelected": this.state.fontSizeSelectedCustom, "showMenuLogo": this.state.showMenuLogoCustom, "showSidebarLogo": this.state.showSidebarLogoCustom, specialEffects: "" },
}

function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function cssToDict(cssContent) {
    // We convert the theme to a dictionary to simplify reading

    var themeVars = {}

    cssContent["stylesheet"]["rules"][0]["declarations"].forEach(element => {
        if (element["property"]) {
            if (element["value"].endsWith("px")) {
                themeVars[element["property"]] = parseInt(element["value"].split("px")[0])
            } else {
                themeVars[element["property"]] = element["value"]
            }
        }
    });
    return themeVars
}

const DesignEdit = ({ onDesignChangeCallback, onMetadataChangeCallback, iref, restaurantId, metadata, themeCss }) => {
    const [template, setTemplate] = useState(metadata.template ? metadata.template : 1)
    const [imagesPositioning, setImagesPositioning] = useState(metadata.imagesPositioning || 'under-image')
    const [bgColor, setBgColor] = useState("#ffffff")
    const [secondaryColor, setSecondaryColor] = useState("#ffffff")
    const [searchBarColor, setSearchBarColor] = useState("#121212")
    const [itemsFontColor, setItemsFontColor] = useState("#121212")
    const [sectionsFontColor, setSectionsFontColor] = useState("#121212")
    const [fontFamilySelected, setFontFamilySelected] = useState("Nunito")
    const [fontSizeSelected, setFontSizeSelected] = useState(15)
    const [cardColor, setCardColor] = useState("#f2e9e1")
    const [showMenuLogo, setShowMenuLogo] = useState(true)
    const [showSidebarLogo, setShowSidebarLogo] = useState(true)
    const [includeWatermark, setIncludeWatermark] = useState(metadata.include_watermark)
    const [selectedTheme, setSelectedTheme] = useState(metadata.theme || "")
    const [logoSrc, setLogoSrc] = useState(metadata.logo?.src || "")
    const [randomizeAi, setRandomizeAI] = useState(Math.random())
    const [uploading, setUploading] = useState("")
    const [showCustomOptions, setShowCustomOptions] = useState(false)
    const [logoLoaded, setLogoLoaded] = useState(null)
    const [firstRender, setFirstRender] = useState(true)
    /** TODO: translate */
    const { t } = useTranslation('app')

    useEffect(() => {
        // Load theme from file
        fetch(`${config.url.PUBLIC}/restaurants/${restaurantId}/themes/theme.css`, { method: 'GET' })
            .then(data => {
                if (data.status === 200) {
                    data.text()
                        .then((themeCss) => {
                            var parsedCss = cssParse(themeCss);
                            const cssVars = cssToDict(parsedCss)

                            // TODO: check that all vars exist
                            setSearchBarColor(contrastingColorBw(cssVars["--app-bar-bg"]))
                            setSecondaryColor(cssVars["--app-bar-bg"])
                            setBgColor(cssVars["--app-bg"])
                            setItemsFontColor(cssVars["--item-color"])
                            setSectionsFontColor(cssVars["--app-section-color"])
                            setFontFamilySelected(cssVars["--app-font"])
                            setFontSizeSelected(cssVars["--app-font-size"])
                            setCardColor(cssVars["--item-bg"])
                            setShowMenuLogo(cssVars["--menu-logo"] !== 'none')
                            setShowSidebarLogo(cssVars["--sidebar-logo"] !== 'none')
                            setFirstRender(false)
                        })
                }
            })
    }, [])


    useEffect(() => {
        if (firstRender) return
        if (!logoLoaded || selectedTheme !== "ai") return

        const logo = document.getElementById('your-logo');


        if (logo) {
            const colors = colorThief.getPalette(logo, 3)
            if (colors) {
                const hexColors = colors.map(color => rgbToHex(color[0], color[1], color[2]))
                const i = Math.floor(Math.random() * 3);
                const j = Math.floor(Math.random() * 3);
                const k = Math.floor(Math.random() * 3);
                const l = Math.floor(Math.random() * 2);
                const showAsCards = Math.random() > 0.5
                const showHeaderAsDifferentColor = Math.random() > 0.5

                const tmpCardColor = showAsCards ? (l ? "#ffffff" : hexColors[k]) : hexColors[i]
                setBgColor(hexColors[i])
                setSecondaryColor(showHeaderAsDifferentColor ? hexColors[j] : hexColors[i])
                setCardColor(tmpCardColor)
                setItemsFontColor(contrastingColorBw(tmpCardColor))
                setSectionsFontColor(contrastingColorBw(hexColors[i]))
                setFontFamilySelected("Nunito")
                setFontSizeSelected(15)
                setShowMenuLogo(true)
                setShowSidebarLogo(true)
            }
        }
    }, [logoLoaded, randomizeAi])

    useEffect(() => {
        if (firstRender) return

        metadata.template = template
        metadata.imagesPositioning = imagesPositioning
        metadata.include_watermark = includeWatermark
        onMetadataChangeCallback()

        if (iref) iref.contentWindow.postMessage({ nature: "metadata", content: metadata }, config.url.PREVIEW)

    }, [template, includeWatermark, imagesPositioning])

    useEffect(() => {
        if (firstRender) return

        metadata.logo = { src: logoSrc || "", alt: "Logo" }

        if (iref) iref.contentWindow.postMessage({ nature: "metadata", content: metadata }, config.url.PREVIEW)

    }, [logoLoaded])

    useEffect(() => {
        if (firstRender) return
        setSearchBarColor(contrastingColorBw(secondaryColor))
    }, [secondaryColor])

    useEffect(() => {
        if (firstRender) return

        onDesignChangeCallback()
        const theme = themes[selectedTheme]

        if (theme && !firstRender) {
            setBgColor(theme["bgColor"])
            setSecondaryColor(theme["secondaryColor"])
            // setSearchBarColor([theme[""]])
            setItemsFontColor(theme["itemsFontColor"])
            setSectionsFontColor(theme["sectionsFontColor"])
            setFontFamilySelected(theme["fontFamilySelected"])
            setFontSizeSelected(theme["fontSizeSelected"])
            setCardColor(theme["cardColor"])
            setShowMenuLogo(true)
            setShowSidebarLogo(true)
        }
    }, [selectedTheme])

    useEffect(() => {
        if (firstRender) return

        onDesignChangeCallback()

        if (iref) {
            themeCss.css = `
:root {
--app-bar-bg: ${secondaryColor};
--app-bar-color: ${searchBarColor};
--app-bg: ${bgColor};
--app-font: ${fontFamilySelected};
--app-font-size: ${fontSizeSelected}px;
--app-section-color: ${sectionsFontColor};
--item-bg: ${cardColor};
--item-color: ${itemsFontColor};
--menu-logo: ${showMenuLogo ? "inherit" : "none"};
--sidebar-logo: ${showSidebarLogo ? "inherit" : "none"};
--display-collapser: none;
--h-decoration: none;
--section-desc-weight: bold;
}

#root {
    visibility: visible;
}`
            iref.contentWindow.postMessage({ nature: "css", content: themeCss.css }, config.url.PREVIEW)
        }
    }, [
        secondaryColor,
        searchBarColor,
        bgColor,
        fontFamilySelected,
        fontSizeSelected,
        sectionsFontColor,
        cardColor,
        itemsFontColor,
        showMenuLogo,
        showSidebarLogo,
    ])

    return <div id="design-anchor" style={{
        width: "30rem",
        maxWidth: "100%",
        margin: "auto",
        padding: "1rem",
        boxSizing: "border-box",
        background: "#ffffff",
        borderRadius: "8px",
        ...(showCustomOptions && { zIndex: 1 })
    }}> {/** TODO: Clean class names */}
        <div className="card edit App" style={{ backgroundColor: "unset" }}>
            <div className="edit columns" name="app-content">
                <div className="edit left-column">
                    <div className="clickable" style={{ boxShadow: "0px 0px 0px 2px #00b0fb", background: "#00b0fb", color: "#ffffff", justifyContent: "space-between", borderRadius: "6px", paddingInline: "1rem", width: "100%", height: "3rem", display: "flex", marginBottom: "1.5rem", alignItems: "center" }}
                        onClick={() => {
                            var inputTag = document.createElement("input")
                            inputTag.type = "file"
                            inputTag.accept = "image/*"
                            inputTag.onchange = function (event) {
                                setUploading(true)
                                uploadLogo(event, restaurantId, (filename) => {
                                    setLogoSrc(filename)
                                    // metadata.logo.src = filename TODO
                                }, () => {
                                    alert("failed to upload")
                                    setUploading(false)
                                })
                            }
                            inputTag.style = "display:none;"
                            inputTag.click()
                        }}>
                        <div style={{ fontWeight: "bold", display: "contents" }}>
                            {uploading ? "Loading..." : (!logoSrc ? t('identity.logo.upload') : <img src={`${config.url.PUBLIC}/restaurants/${restaurantId}/${logoSrc}`} style={{ height: "100%", objectFit: "contain" }} crossOrigin="Anonymous" />)}
                        </div>
                        <AddPhotoAlternate />
                    </div>
                    <div className='templates-container'>
                        <div className='templates'>
                            <div className={template === 1 ? 'selected' : "clickable"}
                                onClick={() => setTemplate(1)}
                            >
                                <span>{t('general.in-row')}</span>
                                {template === 1 ? <TaskAlt /> : <RadioButtonUnchecked />}
                            </div>
                            <div className={template === 2 ? 'selected' : "clickable"}
                                onClick={() => setTemplate(2)}>
                                <span>{t('general.in-col')}</span>
                                {template === 2 ? <TaskAlt /> : <RadioButtonUnchecked />}
                            </div>
                        </div>
                    </div>
                    <div className='templates-container'>
                        <div className='templates'>
                            <div className={imagesPositioning === 'left-image' ? 'selected' : "clickable"}
                                onClick={() => setImagesPositioning('left-image')}
                            >
                                <span>{t('general.left-image')}</span>
                                {imagesPositioning === 'left-image' ? <TaskAlt /> : <RadioButtonUnchecked />}
                            </div>
                            <div className={imagesPositioning === 'under-image' ? 'selected' : "clickable"}
                                onClick={() => setImagesPositioning('under-image')}
                            >
                                <span>{t('general.under-image')}</span>
                                {imagesPositioning === 'under-image' ? <TaskAlt /> : <RadioButtonUnchecked />}
                            </div>
                        </div>
                    </div>
                    {showCustomOptions ? <div className="custom-options">
                        <div style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>{t('general.colors')}</div>
                        <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem", marginBottom: "1rem" }}>
                            <ColorPicker initColorHex={bgColor} callback={setBgColor} left={true} label={t('general.background')} />
                            <ColorPicker initColorHex={secondaryColor} callback={setSecondaryColor} label={t('general.menu-bar')} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem", marginBottom: "1rem" }}>
                            <ColorPicker initColorHex={itemsFontColor} callback={setItemsFontColor} left={true} label={t('general.text')} />
                            <ColorPicker initColorHex={sectionsFontColor} callback={setSectionsFontColor} label={t('general.sections')} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem", marginBottom: "1rem" }}>
                            <ColorPicker initColorHex={cardColor} callback={setCardColor} left={true} label={t('appearance.cards.color')} />
                        </div>
                        <div style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>{t('appearance.font.title')}</div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <select onChange={(e) => setFontFamilySelected(e.target.value)}>
                                <option
                                    style={{ fontFamily: 'Montserrat' }}
                                    selected={fontFamilySelected === "Montserrat"}
                                    value="Montserrat">Montserrat</option>
                                <option
                                    style={{ fontFamily: 'Nunito' }}
                                    selected={fontFamilySelected === "Nunito"}
                                    value="Nunito">Nunito</option>
                                <option
                                    style={{ fontFamily: '"Arial, Helvetica, sans-serif"' }}
                                    selected={fontFamilySelected === "Arial, Helvetica, sans-serif"}
                                    value="Arial, Helvetica, sans-serif">Arial</option>
                                <option
                                    style={{ fontFamily: '"Lucida Console", Courier, monospace' }}
                                    selected={fontFamilySelected === '"Lucida Console", Courier, monospace'}
                                    value='"Lucida Console", Courier, monospace'>Lucida console</option>
                                <option
                                    style={{ fontFamily: '"Times New Roman", Times, serif' }}
                                    selected={fontFamilySelected === '"Times New Roman", Times, serif'}
                                    value='"Times New Roman", Times, serif'>Times New Roman</option>
                                <option
                                    style={{ fontFamily: '"Dancing Script", cursive' }}
                                    selected={fontFamilySelected === '"Dancing Script", cursive'}
                                    value='"Dancing Script", cursive'>Dancing Script</option>
                            </select>
                            <select onChange={(e) => setFontSizeSelected(parseInt(e.target.value))} >
                                <option
                                    selected={fontSizeSelected === 13}
                                    value={13}>Small</option>
                                <option
                                    selected={fontSizeSelected === 15}
                                    value={15}>Medium</option>
                                <option
                                    selected={fontSizeSelected === 17}
                                    value={17}>Large</option>
                            </select>
                        </div>
                        <div style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>{t('appearance.logo.title')}</div>
                        <div className='templates-container'>
                            <div className='templates'>
                                <div className={showMenuLogo ? 'selected' : "clickable"}
                                    onClick={() => setShowMenuLogo(!showMenuLogo)}
                                >
                                    <span>{t('appearance.logo.show-in-menu')}</span>
                                    {showMenuLogo ? <TaskAlt /> : <RadioButtonUnchecked />}
                                </div>
                                <div className={showSidebarLogo ? 'selected' : "clickable"}
                                    onClick={() => setShowSidebarLogo(!showSidebarLogo)}>
                                    <span>{t('appearance.logo.show-in-sidebar')}</span>
                                    {showSidebarLogo ? <TaskAlt /> : <RadioButtonUnchecked />}
                                </div>
                            </div>
                        </div>
                        <div className='templates-container'>
                            <div className='templates'>
                                <div className={includeWatermark ? 'selected' : "clickable"}
                                    onClick={() => setIncludeWatermark(!includeWatermark)}
                                >
                                    <span>{t('paper-menu.watermark.title')}</span>
                                    {includeWatermark ? <TaskAlt /> : <RadioButtonUnchecked />}
                                </div>
                            </div>
                        </div>
                        <div className="tas-button"
                            style={{ marginTop: "0.5rem", display: "flex", paddingLeft: "0.5rem", paddingRight: "0" }}
                            onClick={() => {
                                setShowCustomOptions(false)
                            }}><ArrowBackIcon style={{ marginRight: "1rem" }} />{t('appearance.back-predefined-designs')}</div>
                    </div>
                        : <>
                            <div className="design-container">
                                <div className="prefined-designs">
                                    {["light", "default", "halloween-2", "wood", "deepblue", "bonbon"].map(designName => {
                                        return <div key={designName} name={designName}>
                                            <Design
                                                design={themes[designName]}
                                                colorOne={themes[designName]["bgColor"]}
                                                colorTwo={themes[designName]["secondaryColor"]}
                                                cardColor={themes[designName]["cardColor"]}
                                                isSelected={selectedTheme == designName}
                                                selectCallback={() => setSelectedTheme(designName)}
                                            /></div>
                                    })}
                                    <div name="random">
                                        <div
                                            onClick={() => {
                                                const i = Math.floor(Math.random() * 100);
                                                const showAsCards = Math.random() > 0.5
                                                const showHeaderAsDifferentColor = Math.random() > 0.5

                                                const contrastingCardColor = contrastingColorBw(showAsCards ? colors[i][2] : colors[i][0])
                                                const contrastingBgColor = contrastingColorBw(colors[i][0])

                                                setBgColor(colors[i][0])
                                                setSecondaryColor(showHeaderAsDifferentColor ? colors[i][1] : colors[i][0])
                                                setItemsFontColor(contrastingCardColor)
                                                setSectionsFontColor(contrastingBgColor)
                                                setFontFamilySelected("Nunito")
                                                setFontSizeSelected(15)
                                                setCardColor(showAsCards ? colors[i][2] : colors[i][0])
                                                setShowMenuLogo(true)
                                                setShowSidebarLogo(true)
                                            }}>
                                            <Design
                                                design={{ name: "Shuffle" }}
                                                colorOne={"linear-gradient(-45deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100%)"}
                                                colorTwo={"linear-gradient(-45deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100%)"}
                                                cardColor={null}
                                                isSelected={selectedTheme == "shuffle"}
                                                selectCallback={() => setSelectedTheme("shuffle")}
                                            />
                                        </div>
                                    </div>
                                    <div name="ai" style={{ position: "relative" }} onClick={() => {
                                        setSelectedTheme("ai")
                                        if (!logoSrc) {
                                            var inputTag = document.createElement("input")
                                            inputTag.type = "file"
                                            inputTag.accept = "image/*"
                                            inputTag.onchange = function (event) {
                                                setUploading(true)
                                                uploadLogo(event, restaurantId, (filename) => {
                                                    setLogoSrc(filename)
                                                }, () => {
                                                    alert("failed to upload")
                                                    setUploading(false)
                                                })
                                            }
                                            inputTag.style = "display:none;"
                                            inputTag.click()
                                        } else {
                                            setRandomizeAI(Math.random())
                                        }
                                    }}>
                                        <DesignLogo logoSrc={logoSrc} randomize={() => setRandomizeAI(Math.random())} removePicture={() => setLogoSrc("")} restaurantId={restaurantId} loadedCallback={() => {
                                            setLogoLoaded(Math.random())
                                            setUploading(false)
                                        }} />
                                        {uploading && <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", background: "#ffffff" }}>loading...</div>}
                                    </div>
                                    <div name="custom" onClick={() => {
                                        setSelectedTheme("custom")
                                        setShowCustomOptions(true)
                                    }}>
                                        <CustomDesign />
                                    </div>
                                </div>
                                <div className='templates-container' style={{ marginTop: "1rem", marginBottom: "0" }}>
                                    <div className='templates'>
                                        <div className={includeWatermark ? 'selected' : "clickable"}
                                            onClick={() => setIncludeWatermark(!includeWatermark)}
                                        >
                                            <span>{t('paper-menu.watermark.title')}</span>
                                            {includeWatermark ? <TaskAlt /> : <RadioButtonUnchecked />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Dancing Script"
        />
    </div >
}

var themeCss = { css: "" }

const ThemeView = ({ metadata, restaurantId, renewCredentials, updateMetadata, tier, goToPage, showNavigation }) => { // todo: use all params
    const [iref, setIref] = useState(null)
    const [proOptionSelected, setProOptionSelected] = useState(false)
    const [mobilePreview, setMobilePreview] = useState(false) // design-anchor, qrcode-anchor, signup-anchor
    const [localSaved, setLocalSaved] = useState(true)
    const [saving, setSaving] = useState(false)
    const { t } = useTranslation('app');

    // todod: load from file

    // var localMetadata = JSON.parse(JSON.stringify(metadata))

    // TODO:          const proOptionSelected = this.props.tier !== "gold" && (this.state.specialEffects || (!this.state.metadata.include_watermark && !(this.state.metadata.include_watermark === undefined)))

    const menuUrl = config.url.PREVIEW + `/menu/?context=preview&utm_source=takeaseat-admin-preview&id=${metadata.alias || restaurantId}`

    function nextPage() {
        setSaving(true)
        if (themeCss.css !== "") {
            fetch(`${config.url.API}/theme/theme.css`, { // TODO. merge code with below
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify({
                    theme: themeCss.css
                })
            }).then(_ => {
                updateMetadata(metadata).then(() => {
                    setTimeout(() => goToPage("your-qr-code"), 500) // TODO: handle wehn it fails
                })
            })
        } else {
            updateMetadata(metadata).then(() => {
                setTimeout(() => goToPage("your-qr-code"), 500) // TODO: handle wehn it fails
            })
        }
    }

    async function saveTheme() {
        if (localSaved) return false

        if (themeCss.css !== "") {
            return fetch(`${config.url.API}/theme/theme.css`, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify({
                    theme: themeCss.css
                })
            }).then(data => {
                return updateMetadata(metadata).then((success) => {
                    setLocalSaved(success)
                    return success
                })
            })
        } else {
            return updateMetadata(metadata).then((success) => {
                setLocalSaved(success)
                return success
            })
        }
    }

    if (!showNavigation && Object.keys(metadata.menus || {}).length === 0) return <div className="no-menu">
        <div><b>{t('general.no-menu')}</b></div>
        <Link to="/edit"><div className="tas-button">{t('general.create')}</div></Link>
    </div>

    return <div className='design-app'>
        { // In the Admin interface we display a save button
            (!showNavigation && localSaved !== undefined) && <>
                <div className="app-menu">
                    <div className="first-row">
                        <SaveButton key={localSaved} saved={localSaved} callback={() => {
                            if (proOptionSelected) return // TODO
                            saveTheme()
                        }} />
                        {(proOptionSelected) && <div style={{ marginLeft: "1rem", color: "red" }}>{t('general.pro-option-selected.0')}<PricingModalTrigger text="PRO" chooseAdsCallback={() => updateMetadata({ ...metadata, ads: true })} />{t('general.pro-option-selected.2')}</div>}
                        {localSaved && <div className="tas-button borderless outline" style={{ marginLeft: "auto", marginRight: "0" }}>
                            <span>
                                <a href={menuUrl} target="_blank" style={{ margin: "auto" }}>{t('general.view')} <OpenInNewIcon /></a>
                            </span>
                        </div>}
                    </div>
                </div>
            </>
        }
        <div className="halves">
            <div className="half left">
                <DesignEdit iref={iref} restaurantId={restaurantId} metadata={metadata} onMetadataChangeCallback={() => {
                    setLocalSaved(false)
                    setMobilePreview(true)
                }} onDesignChangeCallback={() => {
                    setLocalSaved(false)
                    setMobilePreview(true)
                }} themeCss={themeCss} />
            </div>
            <div className="half right">
                <div className={"preview-content" + (mobilePreview ? " show" : "")} onClick={() => setMobilePreview(false)}>
                    <div className={"preview-device"}>
                        <div className={"smartphone"}>
                            <img src="/static/media/phone-facing-2.png" alt="Phone case" width="395" height="796" />
                            <div>
                                <iframe
                                    id="smartphone"
                                    src={menuUrl} // TODO: `https://site.takeaseat.io/demo/menu?context=landing-page&utm_source=live-demo`
                                    width="351px"
                                    height="720px"
                                    display="initial"
                                    position="relative"
                                    ref={(iframeRef) => setIref(iframeRef)} />
                            </div>
                        </div>
                    </div>
                    {mobilePreview && <div className="small-screen" style={{
                        position: "absolute",
                        top: "3rem",
                        right: "2rem",
                        background: "#ffffff",
                        borderRadius: "50%",
                        padding: "0.5rem",
                        border: "1px solid grey"
                    }} onClick={() => setMobilePreview(false)}><Clear /></div>}
                </div>
            </div>
        </div>
        {
            (showNavigation) && <div className='bb-container' style={{ maxWidth: "30rem" }}>
                <div className="bottom-buttons">
                    <div className="tas-button next-button" onClick={nextPage}>
                        {saving && <div className="loader">Saving...</div>}
                        <div style={{ visibility: saving ? "hidden" : "visible" }}>
                            {t('setup.next')}&#160;&#160;❯
                        </div>
                    </div>
                </div>
            </div>
        }
    </div >
}

export default ThemeView;
