import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { config } from '../Constants'
import './Data.css'
import { useTranslation } from "react-i18next";
import CheckIcon from '@mui/icons-material/Check';


export function EditAndSaveButton({ mode, onEdit, onSave, onDelete, onCheck }) {
    /** TODO: rename now that delete is also possible */
    /**
     * mode: 'edit', 'saved', 'delete'
     */
    return <div className="save-edit-button">
        {
            (mode === 'saved') ? <div className='edit-icon'><EditIcon onClick={() => onEdit()} /></div> :
                (mode === 'edit') ? <div className='save-icon'><SaveIcon onClick={() => onSave()} /></div> :
                    (mode === 'delete') ? <div className='delete-icon'><DeleteIcon onClick={() => onDelete()} /></div> :
                        (mode === 'check') ? <div className='edit-icon' onClick={() => onCheck()}><CheckIcon /></div> :
                            <div className='edit-icon' style={{ visibility: "hidden" }}><EditIcon /></div>
        }
    </div>
}


export function SaveButton({ saved, callback }) {
    const [calledCallback, setCalledCallback] = useState(false)
    const { t } = useTranslation('app');

    return <div onClick={() => {
        callback()
        setCalledCallback(true)
    }}
        className={"save-button" + (saved ? " saved" : " unsaved")}>
        {saved ?
            <>
                <div>{t('edit.saved')}</div>
                <div><CheckIcon /></div>
            </>
            :
            <>
                {calledCallback ? <div>Saving...</div>
                    :
                    <>
                        <div>{t('edit.save')}</div>
                        <SaveIcon />
                    </>
                }
            </>}
    </div>
}