import React, { Component, useState } from 'react';
import { config } from '../Constants'
import { CustomDesign, Design } from '../utils/Design'; /** TODO: adapt */
import { withTranslation, useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import './Design.css';
import { ColorPicker } from '../utils/ColorUtils'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { contrastingColorBw } from '../utils/ColorUtils';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { SaveButton } from '../utils/Data';
import colors from 'nice-color-palettes'


const cssParse = require('css/lib/parse');
// var css = require('css');

function LivePreview({ propagateRef, restaurantId, alias }) {
    const [device, setDevice] = useState('mobile')
    const { t } = useTranslation('app');

    const laptopRatio = 3 // 1 / (window.innerWidth / 1920 / 4)
    const mobileRatio = 1.2

    return <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <div className='device-selector'>
            <div style={{ background: "white", display: "flex" }}>
                <div
                    className={"tas-button" + (device === 'mobile' ? ' active' : ' outline')}
                    onClick={() => setDevice('mobile')}><span>{t('mobile')}</span></div>
            </div>
            <div style={{ background: "white", display: "flex" }}>
                <div
                    className={"tas-button" + (device === 'laptop' ? ' active' : ' outline')}
                    onClick={() => setDevice('laptop')}><span>{t('laptop')}</span></div>
            </div>
        </div>
        {device === 'laptop' && <div className="laptop-container">
            <div className="laptop-img" alt="Image of a laptop"></div>
            <div className="inner-laptop">
                <iframe src={config.url.PREVIEW + `/?utm_source=takeaseat-admin-preview&context=preview&id=${alias || restaurantId}`}
                    width={`${laptopRatio * 100}%`}
                    height={`${laptopRatio * 100}%`}
                    id="test"
                    className="myClassname"
                    display="initial"
                    position="relative"
                    style={{ transform: `scale(${1 / laptopRatio})`, transformOrigin: "top left", border: "none" }}
                    ref={(iframeRef) => propagateRef(iframeRef)} />
            </div>
        </div>}
        {device === 'mobile' && <div className="mobile-container">
            <div className="mobile-img" alt="Image of a mobile"></div>
            <div className="inner-mobile">
                <iframe src={config.url.PREVIEW + `/?utm_source=takeaseat-admin-preview&context=preview&id=${alias || restaurantId}`}
                    width={`${mobileRatio * 100}%`}
                    height={`${mobileRatio * 100}%`}
                    className="myClassname"
                    display="initial"
                    position="relative"
                    style={{ transform: `scale(${1 / mobileRatio})`, transformOrigin: "top left", border: "none" }}
                    ref={(iframeRef) => propagateRef(iframeRef)} />
            </div>
        </div>}
    </div>
}

function ProOptions() {
    const [show, setShow] = useState(false)
    const { t } = useTranslation('app');

    return <div className="design-container premium">
        {show ? <div style={{ padding: "1rem" }}>
            <div style={{ paddingBottom: "1rem" }}><b>{t('spe')}</b></div>
            <div>{t('free-imp')}</div>
            <br />
            <div><Link to='/support'>{t('general.contact-us')}</Link></div>
        </div> : <div style={{ color: '#C6703A', padding: "0.75rem", cursor: "pointer" }} onClick={() => setShow(true)}>+ {t('appearance.show-pro-options')}</div>}
    </div>
}


class DesignView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bgColor: null,
            navColor: null,
            saved: true,
            selectedDesign: "custom"
        }

        this.computeRules = this.computeRules.bind(this)
        this.loadDesign = this.loadDesign.bind(this)
        this.parseDesign = this.parseDesign.bind(this)
        this.setIref = this.setIref.bind(this)
    }

    // TODO: Rename theme to design or design to them
    designs() {
        return {
            "default": {
                "name": "Take a Seat",
                'bgColor': "#ffffff",
                'navColor': "#2CB052"
            },
            "coffee-shop": {
                "name": "Coffee shop",
                'bgColor': "#ede8e2",
                'navColor': "#ede8e2"
            },
            "classic": {
                "name": "Classic",
                'bgColor': "#ffffff",
                'navColor': "#000000"
            },
            "sushi": {
                "name": "Sushi",
                'bgColor': "#ffffff",
                'navColor': "#ee6146"
            },
            "custom": {
                "name": "Custom",
                'bgColor': "#ffffff",
                'navColor': "#000000"
            },
            "earth": {
                "name": "Earth",
                "bgColor": "#dfd7c6",
                "navColor": "#7d7875"
            },
            "wood": {
                "name": "Wood",
                "bgColor": "#F9E1A1",
                "navColor": "#F9E1A1"
            },
            "deepblue": {
                "name": "Deep blue",
                "bgColor": "#040273",
                "navColor": "#040273"
            },
            "ocean-2": {
                "name": "Ocean",
                "bgColor": "#A6C6FF",
                "navColor": "#A6C6FF"
            },
            "discreet": {
                "name": "Smooth",
                "bgColor": "#ffffff",
                "navColor": "#DFDFDF"
            },
            "pb": {
                "name": "PB",
                "bgColor": "#6A6060",
                "navColor": "#443D3D"
            },
            "carbon": {
                "name": "Carbon",
                "bgColor": "#000000",
                "navColor": "#56667A"
            },
        }
    }

    computeRules(design) {
        // Font color is automatically computed from the background and nav bar color
        const navFtColor = contrastingColorBw(design['navColor'])
        const bgFtColor = contrastingColorBw(design['bgColor'])

        return `
        #___gatsby { background-color: ${design['bgColor']}; color: ${bgFtColor};}
        nav { background-color: ${design['navColor']}; color: ${navFtColor};}
        `
    }

    applyStyleToIframe(rules, restaurantId, alias) {
        this.iref.contentWindow.postMessage({ nature: "css", content: rules }, config.url.PREVIEW)
    }

    currentDesign() {
        return {
            'bgColor': this.state.bgColor,
            'navColor': this.state.navColor,
            'name': this.state.selectedDesign
        }
    }

    // TODO: SaveTheme

    componentDidMount() {
        // load current css values
        // TODO : Load current theme from DB
        this.setState({
            bgColor: "#ffffff",
            navColor: "#000000"
        })
    }

    setIref(ref) {
        this.iref = ref;
    }

    parseDesign(cssRules) {
        const bgColor = cssRules.stylesheet.rules[0].declarations[0].value
        const navColor = cssRules.stylesheet.rules[1].declarations[0].value
        const selectedDesign = (() => {
            const possibleDesigns = this.designs()
            var designFromCSS = "custom"
            Object.keys(possibleDesigns).forEach(designKey => {
                if (designKey === "custom") return
                if (possibleDesigns[designKey]["bgColor"] === bgColor &&
                    possibleDesigns[designKey]["navColor"] === navColor) designFromCSS = designKey
            });
            return designFromCSS
        })()
        this.setState({ bgColor, navColor, selectedDesign })
    }

    loadDesign() {
        fetch(`${config.url.PUBLIC}/restaurants/${this.props.restaurantId}/public-page/design.css`, {
            method: 'get'
        }).then(resp => {
            if (resp.status === 200) {
                resp.text().then(respText => this.parseDesign(cssParse(respText)))
            }
        })
    }

    saveDesign() {
        const design = (this.state.selectedDesign === "custom" || this.state.selectedDesign === "AI") ? this.currentDesign() : this.designs()[this.state.selectedDesign]

        return fetch(`${config.url.API}/public-page/${this.props.restaurantId}/design`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                design: this.computeRules(design)
            })
        }).then(resp => {
            if (resp.status === 200) {
                this.setState({ saved: true })
            }
        })
    }

    componentDidMount() {
        this.loadDesign()
    }

    render() {
        const { t } = this.props
        return <div className="App pp-design">
            <div className="app-menu">
                <div className="first-row">
                    <div>
                        <SaveButton key={this.state.saved} saved={this.state.saved} callback={(() => { this.saveDesign() }).bind(this)} />
                    </div>
                    {this.state.saved && <div className="tas-button borderless outline" style={{ marginLeft: "auto", marginRight: "0" }}>
                        <span>
                            <a href={config.url.SITES.replace('$1', this.props.metadata.alias || this.props.restaurantId)} target="_blank" style={{ margin: "auto" }}>{t('view')} <OpenInNewIcon /></a>
                        </span>
                    </div>}
                </div>
            </div>
            <div className='pp-design-content'>
                <div className="design-container">
                    {this.state.showCustomOptions ?
                        <div style={{ width: "100%", display: "flex" }}>
                            <div className="custom-options" style={{ margin: "auto" }}>
                                <div className="option-section">
                                    <table>
                                        <tbody>
                                            <tr style={{ position: "relative" }}>
                                                <td className="option-label">
                                                    {t('app:general.background')}:
                                                </td>
                                                <td className="option-value">
                                                    <ColorPicker
                                                        initColorHex={this.state.bgColor}
                                                        callback={(newColorHex) => {
                                                            this.setState({ bgColor: newColorHex, saved: false },
                                                                () => this.applyStyleToIframe(this.computeRules(this.currentDesign()), this.props.restaurantId, this.props.metadata.alias) // TODO Check if always after state update
                                                            )
                                                        }} />
                                                </td>
                                            </tr>
                                            <tr style={{ position: "relative" }}>
                                                <td className="option-label">
                                                    {t('app:general.menu-bar')}:
                                                </td>
                                                <td className="option-value">
                                                    <ColorPicker
                                                        initColorHex={this.state.navColor}
                                                        callback={(newColorHex) => {
                                                            this.setState({ navColor: newColorHex, saved: false },
                                                                () => this.applyStyleToIframe(this.computeRules(this.currentDesign()), this.props.restaurantId, this.props.metadata.alias)
                                                            ) // TODO Check if always after state update
                                                        }} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="tas-button outline" onClick={() => {
                                        this.setState({ showCustomOptions: false })
                                    }}>
                                        <span>
                                            <div style={{ display: "flex" }}>
                                                <div><ArrowBackIcon /></div>
                                                <div>{t('app:general.back')}</div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div className="prefined-designs" style={{ padding: "1rem", background: "white", borderRadius: "8px" }}>
                            {["coffee-shop", "wood", "ocean-2", "default", "sushi", "pb"].map(designKey => {
                                return <div name={designKey}><Design
                                    design={this.designs()[designKey]}
                                    colorOne={this.designs()[designKey]["bgColor"]}
                                    colorTwo={this.designs()[designKey]["navColor"]}
                                    isSelected={this.state.selectedDesign == designKey}
                                    selectCallback={(() => {
                                        this.applyStyleToIframe(this.computeRules(this.designs()[designKey]), this.props.restaurantId, this.props.metadata.alias);
                                        this.setState({ selectedDesign: designKey, saved: false })
                                    }).bind(this)} /></div>
                            })}
                            <div>
                                <Design
                                    design={{ name: "Shuffle" }}
                                    colorOne={"linear-gradient(-45deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100%)"}
                                    colorTwo={"linear-gradient(-45deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100%)"}
                                    cardColor={null}
                                    selectCallback={() => {
                                        const i = Math.floor(Math.random() * 100);
                                        const showHeaderAsDifferentColor = Math.random() > 0.5

                                        this.setState({
                                            'bgColor': colors[i][0],
                                            'navColor': showHeaderAsDifferentColor ? colors[i][2] : colors[i][0],
                                            'name': "AI",
                                            "selectedDesign": "AI",
                                            saved: false
                                        },
                                            () => this.applyStyleToIframe(this.computeRules(this.currentDesign()), this.props.restaurantId, this.props.metadata.alias) // TODO Check if always after state update
                                        )
                                    }}
                                />
                            </div>
                            <div>
                                <div>
                                    <CustomDesign
                                        isSelected={this.state.selectedDesign == "custom"}
                                        selectCallback={(() => {
                                            this.applyStyleToIframe(this.computeRules(this.currentDesign()), this.props.restaurantId, this.props.metadata.alias);
                                            this.setState({ showCustomOptions: true, selectedDesign: "custom", saved: false })
                                        }).bind(this)} t={t} />
                                </div>
                            </div>
                        </div>
                    }
                    <ProOptions />
                </div>
                <LivePreview restaurantId={this.props.restaurantId} propagateRef={this.setIref} alias={this.props.metadata.alias || ""} />
            </div>
        </div> 
    }
}

const DesignViewTranslated = withTranslation(['publicpage', 'app'])(DesignView)

export default DesignViewTranslated;
