import React, { Component, useState, useEffect } from 'react';
import { config } from '../Constants';
import _ from 'lodash'; // optimize import
import { useTranslation, withTranslation } from "react-i18next";
import { Loader } from '../utils/MenuUtils';
import { Modal, TextField } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import DeleteIcon from '@mui/icons-material/Delete';
import { queryJsonData } from '../utils/MenuUtils'
import { logout } from '../utils/LoadInformation';
import { Clear } from '@mui/icons-material';


function DeleteAccount({ onLogout }) {
    const [showModal, setShowModal] = useState(false)
    const [feedback, setFeedback] = useState("")
    const [deleting, setDeleting] = useState(false)
    const [reason, setReason] = useState("")
    const [error, setError] = useState("")
    const { t } = useTranslation('app');

    return <div style={{ marginLeft: "auto", paddingLeft: "1rem" }}>
        <div
            className='tas-button borderless danger'
            onClick={() => setShowModal(true)}><span><DeleteIcon />{t('identity.delete-account')}</span></div>
        <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            className={"modal-view user-modal"}
        >
            <div className="modal-content" style={{ background: "white", width: "90%", maxWidth: "30rem", position: "relative" }}>
                <div style={{ position: "absolute", top: "0.5rem", right: "0.5rem" }} onClick={() => setShowModal(false)}><Clear /></div>
                <div>
                    <div style={{ fontSize: "200%", textAlign: "center" }}>{t('identity.delete-account')}</div>
                </div>
                <br />
                <br />
                <div>
                    <div><b>{t('identity.delete-why')}</b></div>
                    <br />
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
                        <div className={"reason" + (reason === "expensive" ? " selected" : "")} onClick={() => { setReason("expensive"); setFeedback("Too expensive") }}>💰 Too expensive</div>
                        <div className={"reason" + (reason === "bug" ? " selected" : "")} onClick={() => { setReason("bug"); setFeedback("") }}>🐞 There is a bug</div>
                        <div className={"reason" + (reason === "not-clear" ? " selected" : "")} onClick={() => { setReason("not-clear"); setFeedback("") }}>🧐 Not clear</div>
                        <div className={"reason" + (reason === "other" ? " selected" : "")} onClick={() => { setReason("other"); setFeedback("") }}>Other</div>
                    </div>
                    {reason && <div style={{ marginTop: "1rem" }}>
                        {reason === "expensive" ? <div>
                            <div style={{ textAlign: "center", width: "100%", padding: "0.25rem", borderRadius: "8px", boxShadow: "1px 2px 4px 2px lightgrey" }}>
                                <p>50% OFF with promo code</p>
                                <p><em>COUPON50</em></p>
                            </div>
                        </div> : <TextareaAutosize
                            minRows={3}
                            placeholder="Please describe..."
                            onChange={(e) => {
                                setFeedback(e.target.value)
                                setError("")
                            }}
                            style={{ width: "100%", borderRadius: "6px", border: (error === "Missing required field" ? "2px solid red" : "1px solid") }}
                        />}
                    </div>}
                    <br />
                    <div>{t('identity.delete-f-1')}</div>
                </div>
                <br />
                <div style={{ textAlign: "center" }}>
                    <div
                        className="danger borderless tas-button"
                        onClick={() => {
                            if (!reason) setError("Missing reason")
                            else if (!feedback) setError("Missing required field")
                            else {
                                if (!deleting) {
                                    setDeleting(true)
                                    queryJsonData({ url: `${config.url.API}/restaurant`, body: { feedback: reason + " | " + feedback }, verb: "delete" }).then(resp => {
                                        if (resp.status === 200) {
                                            logout(onLogout)
                                            setDeleting(false)
                                        } else {
                                            setError("Could not delete account, please contact team.")
                                            setDeleting(false)
                                        }
                                    })
                                }
                            }
                        }}
                    ><span>{t('identity.delete-account')}</span></div>
                    <div style={{ color: "red", marginTop: "0.5rem" }}>{error}</div>
                </div>
            </div>
        </Modal>
    </div>
}

function AccountView({ onLogout }) {
    const [userAccountInfo, setUserAccountInfo] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [currentPassword, setCurrentPassword] = useState("")
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState("")
    const [newEmail, setNewEmail] = useState("")
    const [submitting, setSubmitting] = useState(false)
    const [newPassword, setNewPassword] = useState("************")
    const [action, setAction] = useState(null)
    const { t } = useTranslation('app');

    useEffect(() => {
        fetch(config.url.API + '/user/info', {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include"
        }).then(resp => {
            if (resp.status !== 200) {
                alert(t('error-contact-team'))
            } else {
                resp.json().then(setUserAccountInfo)
            }
        })
    }, [])

    useEffect(() => {
        setNewEmail(userAccountInfo?.email || "")
    }, [userAccountInfo])

    const updatePassword = () => {
        if (!newPassword) return setError(t('account-empty-pwd'))
        if (!newPassword || !currentPassword) return setError(t('account-missing-pwd'))
        if (!submitting) {
            fetch(config.url.API + '/user/password', {
                method: "PUT",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify({ password: newPassword, currentPassword })
            }).then(resp => {
                if (resp.status === 200) {
                    setError("")
                    setSuccess(true)
                } else {
                    setError(t('account-error-pwd'))
                    setSuccess(false)
                }
                setSubmitting(false)
            }).catch(e => {
                setError(t('account-error-pwd'))
                setSuccess(false)
            })
            setSubmitting(true)
        }
    }

    const updateEmailAddress = () => {
        if (!newEmail) return setError(t('account-empty-email'))
        if (!currentPassword) return setError(t('account-missing-pwd'))
        if (!submitting) {
            fetch(config.url.API + '/user/email', {
                method: "PUT",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify({ email: newEmail, currentPassword })
            }).then(resp => {
                if (resp.status === 200) {
                    setError("")
                    setSuccess(true)
                } else {
                    setError(t('account-error-pwd'))
                    setSuccess(false)
                }
                setSubmitting(false)
            }).catch(e => {
                setError(t('account-error-pwd'))
                setSuccess(false)
            })
            setSubmitting(true)
        }
    }

    useEffect(() => {
        if (!openModal) setAction(null)
    }, [openModal])

    return <div>
        <div className="app-menu">
            <div className="first-row">
                <DeleteAccount onLogout={onLogout} />
            </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "4rem auto" }}>
            {userAccountInfo ? <div style={{ padding: "1rem", maxWidth: "100%" }}>
                <div style={{ marginBottom: "0.5rem" }}><b>{t('account-email-pwd')}</b></div>
                <div style={{
                    width: "30rem",
                    borderRadius: "8px",
                    boxShadow: "1px 2px 4px 2px lightgrey",
                    height: "100%",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    padding: "1rem",
                    maxWidth: "100%",
                    background: "white"
                }}>
                    <div>
                        <TextField value={newEmail} onChange={(e) => setNewEmail(e.target.value)} disabled={action !== "email"} fullWidth />
                        {action !== "email" ?
                            <div className="tas-button" style={{ marginBlock: "0.5rem" }} onClick={() => setAction("email")}>{t('account-email-change')}</div>
                            : <div>
                                <div className="tas-button" style={{ marginBlock: "0.5rem", marginRight: "1rem" }} onClick={() => setOpenModal(true)}>{t('general.save')}</div>
                                <div className="tas-button outline borderless" onClick={() => setAction(null)}><span>{t('edit.cancel')}</span></div>
                            </div>
                        }
                    </div>
                    <div>
                        <TextField value={newPassword} onChange={(e) => setNewPassword(e.target.value)} type="password" disabled={action !== "password"} fullWidth />
                        {action !== "password" ?
                            <div className="tas-button" style={{ marginBlock: "0.5rem" }} onClick={() => setAction("password")}>{t('account-pwd-change')}</div>
                            : <div>
                                <div className="tas-button" style={{ marginBlock: "0.5rem", marginRight: "1rem" }} onClick={() => setOpenModal(true)}>{t('general.save')}</div>
                                <div className="tas-button outline borderless" onClick={() => setAction(null)}><span>{t('edit.cancel')}</span></div>
                            </div>
                        }
                    </div>
                </div>
                <Modal
                    open={openModal}
                    onClose={() => { setOpenModal(false) }}
                    className={"modal-view user-modal"}
                >
                    <div className="modal-content" style={{ background: "white", width: "90%", maxWidth: "30rem", position: "relative" }}>
                        <div style={{ position: "absolute", top: "0.5rem", right: "0.5rem" }} onClick={() => setOpenModal(false)}><Clear /></div>                        
                        <div style={{ marginBottom: "1rem", marginTop: "1rem" }}>{t('account-confirm-id')}</div>
                        <TextField type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} fullWidth label={t('account-current-pwd')} />
                        <div style={{ marginTop: "1rem" }}>{t('account-pwd-reset-instructions')} <a href="/reset_password">{t('account-reset-pwd')}</a></div>
                        <div className="tas-button" style={{ marginTop: "1.5rem" }} onClick={action === "email" ? updateEmailAddress : (action === "password" ? updatePassword : () => { })}>{t('account-do-confirm')}</div>
                        {success && <div style={{ color: "#2cb052", marginTop: "0.5rem" }}>{t('account-success')}</div>}
                        {error && <div style={{ color: "red", marginTop: "0.5rem" }}>{error}</div>}
                    </div>
                </Modal>
            </div> :
                <Loader />}
        </div>
    </div>
}

const AccountViewTranslated = withTranslation('app')(AccountView)

export default AccountViewTranslated;
