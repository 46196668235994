import React from 'react';
import './Footer.css';
import { withTranslation } from "react-i18next";
import Cookies from 'js-cookie'
import { config } from './Constants'
import { changeLanguagePreference } from "./utils/LanguageUtils";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import './LanguagePicker.css';


function Footer({ t, i18n, restaurantId, renewCredentials, version }) {

  function setLang({ lang }) {
    i18n.changeLanguage(lang);

    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);

    Cookies.set("lang", lang, { domain: config.cookies.domain, expires: nextYear })
    if (restaurantId) changeLanguagePreference(lang, renewCredentials)
  }

  return (
    <div className="footer">
      <div style={{ margin: "auto 1rem auto auto", display: "flex", zIndex: "1" }} className='privacy'>
        <a target="_blank" href="https://www.privacypolicygenerator.info/live.php?token=wGGtkHFI3MdKx5HpDWSZYKwI42xkUbse" style={{ margin: "auto 1rem auto 0.5rem", color: "lightgrey" }}>
          {t('sidebar.privacy')}
        </a>
      </div>
      {i18n.language &&
        <div id="lang-picker">
          <Select
            id="lang-picker-select"
            value={i18n.language}
            size="small"
            onChange={(e) => setLang({ lang: e.target.value })}>
            {/* <MenuItem value={"de"}>Deutsch</MenuItem> */}
            <MenuItem value={"en"}>English</MenuItem>
            <MenuItem value={"es"}>Español</MenuItem>
            <MenuItem value={"fr"}>Français</MenuItem>
            <MenuItem value={"pt"}>Português</MenuItem>
          </Select>
        </div>
      }
    </div>
  );
}

const FooterTranslated = withTranslation('app')(Footer)

export default FooterTranslated;
