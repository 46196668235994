
import React, { useState, useTransition } from 'react';
import { AddPhotoAlternate, Clear, Autorenew, Tune } from '@mui/icons-material';
import { config } from '../Constants';
import { useTranslation } from 'react-i18next';


export const popover = {
    position: 'absolute',
    zIndex: '2',
    left: '10%',
}
export const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
}


export function Design({ design, isSelected, selectCallback, colorOne, colorTwo, cardColor }) { /** TODO: translate */
    return <div className={"design" + (isSelected ? " selected" : "")} onClick={() => selectCallback()}>
        <div className="preview">
            <div style={{ background: colorTwo, width: "100%", height: "1rem", marginBottom: "0.5rem", borderRadius: "4px" }}></div>
            <div style={{ background: colorOne, padding: "0.75rem", borderRadius: "6px" }}>
                <div style={{ background: cardColor, padding: "0.5rem", borderRadius: "4px" }}></div>
            </div>
        </div>
        <div style={{ textAlign: "center", marginTop: "0.25rem", fontSize: "80%", fontWeight: "600" }}>{design["name"]}</div> {/**fontFamily: design["fontFamilySelected"],  */}
    </div>
}

export function DesignLogo({ logoSrc, randomize, removePicture, loadedCallback, restaurantId }) {/** TODO: translate */
    return <div className="design">
        <div style={{ position: "relative", overflow: "hidden" }}>
            <div style={{ width: "100%", height: "1rem", marginBottom: "0.5rem", borderRadius: "4px" }}></div>
            <div style={{ padding: "0.75rem", borderRadius: "6px" }}>
                <div style={{ padding: "0.5rem", borderRadius: "4px" }}></div>
            </div>
            {logoSrc ? <>
                <div style={{ position: "absolute", position: "absolute", top: "50%", transform: "translate(-50%, -50%)", left: "50%", width: "100%", height: "100%" }}>
                    <img
                        id="your-logo"
                        src={`${config.url.PUBLIC}/restaurants/${restaurantId}/${logoSrc}`} /*`https://public.takeaseat.io/restaurants/${this.props.restaurantId}/${this.state.metadata.logo.src}`*/
                        style={{ width: "100%", height: "100%", objectFit: "contain" }}
                        crossOrigin="Anonymous"
                        onLoad={loadedCallback}
                    />
                </div>
                <div style={{ position: "absolute", top: 0, right: 0, background: "white", display: "flex" }} className="clickable" onClick={removePicture}><Clear /></div>
            </> : <div style={{ display: "flex", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}><AddPhotoAlternate style={{ width: "2rem", height: "2rem", color: "lightgrey" }} /></div>
            }
        </div>
        {logoSrc ? <div style={{ textAlign: "center" }}>
            <Autorenew className="clickable" onClick={randomize} style={{ border: "1px solid lightgrey", borderRadius: "50%" }} />
        </div> :
            <div style={{ textAlign: "center", marginTop: "0.25rem", fontSize: "80%", fontWeight: "600" }}>Logo</div>}
    </div>
}

export function CustomDesign({ isSelected, selectCallback }) {/** TODO: translate */
    const { t } = useTranslation('app')

    return <div className={"design" + (isSelected ? " selected" : "")} onClick={() => { if (selectCallback) selectCallback() }}>
        <div style={{ position: "relative" }}>
            <div style={{ width: "100%", height: "1rem", marginBottom: "0.5rem", borderRadius: "4px" }}></div>
            <div style={{ padding: "0.75rem", borderRadius: "6px" }}>
                <div style={{ padding: "0.5rem", borderRadius: "4px" }}></div>
            </div>
            <div style={{ display: "flex", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}><Tune style={{ width: "2rem", height: "2rem", color: "lightgrey" }} /></div>
        </div>
        <div style={{ textAlign: "center", marginTop: "0.25rem", fontSize: "80%", fontWeight: "600" }}>{t('appearance.customize')}</div>
    </div>
}