import React, { useEffect, useState } from 'react';
import { config } from './Constants'
import { useTranslation } from "react-i18next";


export default function PreviewView({ restaurantId, menuId, menuData, show, saveCallback, metadata }) {
    const [iref, setIref] = useState(null)
    const [showMenu, setShowMenu] = useState(true)

    const { t } = useTranslation('app');
    const menuUrl = config.url.PREVIEW + `/menu/?menu=${menuId}&context=preview&utm_source=takeaseat-admin-preview&id=${metadata.alias || restaurantId}`

    useEffect(() => {
        if (iref) iref.contentWindow.postMessage({ nature: "menuData", content: menuData }, config.url.PREVIEW)
    }, [menuData])

    useEffect(() => {
        if (iref) iref.contentWindow.postMessage({ nature: "metadata", content: metadata }, config.url.PREVIEW)
    }, [metadata])

    useEffect(() => setShowMenu(show), [show])

    window.addEventListener('message', function (event) {
        if (event.origin !== config.url.PREVIEW) return;
        console.log('received response:  ', event.data); // TODO: limit number of event listener to one
    }, false);

    return <div className={"preview-content"} id="iframe-c">
        <div className={"preview-device"}>
            <div className={"smartphone"}>
                <img src="/static/media/phone-facing-2.png" alt="Phone case" />
                <div>
                    <iframe
                        id="iframe"
                        src={menuUrl}
                        width="351px"
                        height="720px"
                        display="initial"
                        position="relative"
                        ref={(iframeRef) => setIref(iframeRef)} />
                    {!showMenu && <div className='smoke-screen'>
                        <div className="save-button unsaved" onClick={saveCallback}>
                            <div>{t('general.save')}</div>
                            <div>&</div>
                            <div>{t('refresh')}</div>
                        </div>
                    </div>}
                </div>
            </div>
            <div style={{ textAlign: "center" }}>
                <a href={config.url.SITES.replace('$1', metadata.alias || restaurantId) + `/menu?menu=${menuId}&context=preview&utm_source=takeaseat-admin-preview`} target="_blank">
                    <div className='tas-button'>{t('general.view')}</div>
                </a>
            </div>
        </div>
    </div>
}