import React, { Component } from 'react';
import { config } from '../Constants'
import { withTranslation } from "react-i18next";


class SupportTicketView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticket: null,
            postMessage: "",
            sendingPost: false,
            postSuccessfullySubmitted: null
        };
        this.handlePost = this.handlePost.bind(this);
        this.loadTicket = this.loadTicket.bind(this);
    }

    componentDidMount() {
        this.loadTicket();
    }

    markAsRead({ ticketId, restaurantId, updateStatus, ticketIndex }) {
        fetch(`${config.url.API}/support/tickets/read`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({ ticketId, restaurantId })
        }).then( resp => {
            if (resp.status === 200) updateStatus({ ticketIndex, status: "open" })
        })
    }

    loadTicket() {
        fetch(`${config.url.API}/support/tickets/${this.props.ticketId}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include"
        }).then(data => {
            if (data.status != 200) {
                alert("Unexpected error");
                // TODO: display  nice error message on the page.
                return
            }
            data.json().then(data => {
                this.setState({ ticket: data });
                if (data.status !== "open") this.markAsRead({ ticketId: data.id, ticketIndex: this.props.ticketIndex, restaurantId: data.restaurant_id, updateStatus: this.props.updateStatus })
            });
        });
    }

    handlePost = (e) => {
        e.preventDefault();

        this.setState({
            sendingPost: true,
            messageSuccessfullySubmitted: null
        })

        fetch(`${config.url.API}/support/tickets/${this.props.ticketId}/posts`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({ "message": this.state.postMessage })
        })
            .then((response) => {
                if (response.status === 200) {
                    this.loadTicket();
                    this.setState({ postMessage: "", sendingPost: false, postSuccessfullySubmitted: true });
                } else this.setState({ sendingPost: false, postSuccessfullySubmitted: false })
            }
            )
            .catch((error) => {
                console.error(error);
            })
    }

    // Loading -> use spinner

    render() {
        const { t } = this.props;

        if (!this.state.ticket)
            return <div>Loading</div>

        const Post = function ({ post }) {
            const date = new Date(post.posted_time).toLocaleString();
            const source = post.source == "restaurant" ? t("support.ticket.updates.you") : "Take a Seat";

            return <li className={"ticket-post " + post.source}>
                <div className="ticket-post-message">{post.message}</div>
                <div className="ticket-post-metadata">{t("support.ticket.updates.by")} <em>{source}</em> {t("support.ticket.updates.on")} {date}</div>
            </li>
        }

        return (
            <div className="ticket-view">
                <h2 className="ticket-title">{this.state.ticket.title} | {t("support.ticket.ticket")} #{this.state.ticket.id}</h2>
                <div className="ticket-message">
                    <div className="ticket-post-message">{this.state.ticket.message}</div>
                    <div className="ticket-post-metadata">{t("support.ticket.updates.by")} <em>{t("support.ticket.updates.you")}</em> {t("support.ticket.updates.on")} {this.state.ticket.creation_time}</div>
                </div>

                {(this.state.ticket.posts.length > 0) && <>
                    <h4>{t("support.ticket.updates.title")}</h4>
                    <ul className="ticket-posts">
                        {this.state.ticket.posts.map(post => <Post post={post} />)}
                    </ul>
                </>}
                <h4>{t("support.ticket.post.title")}</h4>
                <form onSubmit={this.handlePost}>
                    <textarea rows="5" style={{ width: "100%", resize: "vertical" }} name="message"
                        value={this.state.postMessage}
                        onChange={(e) => this.setState({ postMessage: e.target.value })} />
                    <input type="submit" value={t("support.ticket.post.send")} className="button tas-button wide" />
                </form>
            </div>
        )
    }
}

const SupportTicketViewTranslated = withTranslation('app')(SupportTicketView)

export default SupportTicketViewTranslated;
