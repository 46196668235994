import React, { Component, useState } from 'react';
import './CustomSwitch.css'
import LockIcon from '@mui/icons-material/Lock';
import { ProFeatureBadge } from '../utils/Disclaimers'


export function Switch({ defaultChecked, onClickCallback, locked, premium, t }) {
    const [checked, setChecked] = useState((defaultChecked === undefined) ? false : defaultChecked)

    return <div
        className={"tas-switch" + (checked ? " checked" : "")}
        onClick={() => {
            if (!locked) setChecked(!checked)
            if (onClickCallback) onClickCallback()
        }}>
        <div>{t('app:general.yes')}</div>
        <div >{t('app:general.no')}</div>
    </div>
}