import React, { useEffect, useState } from 'react';
import './AllPurpose.scss'
import CircularProgress from '@mui/material/CircularProgress';
import { TextField } from '@mui/material'; //TextFieldProps 
import { styled } from '@mui/material/styles';

export const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        borderRadius: 8,
        paddingBlock: "0.25rem",
        ' .MuiOutlinedInput-notchedOutline': {
            borderColor: '#EDEDED',
            boxShadow: "0px 2px 4px -2px #ededed"
        }
    },
}));

export const CustomDropdown = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        borderRadius: 8,
        paddingBlock: "0.25rem",
        ' .MuiOutlinedInput-notchedOutline': {
            borderColor: '#EDEDED',
            boxShadow: "0px 2px 4px -2px #ededed"
        }
    },
}));

// export default function StyledCustomization() {
//     return <CustomTextField defaultValue={30} />;
// }

export function Box({ children, style }) {
    return <div className='custom-box' style={style}>
        {children}
    </div>
}

export function TasLoadingButton({ label, loading, onClick, className }) {
    return <button
        onClick={onClick}
        className={'tas' + " " + (className || '')}
    >
        {label}
        {loading && <div className='circular-progress' >
            <CircularProgress
                style={{ width: "25px", height: "25px" }}
                sx={{ color: "inherit" }} />
        </div>}
    </button>
}
