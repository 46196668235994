import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import imageCompression from 'browser-image-compression';
import axios from 'axios';
import _ from 'lodash'; // optimize import
import { config } from '../Constants'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';


async function uploadImage(file, restaurantId) {
    const formData = new FormData();
    formData.append('file', file, file.name); // appending file

    window.gtag('event', 'upload_image');
    return axios.post(`${config.url.API}/images/${restaurantId}`, formData,
        { withCredentials: true }
    ).then(res => {
        if (res.status === 200) {
            return true
        }
        return false
    }).catch(err => {
        return false
    })
}

export async function uploadLogo(event, restaurantId, successCallback, failedCallback) {
    const options = {
        maxSizeMB: 6,
        maxWidthOrHeight: 512,
        useWebWorker: true
    }

    if (event.target.files && event.target.files.length > 0) {
        const imageFile = event.target.files[0]
        try {
            var compressedFile = (imageFile.size > 6000) ? await imageCompression(imageFile, options) : imageFile

            if ((compressedFile.type === "image/png") && (!compressedFile.name.endsWith("png"))) compressedFile.name = compressedFile.name.concat('.png')  // handle svg and gif files that are converted to png

            const formData = new FormData();
            formData.append('file', compressedFile, compressedFile.name); // appending file

            fetch(`${config.url.API}/metadata/logo/${restaurantId}`, {
                method: 'post',
                headers: {
                    'Accept': 'application/json'
                },
                credentials: "include",
                body: formData
            }).then(resp => {
                if (resp.status === 200) {
                    successCallback(compressedFile.name)
                } else {
                    failedCallback(compressedFile.name)
                }
                return resp
            })
        } catch (error) {
            console.log(error);
        }
    }
}

export async function uploadImageWithThumbnail(event, restaurantId, successCallback, tier) {

    const options = {
        maxSizeMB: 6,
        maxWidthOrHeight: 1024,
        useWebWorker: true
    }

    const thumbnailOptions = {
        maxSizeMB: 0.4,
        maxWidthOrHeight: 512,
        useWebWorker: true
    }

    if (event.target.files) {
        const nfiles = event.target.files.length
        const files = event.target.files

        for (let i = 0; i < nfiles; i++) {
            const imageFile = files[i]
            try {
                var compressedFile = (tier !== "gold" || imageFile.size > 4000) ? await imageCompression(imageFile, options) : imageFile

                if ((compressedFile.type === "image/png") && (!compressedFile.name.endsWith("png"))) compressedFile.name = compressedFile.name.concat('.png')  // handle svg and gif files that are converted to png

                uploadImage(compressedFile, restaurantId).then(async (success) => {
                    if (success) {
                        // Now upload the thumbnail                
                        compressedFile = (tier !== "gold" || imageFile.size > 400) ? await imageCompression(imageFile, thumbnailOptions) : imageFile

                        const fileName = compressedFile.name
                        compressedFile.name = "thumbnail-" + fileName
                        if ((compressedFile.type === "image/png") && (!compressedFile.name.endsWith("png"))) compressedFile.name = compressedFile.name.concat('.png')  // handle svg and gif files that are converted to png

                        uploadImage(compressedFile, restaurantId).then((success) => {
                            if (success) {
                                successCallback(fileName)
                            } else { /* TODO */ }
                        })
                    } else {
                        // handle failed to upload
                    }
                })
            } catch (error) {
                console.log(error);
            }
        }
    }
}

export async function handleImageUpload(event, jsonPath, restaurantId, successCallbackBind, tier) {
    if (event.target.files && event.target.files.length > 0) {
        var imageFile = event.target.files[0];

        try {
            if (tier !== "gold" || imageFile.size > 600000) {
                const options = {
                    maxSizeMB: 0.6,
                    useWebWorker: true,
                }
                imageFile = await imageCompression(imageFile, options);
            }

            // handle svg and gif files that are converted to png
            if ((imageFile.type === "image/png") && (!imageFile.name.endsWith("png"))) imageFile.name = imageFile.name.concat('.png')

            uploadImage(imageFile, restaurantId).then((success) => {
                if (success) {
                    successCallbackBind(jsonPath, imageFile)
                } else {
                    // handle failed to upload
                }
            })
            // await uploadToServer(imageFile); // write your own logic
        } catch (error) {
            console.log(error);
        }
    }
}

export function successCallback(jsonPath, imageFile) {
    _.set(this.state.menuData, jsonPath, imageFile.name)
    if (this.props.saved) this.props.onSavedUpdated(false);
    this.setState({})
}

export function successCallbackMultiImages(jsonPath, imageFile) {
    // TODO: merge with previous function
    var images = _.get(this.state.menuData, jsonPath, [])
    images.push(imageFile.name)
    _.set(this.state.menuData, jsonPath, images)
    if (this.props.saved) this.props.onSavedUpdated(false);
    this.setState({})
}

export function saveImages(jsonPath, images) {
    _.set(this.state.menuData, jsonPath, images)
    if (this.props.saved) this.props.onSavedUpdated(false);
    this.setState({});
}

const AddImage = ({ images, jsonPath, restaurantId, successCallbackBind, saveImages, tier }) => {
    return <div style={{ margin: "auto", zIndex: "1", width: "100%", maxWidth: "50rem" }}>
        <div style={{ display: "flex", overflow: "auto" }}>
            {images.map((image, i) => {
                return <div className="thumbnail">
                    <div className="img"
                        style={{ background: "url(" + encodeURI(`${config.url.PUBLIC}/restaurants/${restaurantId}/images/${image}`).replace(/\(/ig, "%28").replace(/\)/ig, "%29") + ") center center / contain no-repeat" }}
                    >
                    </div>
                    <div style={{ background: "white", borderRadius: "50%" }} className="clickable clear-icon" onClick={() => {
                        images.splice(i, 1)
                        saveImages(`${jsonPath}.images`, images)
                    }}>
                        <ClearIcon />
                    </div>
                </div>
            })}
            <div className="thumbnail upload clickable">
                <div className="img">
                    <label style={{ cursor: "pointer", textAlign: "center", width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <input type="file" accept="image/*" onChange={(e) => handleImageUpload(e, `${jsonPath}.images`, restaurantId, successCallbackBind, tier)} style={{ display: "none" }} />
                        <div><PhotoCameraIcon /></div>
                    </label>
                </div>
            </div>
        </div>
    </div >

}

export { AddImage };