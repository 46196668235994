import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import './ColorUtils.css'

export function contrastingColorBw(color) {
    if (!color) return "#000000"

    color = (color.charAt(0) === '#') ? color.substring(1, 7) : color;

    var r = parseInt(color.slice(0, 2), 16),
        g = parseInt(color.slice(2, 4), 16),
        b = parseInt(color.slice(4, 6), 16);

    // http://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 180
        ? '#000000'
        : '#FFFFFF';
}

export function ColorPicker({ callback, initColorHex, disabled, left, label }) {
    const [color, setColor] = useState(initColorHex)
    const [contrastingColor, setContrastingColor] = useState(contrastingColorBw(initColorHex || "#000000"))
    const [showSelector, setShowSelector] = useState(false)

    useEffect(() => {
        setColor(initColorHex)
    }, [initColorHex])

    useEffect(() => {
        setContrastingColor(contrastingColorBw(color))
    }, [color])

    const popover = {
        position: 'absolute',
        ...(left ? { left: '10%' } : { right: '10%' }),
        zIndex: '2',
    }

    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    const boxShadowColor = (contrastingColor === "#000000") ? "rgba(0, 0, 0, 0.1)" : color

    return <>
        {showSelector && <div style={cover} onClick={() => setShowSelector(false)} />}
        <div
            className="custom-color-picker"
            style={{ backgroundColor: color, color: contrastingColor, boxShadow: `0 0 0 2px ${boxShadowColor}` }}
            onClick={() => { if (!disabled) setShowSelector(true) }}>
            {
                showSelector ? <div style={popover}>
                    <SketchPicker
                        color={color}
                        onChangeComplete={(selectedColor) => {
                            setColor(selectedColor.hex)
                            callback(selectedColor.hex)
                        }} />
                </div> : <span>{label || ""}</span>
            }
        </div>
    </>
}