import { config } from '../Constants'

export function logout(onLogout) {
    fetch(`${config.url.API}/logout`, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include"
    }).then(onLogout)
}

// TODO: merge in one method
export function loadName() {
    return fetch(`${config.url.API}/restaurant/name`, {
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include"
    }).then(resp => {
        if (resp.status === 200) {
            return resp.json()
        }
    })
}

export function loadAddress() {
    return fetch(`${config.url.API}/restaurant/address`, {
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include"
    }).then(resp => {
        if (resp.status === 200) {
            return resp.json()
        }
    })
}

export function loadBusinesses() {
    return fetch(`${config.url.API}/list-businesses`, {
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include"
    })
}

export function loadSocialAccounts() {
    return fetch(`${config.url.API}/restaurant/social-accounts`, {
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include"
    })
}

export function loadTelephone() {
    return fetch(config.url.API + config.endpoints['business-telephone'], {
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include"
    }).then(resp => {
        if (resp.status === 200) {
            return resp.json()
        }
    })
}

export function loadSolutions() {
    return fetch(`${config.url.API}/restaurant/solutions`, {
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include"
    }).then(resp => {
        if (resp.status === 200) {
            return resp.json()
        }
        return null
    })
}


// TODO: refactor utils file (e.g. rename)
export function loadOrders({ restaurantId, status, page, orderNumber }) {
    var url = `${config.url.API}/orders/${restaurantId}?status=${JSON.stringify(status)}` + (page != undefined ? `&page=${page}` : "")

    if (orderNumber) url += `&order_number=${orderNumber}`

    return fetch(url, {
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include"
    }).then(resp => {
        if (resp.status === 200) {
            return resp.json()
        }
    })
}
