import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import printJS from 'print-js'
import { config } from './Constants';
import './qrcodepage.css';
import QRcodeCanvas from './CanvasQRcode/QRcodeCanvas';
import { ColorPicker } from './utils/ColorUtils'
import { Link } from 'react-router-dom';
import ColorizeIcon from '@mui/icons-material/Colorize';
import { v4 as uuidv4 } from 'uuid';
import { SaveButton } from './utils/Data';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getJsonData, putJsonData } from './utils/MenuUtils'
import { ReactComponent as CheckerSVG } from './icons/checker.svg';
import { useEffect } from 'react';


/** TODO: Put Copy button in utils */

function Copy({ textToCopy }) {
    const [copied, setCopied] = useState(false)

    return <div className="copy" onClick={() => {
        navigator.clipboard.writeText(textToCopy)
        setCopied(true)
        setTimeout(() => { setCopied(false) }, 1000)
    }}>
        <ContentCopyIcon />
        {copied && <div className='copied'>Copied</div>}
    </div>
}

function MenuQRAccess({ menuId, callback, renewCredentials }) {
    const [loading, setLoading] = useState(false)

    return <div
        className="tas-button"
        style={{ marginLeft: "auto" }}
        onClick={() => {
            setLoading(true)
            getJsonData(`${config.url.API}/qrcode/design/${menuId}`, renewCredentials).then((data) => {
                setLoading(false)
                callback(data)
            })
        }}>
        <b>QR code</b>
        {loading && <div className='loading'>Loading...</div>}
    </div>
}

function qrCodeUrl({ alias, menuId, restaurantId, selectedLang, tableNumber, customDomain }) {
    var resultUrl = (customDomain ? `https://${customDomain}` : config.url.SITES.replace('$1', alias || restaurantId)) + `?menu=${menuId}&utm_medium=qrcode`

    if (restaurantId === "4a4ca98c-9a6e-487c-ba6f-317eb9b03c2d") resultUrl += "&venue=the-gallery-restaurant" // TODO: remove

    if (selectedLang) resultUrl += `&lang=${selectedLang}`
    if (tableNumber) resultUrl += `&table=${tableNumber}`

    return resultUrl
}

function YourQRCode({ metadata, restaurantId, renewCredentials, updateMetadata, customDomain }) {
    const [logoOnTop, setLogoOnTop] = useState(false)
    const [logoInMiddle, setLogoInMiddle] = useState(false)
    const [logoInMiddleNoBg, setLogoInMiddleNoBg] = useState(false)
    const [bgColor, setBgColor] = useState("transparent")
    const [fgColor, setFgColor] = useState("#000000")
    const [selectedMenu, setSelectedMenu] = useState("")
    const [text, setText] = useState("")
    const [pattern, setPattern] = useState("square")
    const [canvasList, setCanvasList] = useState([])
    const [canId, setCanId] = useState(uuidv4())
    const [saved, setSaved] = useState(true)
    const [tableFrom, setTableFrom] = useState(NaN)
    const [tableTo, setTableTo] = useState(NaN)
    const [tableN, setTableN] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9])
    const [withTable, setWithTable] = useState(false)

    const [selectedLang, setSelectedLang] = useState("")
    const [withContour, setWithContour] = useState(false)
    const [contourColor, setContourColor] = useState("#000000")

    const { t } = useTranslation('app');


    function callback(canvas) {
        setCanvasList([...canvasList, canvas]);
    }

    function saveDesign() {
        putJsonData(`${config.url.API}/qrcode/design/${selectedMenu}`,
            {
                design: {
                    logo_top: logoOnTop,
                    logo_middle: logoInMiddle,
                    logo_middle_plain: logoInMiddleNoBg,
                    bg_color: bgColor,
                    fg_color: fgColor,
                    text: text,
                    pattern: pattern,
                    table_from: tableFrom,
                    table_to: tableTo
                }
            },
            renewCredentials
        ).then(resp => {
            if (resp.status === 200) setSaved(true)
        })
    }

    useEffect(() => {
        setCanvasList([])
        setCanId(uuidv4())
        setSaved(false)
    }, [tableTo, tableFrom, text, logoOnTop, logoInMiddle, logoInMiddleNoBg, bgColor, fgColor, pattern])

    useEffect(() => {
        if (!tableFrom || !tableTo || isNaN(tableFrom) || isNaN(tableTo)) {
            setWithTable(false)
            setTableN([1, 2, 3, 4, 5, 6, 7, 8, 9])
        } else {
            const t = []
            for (let j = tableFrom; j < tableTo + 1; j++) {
                t.push(j)
            }
            setTableN([...t])
            setWithTable(true)
        }
    }, [tableFrom, tableTo])

    useEffect(() => setSaved(true), [selectedMenu])

    useEffect(() => setSaved(true), [])

    if (Object.keys(metadata.menus).length === 0) return <div className="no-menu">
        <div><b>{t('general.no-menu')}</b></div>
        <Link to="/edit"><div className="tas-button">{t('general.create')}</div></Link>
    </div>

    return <div className="your-qr-code-page">
        <div className="app-menu">
            <div className="first-row">
                {/* <div>
                    <SaveButton key={saved} saved={saved} callback={(() => { if (!saved) saveDesign() }).bind(this)} />
                </div> */}
            </div>
        </div>
        <div className="content">
            {selectedMenu ? <>
                <div className="header-row" style={{ margin: "1.5rem auto" }}>
                    <div className='back-button' onClick={() => setSelectedMenu(null)}><ArrowBackIcon />Back</div>
                    <div style={{ fontSize: "175%", margin: "1.5rem 0" }}><b>{selectedMenu ? metadata.menus[selectedMenu].label : null}</b></div>
                </div>
                <div className='bottom-row'>
                    <div className="qrcode-left">
                        <div>
                            <div id="print-area">
                                {tableN.map(i => {
                                    const wText = withTable ? `Table ${i}` : undefined
                                    return <div>
                                        <QRcodeCanvas
                                            key={canId}
                                            pattern={pattern}
                                            fgColor={fgColor}
                                            bgColor={bgColor}
                                            callback={callback}
                                            qrCodeUrl={qrCodeUrl({
                                                alias: metadata.alias,
                                                menuId: selectedMenu,
                                                restaurantId,
                                                selectedLang,
                                                customDomain,
                                                tableNumber: (withTable) ? i : undefined
                                            })}
                                            tableNumber={(withTable) ? i : undefined}
                                            id={`can-${i}-${wText || ''}`}
                                            text={text}
                                            contourColor={(withContour) ? contourColor : null}
                                            topFigure={(logoOnTop) ? `${config.url.PUBLIC}/restaurants/${restaurantId}/${metadata.logo.src}` : null}
                                            middleFigure={(logoInMiddle || logoInMiddleNoBg) ? `${config.url.PUBLIC}/restaurants/${restaurantId}/${metadata.logo.src}` : null}
                                            excavate={logoInMiddle}
                                        />
                                    </div>
                                })}
                            </div>
                            <div className="option-buttons">
                                <div className='clickable-icon'
                                    onClick={() => {
                                        var link = document.createElement('a');

                                        link.setAttribute('download', null);
                                        link.style.display = 'none';

                                        document.body.appendChild(link);

                                        if (!isNaN(tableFrom) && !isNaN(tableTo)) {
                                            canvasList.forEach((canvas, i) => {
                                                link.setAttribute('download', `qrcode-table-${i + tableFrom || 'unique'}.png`)
                                                link.setAttribute('href', canvas.toDataURL());
                                                link.click();
                                            })
                                        } else {
                                            // we download only one QR code as they are all the same
                                            link.setAttribute('download', `qrcode.png`)
                                            link.setAttribute('href', canvasList[0]?.toDataURL());
                                            link.click();
                                        }
                                    }}><DownloadIcon /></div>
                                <div className="clickable-icon" onClick={() => {
                                    window.gtag('event', 'generate_qrcode', { 'with_logo': false, 'method': 'print' });
                                    printJS({
                                        printable: "print-area",
                                        type: 'html',
                                        scanStyles: false,
                                        style: `
                                    #print-area > div {
                                        display: inline-block;
                                        border: 1px dotted lightgrey;
                                    }

                                    #print-area canvas {
                                        width: 5.5cm;
                                      }
                                    `
                                    });
                                }}><PrintIcon /></div>
                            </div>
                        </div>
                    </div>
                    <div className="qrcode-right">
                        <div className='qr-content'>
                            <div className="floating-save">
                                <SaveButton key={saved} saved={saved} callback={(() => { if (!saved) saveDesign() }).bind(this)} />
                            </div>
                            <div>
                                <div><b>Pattern</b></div>
                                <div className="qr-dedign-options">
                                    <div
                                        className={"qr-design-option square" + (pattern === "square" ? " selected" : "")}
                                        onClick={() => setPattern("square")}
                                    ></div>
                                    <div
                                        className={"qr-design-option dot" + (pattern === "dot" ? " selected" : "")}
                                        onClick={() => setPattern("dot")}
                                    ></div>
                                </div>
                            </div>
                            <div>
                                <div><b>Color</b></div>
                                <div className="qr-dedign-options">
                                    {["#000000", "#8B5160", "#F6B162", "#8685EF", "#FF00FF", "#657B81", "#00C896"].map(color => (
                                        <div
                                            onClick={() => setFgColor(color)}
                                            className={"qr-design-option" + (fgColor === color ? " selected" : "")} style={{ background: color }}></div>
                                    ))}
                                    < div style={{ display: "inline-block", position: "relative" }}>
                                        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}><ColorizeIcon /></div>
                                        <ColorPicker callback={(newColorHex) => setFgColor(newColorHex)} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div><b>Background</b></div>
                                <div className="qr-dedign-options">
                                    <div
                                        style={{ width: "2rem", height: "2rem", padding: "0" }}
                                        onClick={() => setBgColor("transparent")}
                                        className={"qr-design-option" + (bgColor === "transparent" ? " selected" : "")}><CheckerSVG /></div>
                                    <div
                                        style={{ width: "2rem", height: "2rem", padding: "0" }}
                                        onClick={() => setBgColor("#ffffff")}
                                        className={"qr-design-option white" + (bgColor === "#ffffff" ? " selected" : "")}></div>
                                    {["#000000", "#8685EF", "#FF00FF", "#657B81", "#00C896"].map(color => (
                                        <div
                                            onClick={() => setBgColor(color)}
                                            className={"qr-design-option" + (bgColor === color ? " selected" : "")} style={{ background: color }}></div>
                                    ))}
                                    <div style={{ display: "inline-block", position: "relative" }}>
                                        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}><ColorizeIcon /></div>
                                        <ColorPicker callback={(newColorHex) => setBgColor(newColorHex)} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div><b>{t('general.logo')}</b></div>
                                <div className="qr-dedign-options logo">
                                    <div className={"qr-design-option" + (logoInMiddleNoBg ? " selected" : "")} onClick={() => {
                                        setLogoInMiddleNoBg(prev => !prev)
                                        setLogoInMiddle(false)
                                    }}>Middle</div>
                                    <div className={"qr-design-option" + (logoInMiddle ? " selected" : "")} onClick={() => {
                                        setLogoInMiddle(prev => !prev)
                                        setLogoInMiddleNoBg(false)
                                    }}>Middle excavate</div>
                                    <div className={"qr-design-option" + (logoOnTop ? " selected" : "")} onClick={() => {
                                        setLogoOnTop(prev => !prev)
                                    }} ><span>Top</span></div>
                                </div>
                            </div>
                            <div>
                                <div><b>{t('general.text')}</b></div>
                                <div className="qr-dedign-options">
                                    <input placeholder="Enter text" value={text} onChange={(e) => {
                                        setText(e.target.value)
                                    }} />
                                </div>
                            </div>
                            <div>
                                <div><b>Add table number to QR code</b></div>
                                <div className="qr-dedign-options">
                                    Table from <input min={0} value={isNaN(tableFrom) ? null : tableFrom} style={{ width: "3rem", textAlign: "center" }} type="number"
                                        onChange={(e) => {
                                            if (e.target.value === 0 || isNaN(e.target.value)) {
                                                setTableFrom(NaN)
                                                setTableTo(NaN)
                                            } else {
                                                const newValue = parseInt(e.target.value)
                                                if (newValue > tableTo) setTableTo(newValue)
                                                setTableFrom(newValue)
                                            }
                                        }} />
                                    &#160;to <input type="number" value={isNaN(tableTo) ? null : tableTo} size={5} style={{ width: "3rem", textAlign: "center" }}
                                        onChange={(e) => {
                                            if (isNaN(e.target.value)) {
                                                setTableFrom(NaN)
                                                setTableTo(NaN)
                                            } else {
                                                setTableTo(parseInt(e.target.value))
                                            }
                                        }} min={tableFrom || 1} /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </> : <>
                <div className="header-row">
                    <div style={{ fontSize: "175%", margin: "3rem 0" }}><b>Your menus</b></div>
                </div>
                <div>
                    <div className='menu-line-header' style={{ fontSize: "90%" }}>
                        <div className='menu-name'><b>Menu</b></div>
                        <div className='menu-url'><b>Link</b></div>
                        <div style={{ marginLeft: "auto", width: "110px", padding: "0 0 0 0.5rem", background: "transparent" }}><b>QR code</b></div>
                    </div>
                    {Object.keys(metadata.menus).map(menuId => {
                        var urlToGo = (customDomain ? `https://${customDomain}` : config.url.SITES.replace('$1', metadata.alias || restaurantId)) + `?menu=${menuId}&utm_medium=qrcode`
                        if (restaurantId === "4a4ca98c-9a6e-487c-ba6f-317eb9b03c2d") urlToGo += "&venue=the-gallery-restaurant" // TODO: remove

                        return <div className='menu-line'>
                            <div className='menu-name'><b>{metadata.menus[menuId].label}</b></div>
                            <div className='menu-url'>
                                <a target="_blank" href={urlToGo}>{urlToGo}</a>
                                <Copy textToCopy={urlToGo} />
                            </div>
                            <MenuQRAccess
                                menuId={menuId}
                                callback={(designData) => {
                                    setSelectedMenu(menuId)
                                    setLogoOnTop(designData.logo_top || false)
                                    setLogoInMiddle(designData.logo_middle || false)
                                    setLogoInMiddleNoBg(designData.logo_middle_plain || false)
                                    setBgColor(designData.bg_color || "transparent")
                                    setFgColor(designData.fg_color || "#000000")
                                    setTableFrom(designData.table_from || NaN)
                                    setTableTo(designData.table_to || NaN)
                                    setText(designData.text || "")
                                    setCanvasList([])
                                    setSelectedLang("")
                                    setPattern(designData.pattern || "square")
                                    setCanId(uuidv4())
                                    setSaved(true)
                                }}
                                renewCredentials={renewCredentials} />
                        </div>
                    })}
                </div>
            </>}
        </div>
    </div >
}

export default YourQRCode;
