
import { config } from '../Constants'

export async function saveMetadata({ restaurantId, metadata }) {
    return fetch(`${config.url.API}/metadata/${restaurantId}`, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify({
            metadata: JSON.stringify(metadata)
        })
    })
}

export function firstMenuId(metadata) {
    if (Object.keys(metadata.menus).length === 0) return undefined  // TODO: needed ?
    return Object.keys(metadata.menus)[0]
}

export function firstLanguage(menu) {
    if ((!menu) || menu['supportedLanguages'].length === 0) return undefined
    return menu['supportedLanguages'][0]
}
