import React, { useState, useEffect } from 'react';
import EditView from './EditView';
import IdentityView from './BusinessInformation';
import AccountView from './Account/index';
import AppStore from './AppStore/AppStore';
import ThemeView from './SetupPages/ThemeViewOnboarding';
import ContactView from './support/ContactView';
import QRcodeView from './QRcodeView';
import { config } from './Constants'
import { useTranslation } from "react-i18next";
import { Route, Routes, Link, Navigate, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { saveMetadata } from './utils/MetadataUtils';
import PPEditView from './PublicPage/EditView'
import { default as PPDesignView } from './PublicPage/DesignView'
import Navbar from './Navbar/Navbar'
import { UpgradeModal, TranslationRequestModal, PricingModal, InvoicesModal } from './utils/Disclaimers'
import { Loader } from './utils/MenuUtils'
import { detect } from 'detect-browser'
import WebsiteQRcode from './PublicPage/Qrcode';
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import "./App.scss";
import { TasLoader } from './utils/general';
import { markOnboardingDone } from './utils/general';
// import { ToastContainer, toast } from 'react-toastify';
// import CustomerInsights from './CustomerInsights/customerActivity'
// import CustomerAccounts from './CustomerInsights/customerAccounts'


const AnalyticsView = React.lazy(() => import('./AnalyticsView'));
const TableReservation = React.lazy(() => import('./TableReservation/TableReservation'));
const TableConfiguration = React.lazy(() => import('./TableReservation/TableConfiguration'));
const OpeningHours = React.lazy(() => import('./TableReservation/TableOpeningHours'));
const MyOrdersView = React.lazy(() => import('./MyOrders/MyOrdersView'));
const MyOrdersSettings = React.lazy(() => import('./MyOrders/MyOrdersSettings'));
const MyOrdersReporting = React.lazy(() => import('./MyOrders/MyOrdersReporting'));
const MyTags = React.lazy(() => import('./MyOrders/MyTags'));
const MyInvoices = React.lazy(() => import('./MyOrders/MyInvoices'));
const Coupons = React.lazy(() => import('./MyOrders/Coupons'));
const Promotions = React.lazy(() => import('./MyOrders/Promotions'));
const ActivateMyOrdersView = React.lazy(() => import('./MyOrders/ActivateMyOrdersView'));
const ContactTracingView = React.lazy(() => import('./ContactTracingView'));
const PaperMenuView = React.lazy(() => import('./PaperMenu/PaperMenuView'));
const MessagesView = React.lazy(() => import('./MessagesView'));
const MarketingView = React.lazy(() => import('./Marketing/MarketingView'));
const SurveysEdit = React.lazy(() => import('./Surveys/Edit'));
const SurveysQrCode = React.lazy(() => import('./Surveys/Qrcode'));
const SurveysResults = React.lazy(() => import('./Surveys/ResultsV2'));

function NoAccess() {
    const { t } = useTranslation('app');
    return <div className="no-access">
        <div>
            <div>{t('no-access.desc')}</div>
            <div>{t('no-access.pbm')}<Link to="/support">&#160;{t('general.contact-us')}</Link></div>
        </div>
    </div>
}

async function getSession() {
    return fetch(`${config.url.API}/restaurant/session`, {
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include"
    })
}

async function getOpenInvoices() {
    return fetch(`${config.url.API}/stripe/invoices/outstanding`, {
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include"
    })
}

function RenewCredentialsPopup() { // TODO
    return <></>
}


function WelcomePopup({ onClose, functionalities }) {
    const { t } = useTranslation('app')

    var steps = [
        { element: '#nav > div', popover: { title: t('tour.steps.nav.title'), description: t('tour.steps.nav.desc'), side: "left", align: 'start' } },
        { element: 'a[href="/account"] > li', popover: { title: t('tour.steps.account.title'), description: t('tour.steps.account.desc'), side: "left", align: 'start' } },
        { element: 'a[href="/business-info"] > li', popover: { title: t('tour.steps.business.title'), description: t('tour.steps.business.desc'), side: "left", align: 'start' } },
    ]

    if (functionalities.includes("digital-menu")) steps.push(
        { element: '#digital-menu', popover: { title: t('tour.steps.menus.title'), description: t('tour.steps.menus.desc'), side: "left", align: 'start' } },
    )

    if (functionalities.includes("ordering")) steps.push(
        { element: '#ordering', popover: { title: t('tour.steps.orders.title'), description: t('tour.steps.orders.desc'), side: "left", align: 'start' } },
    )

    if (functionalities.includes("public-page")) steps.push(
        { element: '#public-page', popover: { title: t('tour.steps.website.title'), description: t('tour.steps.website.desc'), side: "left", align: 'start' } },
    )

    if (functionalities.includes("table-reservation")) steps.push(
        { element: '#table-reservation', popover: { title: t('tour.steps.tables.title'), description: t('tour.steps.tables.desc'), side: "left", align: 'start' } },
    )

    steps.push(
        { element: 'a[href="/appstore"] > li', popover: { title: t('tour.steps.apps.title'), description: t('tour.steps.apps.desc'), side: "left", align: 'start' } },
    )

    steps.push(
        { element: 'a[href="/support"] > li', popover: { title: t('tour.steps.support.title'), description: t('tour.steps.support.desc'), side: "left", align: 'start' } },
    )


    const driverObj = driver({
        animate: false,
        allowClose: false,
        showProgress: true,
        showButtons: ['next', 'previous', 'close'],
        nextBtnText: t('setup.next'),
        prevBtnText: t('setup.previous'),
        doneBtnText: t('finish'),
        steps
    });

    function onStart() {
        if (document.getElementById('nav')) {
            driverObj.drive();
        }
    }

    if (window.innerWidth <= 1023) { // This is mobile
        onClose()
        return <></>
    }

    return <div id='welcome-popup'>
        <div id='popup-container'>
            <h2>{t('tour.title')}</h2>
            <div className='desc'>{t('tour.desc')}</div>
            <div className="buttons-wrapper">
                <div><a onClick={onClose}>{t('tour.skip')}</a></div>
                <button className='tas-button' onClick={() => {
                    onStart()
                    onClose()
                }}>{t('tour.start')}</button>
            </div>
        </div>
    </div>
}

// Session -> name, tier, accessRights, solutions, custom_domain, solutions_setup, hasPlan

function App({ defaultMetadata, onLogout, onLogin, pathname }) { // TODO: remove onLogin
    const [metadata, setMetadata] = useState(defaultMetadata)
    const [saved, setSaved] = useState(true)
    const [defaultRedirect, setDefaultRedirect] = useState('/account') // TODO: rename accountDefaultRedirect
    const [session, setSession] = useState(null)
    const [showFooter, setShowFooter] = useState(false)
    const [renewCredentials, setRenewCredentials] = useState(false)
    const [openInvoices, setOpenInvoices] = useState([])

    const { t } = useTranslation('app')

    const navigate = useNavigate();

    const event = new URLSearchParams(window.location.search).get("event")

    // TODO: ?????
    // if (window.location.search) {
    //     this.event = new URLSearchParams(window.location.search).get("event")
    // window.gtag('event', 'upgrade-to-pro');
    // }

    useEffect(() => {
        getSession().then(resp => {
            if (resp.status === 200) {
                resp.json().then(sessionData => {

                    const newDefaultRedirect = sessionData.solutions.includes('digital-menu') ? '/edit'
                        : (sessionData.solutions.includes('public-page') ? '/website/edit'
                            : (sessionData.solutions.includes('table-reservation') ? '/reservations'
                                : (sessionData.solutions.includes('marketing') ? '/marketing' : '/account')))
                    setDefaultRedirect(newDefaultRedirect)

                    setSession({ ...sessionData }) // TODO: ... needed?
                    setShowFooter(true)
                })
            } else if (resp.status === 403) {
                return setRenewCredentials(true)
            } else {
                return navigate('/login') // Should return RESP instead
            }
        })

        if (window.sendEvent) {
            const browser = detect()

            if (browser) {
                window.sendEvent("device", `${browser.name} | ${browser.version} | ${browser.os} | ${browser.type} | ${window.screen.width} x ${window.screen.height}`)
            } else {
                window.sendEvent("device", "unknown")
            }
        }

        getOpenInvoices().then(resp => {
            if (resp.status === 200) {
                resp.json().then(setOpenInvoices)
            }
        })
    }, [])

    async function saveAndUpdateMetadata(newMetadata) {
        const saveSuccessful = await saveMetadata({ restaurantId: metadata.id, metadata: newMetadata }).then(resp => {
            if (resp.status === 403) {
                setRenewCredentials(true)
                return false
            }
            if (resp.status === 200) return true
            return false
        });

        if (saveSuccessful) {
            setMetadata(newMetadata);
            return true;
        } else {
            return false;
        }
    }

    function updateSession(newSession) {
        setSession({ ...session, ...newSession })
    }

    if (session === null) {
        return <><TasLoader /></>
    }

    if (renewCredentials) {
        return <RenewCredentialsPopup /> /** TODO: implement and test */
    }

    return <div id="page">
        {event === "upgrade-to-pro" && <UpgradeModal />}
        {event === "translation-request" && <TranslationRequestModal />}
        {!session.onboarding_finished && <WelcomePopup functionalities={session.solutions || []} onClose={() => {
            markOnboardingDone()
            setSession({ ...session, onboarding_finished: true })
        }} />}
        {session.tier === "pay-tier" && <PricingModal defaultShowModal={true} stayOpen={true} title={t('general.trial-ended')} />}
        {openInvoices.length > 0 && <InvoicesModal invoices={openInvoices} onLogout={onLogout} />}
        <Navbar solutions={session.solutions} pathname={window.location.pathname} onLogout={onLogout} tier={session.tier} metadata={metadata} restaurantId={metadata.id} />
        <div id="page-subcontent" style={{ display: "flex" }}>
            {/* <Prompt when={!this.state.saved} message={t('app:unsaved-warning')}></Prompt> TODO */}
            <div id="view-container">
                <Routes>
                    <Route path="/account" element={
                        <AccountView
                            restaurantId={metadata.id}
                            metadata={metadata}
                            name={session.name}
                            updateMetadata={saveAndUpdateMetadata}
                            updateMetadataWithoutSaving={setMetadata}
                            renewCredentials={() => setRenewCredentials(true)}
                            search={window.location.search}
                            customDomain={session.custom_domain}
                            userAccessRights={session.accessRights}
                            hasPlan={session.hasPlan}
                            onLogout={onLogout}
                            tier={session.tier} />
                    } />
                    <Route path="/business-info" element={
                        (session.accessRights.full_admin_access) ?
                            <IdentityView
                                restaurantId={metadata.id}
                                metadata={metadata}
                                name={session.name}
                                updateMetadata={saveAndUpdateMetadata}
                                updateMetadataWithoutSaving={setMetadata}
                                renewCredentials={() => setRenewCredentials(true)}
                                search={window.location.search}
                                customDomain={session.custom_domain}
                                userAccessRights={session.accessRights}
                                hasPlan={session.hasPlan}
                                tier={session.tier} />
                            : <NoAccess />
                    } />
                    <Route path="/pricing" element={
                        (session.accessRights.full_admin_access) ?
                            <IdentityView
                                restaurantId={metadata.id}
                                metadata={metadata}
                                name={session.name}
                                updateMetadata={saveAndUpdateMetadata}
                                updateMetadataWithoutSaving={setMetadata}
                                renewCredentials={() => setRenewCredentials(true)}
                                search={window.location.search}
                                customDomain={session.custom_domain}
                                comparePlans={true}
                                onLogout={onLogout}
                                tier={session.tier} />
                            : <NoAccess />
                    } />
                    <Route path="/edit" element={
                        (session.accessRights.full_admin_access) ?
                            <EditView
                                restaurantId={metadata.id}
                                metadata={metadata}
                                saved={saved}
                                session={session}
                                updateSession={updateSession}
                                tier={session.tier}
                                onSavedUpdated={(newSaved) => setSaved(newSaved)}
                                updateMetadata={saveAndUpdateMetadata}
                                renewCredentials={() => setRenewCredentials(true)} />
                            : <NoAccess />
                    } />
                    <Route path="/appearance" element={
                        (session.accessRights.full_admin_access) ?
                            <ThemeView
                                metadata={metadata}
                                restaurantId={metadata.id}
                                tier={session.tier}
                                updateMetadata={saveAndUpdateMetadata}
                                renewCredentials={() => setRenewCredentials(true)}
                            />
                            : <NoAccess />
                    } />
                    <Route path="/qrcode" element={
                        (session.accessRights.full_admin_access) ?
                            <QRcodeView
                                restaurantId={metadata.id}
                                renewCredentials={() => setRenewCredentials(true)}
                                metadata={metadata}
                                customDomain={session.custom_domain}
                                updateMetadata={saveAndUpdateMetadata} />
                            : <NoAccess />
                    } />
                    <Route path="/paper-menu" element={
                        (session.accessRights.full_admin_access) ?
                            <React.Suspense fallback={<><Loader /></>}>
                                <PaperMenuView
                                    metadata={metadata}
                                    tier={session.tier}
                                    restaurantId={metadata.id}
                                    renewCredentials={() => setRenewCredentials(true)} />
                            </React.Suspense>
                            : <NoAccess />
                    } />
                    <Route path="/messages" element={
                        (session.accessRights.full_admin_access) ?
                            <React.Suspense fallback={<><Loader /></>}>
                                <MessagesView
                                    metadata={metadata}
                                    tier={session.tier}
                                    updateMetadata={saveAndUpdateMetadata}
                                    restaurantId={metadata.id}
                                    renewCredentials={() => setRenewCredentials(true)} />
                            </React.Suspense>
                            : <NoAccess />
                    } />
                    <Route path="/analytics" element={
                        (session.accessRights.full_admin_access) ?
                            <React.Suspense fallback={<><Loader /></>}>
                                <AnalyticsView
                                    tier={session.tier}
                                    restaurantId={metadata.id}
                                    renewCredentials={() => setRenewCredentials(true)} />
                            </React.Suspense>
                            : <NoAccess />
                    } />
                    <Route path="/tracing" element={
                        (session.accessRights.full_admin_access) ?
                            <React.Suspense fallback={<><Loader /></>}>
                                <ContactTracingView
                                    restaurantId={metadata.id}
                                    renewCredentials={() => setRenewCredentials(true)}
                                    updateMetadata={saveAndUpdateMetadata}
                                    metadata={metadata} />
                            </React.Suspense>
                            : <NoAccess />
                    } />
                    <Route exact path="/support/:subpage?" element={
                        <ContactView
                            restaurantId={metadata.id}
                            renewCredentials={() => setRenewCredentials(true)}
                        />}
                    />
                    {/* <Route exact path="/insights/activity"
                    render={() => <CustomerInsights renewCredentials={() => setRenewCredentials(true)} />}
                >
                </Route>
                <Route exact path="/insights/accounts"
                    render={() => <CustomerAccounts renewCredentials={() => setRenewCredentials(true)} />}
                >
                </Route> */}
                    <Route exact path="/website/edit" element={
                        (session.accessRights.full_admin_access) ?
                            <PPEditView
                                metadata={metadata}
                                restaurantId={metadata.id}
                                customDomain={session.custom_domain}
                                tier={session.tier}
                                renewCredentials={() => setRenewCredentials(true)} />
                            : <NoAccess />
                    } />
                    <Route exact path="/website/qrcode" element={
                        (session.accessRights.full_admin_access) ?
                            <WebsiteQRcode
                                metadata={metadata}
                                restaurantId={metadata.id}
                                customDomain={session.custom_domain}
                                tier={session.tier}
                                renewCredentials={() => setRenewCredentials(true)} />
                            : <NoAccess />
                    } />
                    <Route exact path="/support/tickets/:ticketId" element={
                        <ContactView
                            restaurantId={metadata.id}
                            viewSelected={"tickets"}
                            renewCredentials={() => setRenewCredentials(true)}
                        />
                    } />
                    <Route exact path="/website/design" element={
                        (session.accessRights.full_admin_access) ?
                            <PPDesignView
                                metadata={metadata}
                                restaurantId={metadata.id}
                                renewCredentials={() => setRenewCredentials(true)}
                            />
                            : <NoAccess />
                    } />
                    <Route exact path="/orders" element={
                        (session.accessRights.full_admin_access || session.accessRights.orders_access) ?
                            (metadata.ordering !== undefined ?
                                <React.Suspense fallback={<><Loader /></>}>
                                    <MyOrdersView
                                        restaurantId={metadata.id}
                                        metadata={metadata}
                                        updateMetadata={saveAndUpdateMetadata}
                                        renewCredentials={() => setRenewCredentials(true)} />
                                </React.Suspense>
                                :
                                <React.Suspense fallback={<><Loader /></>}>
                                    <ActivateMyOrdersView
                                        restaurantId={metadata.id}
                                        tier={session.tier}
                                        metadata={metadata}
                                        updateMetadata={saveAndUpdateMetadata}
                                        renewCredentials={() => setRenewCredentials(true)} />
                                </React.Suspense>
                            )
                            : <NoAccess />
                    } />
                    <Route path="/orders/reporting" element={
                        (session.accessRights.full_admin_access || session.accessRights.orders_access) ?
                            (metadata.ordering !== undefined ?
                                <React.Suspense fallback={<><Loader /></>}>
                                    <MyOrdersReporting
                                        restaurantId={metadata.id}
                                        renewCredentials={() => setRenewCredentials(true)} />
                                </React.Suspense>
                                :
                                <React.Suspense fallback={<><Loader /></>}>
                                    <ActivateMyOrdersView
                                        restaurantId={metadata.id}
                                        tier={session.tier}
                                        metadata={metadata}
                                        updateMetadata={saveAndUpdateMetadata}
                                        renewCredentials={() => setRenewCredentials(true)} />
                                </React.Suspense>
                            )
                            : <NoAccess />
                    } />
                    <Route path="/orders/settings" element={
                        (session.accessRights.full_admin_access || session.accessRights.orders_access) ?
                            (metadata.ordering !== undefined ?
                                <React.Suspense fallback={<><Loader /></>}>
                                    <MyOrdersSettings
                                        restaurantId={metadata.id}
                                        alias={metadata?.alias || ""}
                                        renewCredentials={() => setRenewCredentials(true)} />
                                </React.Suspense>
                                :
                                <React.Suspense fallback={<><Loader /></>}>
                                    <ActivateMyOrdersView
                                        restaurantId={metadata.id}
                                        tier={session.tier}
                                        metadata={metadata}
                                        updateMetadata={saveAndUpdateMetadata}
                                        renewCredentials={() => setRenewCredentials(true)} />
                                </React.Suspense>
                            )
                            : <NoAccess />
                    } />
                    <Route path="/orders/tags" element={
                        (session.accessRights.full_admin_access || session.accessRights.orders_access) ?
                            (metadata.ordering !== undefined ?
                                <React.Suspense fallback={<><Loader /></>}>
                                    <MyTags
                                        restaurantId={metadata.id}
                                        metadata={metadata}
                                        renewCredentials={() => setRenewCredentials(true)} />
                                </React.Suspense>
                                :
                                <React.Suspense fallback={<><Loader /></>}>
                                    <ActivateMyOrdersView
                                        restaurantId={metadata.id}
                                        tier={session.tier}
                                        metadata={metadata}
                                        updateMetadata={saveAndUpdateMetadata}
                                        renewCredentials={() => setRenewCredentials(true)} />
                                </React.Suspense>
                            )
                            : <NoAccess />
                    } />
                    <Route path="/orders/invoices" element={
                        (session.accessRights.full_admin_access || session.accessRights.orders_access) ?
                            (metadata.ordering !== undefined ?
                                <React.Suspense fallback={<><Loader /></>}>
                                    <MyInvoices defaultName={session.name} />
                                </React.Suspense>
                                :
                                <React.Suspense fallback={<><Loader /></>}>
                                    <ActivateMyOrdersView
                                        restaurantId={metadata.id}
                                        tier={session.tier}
                                        metadata={metadata}
                                        updateMetadata={saveAndUpdateMetadata}
                                        renewCredentials={() => setRenewCredentials(true)} />
                                </React.Suspense>
                            )
                            : <NoAccess />
                    } />
                    <Route path="/orders/coupons" element={
                        (session.accessRights.full_admin_access || session.accessRights.orders_access) ?
                            (metadata.ordering !== undefined ?
                                <React.Suspense fallback={<><Loader /></>}>
                                    <Coupons
                                        restaurantId={metadata.id}
                                        metadata={metadata}
                                        renewCredentials={() => setRenewCredentials(true)} />
                                </React.Suspense>
                                :
                                <React.Suspense fallback={<><Loader /></>}>
                                    <ActivateMyOrdersView
                                        restaurantId={metadata.id}
                                        tier={session.tier}
                                        metadata={metadata}
                                        updateMetadata={saveAndUpdateMetadata}
                                        renewCredentials={() => setRenewCredentials(true)} />
                                </React.Suspense>
                            )
                            : <NoAccess />
                    } />
                    <Route path="/orders/promotions" element={
                        (session.accessRights.full_admin_access || session.accessRights.orders_access) ?
                            (metadata.ordering !== undefined ?
                                <React.Suspense fallback={<><Loader /></>}>
                                    <Promotions
                                        restaurantId={metadata.id}
                                        metadata={metadata}
                                        updateMetadata={saveAndUpdateMetadata}
                                        renewCredentials={() => setRenewCredentials(true)} />
                                </React.Suspense>
                                :
                                <React.Suspense fallback={<><Loader /></>}>
                                    <ActivateMyOrdersView
                                        restaurantId={metadata.id}
                                        tier={session.tier}
                                        metadata={metadata}
                                        renewCredentials={() => setRenewCredentials(true)} />
                                </React.Suspense>
                            )
                            : <NoAccess />
                    } />
                    <Route path="/reservations/tables" element={
                        <React.Suspense fallback={<><Loader /></>}>
                            <TableConfiguration
                                restaurantId={metadata.id}
                                tier={session.tier}
                                metadata={metadata}
                                updateMetadata={saveAndUpdateMetadata}
                                renewCredentials={() => setRenewCredentials(true)} />
                        </React.Suspense>
                    } />
                    <Route path="/reservations/settings" element={
                        <React.Suspense fallback={<><Loader /></>}>
                            <OpeningHours
                                restaurantId={metadata.id}
                                tier={session.tier}
                                metadata={metadata}
                                updateMetadata={saveAndUpdateMetadata}
                                renewCredentials={() => setRenewCredentials(true)} />
                        </React.Suspense>
                    } />
                    <Route path="/reservations" element={
                        <React.Suspense fallback={<><Loader /></>}>
                            <TableReservation
                                restaurantId={metadata.id}
                                tier={session.tier}
                                metadata={metadata}
                                updateMetadata={saveAndUpdateMetadata}
                                renewCredentials={() => setRenewCredentials(true)} />
                        </React.Suspense>
                    } />
                    <Route path="/orders/settings" element={
                        <React.Suspense fallback={<><Loader /></>}>
                            <MyOrdersSettings
                                restaurantId={metadata.id}
                                renewCredentials={() => setRenewCredentials(true)} />
                        </React.Suspense>
                    } />
                    <Route path="/marketing" element={
                        (session.accessRights.full_admin_access) ?
                            <React.Suspense fallback={<><Loader /></>}>
                                <MarketingView
                                    restaurantId={metadata.id}
                                    metadata={metadata}
                                    name={session.name}
                                    updateMetadata={saveAndUpdateMetadata}
                                    updateMetadataWithoutSaving={setMetadata}
                                    renewCredentials={() => setRenewCredentials(true)}
                                    search={window.location.search}
                                    customDomain={session.custom_domain}
                                    userAccessRights={session.accessRights}
                                    tier={session.tier} />
                            </React.Suspense>
                            : <NoAccess />
                    } />
                    <Route exact path="/surveys" element={
                        (session.accessRights.full_admin_access) ?
                            <React.Suspense fallback={<><Loader /></>}>
                                <SurveysEdit
                                    restaurantId={metadata.id} />
                            </React.Suspense>
                            : <NoAccess />
                    } />
                    <Route exact path="/surveys/qrcode" element={
                        (session.accessRights.full_admin_access) ?
                            <React.Suspense fallback={<><Loader /></>}>
                                <SurveysQrCode
                                    restaurantId={metadata.id}
                                    metadata={metadata} />
                            </React.Suspense>
                            : <NoAccess />
                    } />
                    <Route exact path="/surveys/results" element={
                        (session.accessRights.full_admin_access) ?
                            <React.Suspense fallback={<><Loader /></>}>
                                <SurveysResults
                                    restaurantId={metadata.id}
                                    metadata={metadata} />
                            </React.Suspense>
                            : <NoAccess />
                    } />
                    <Route path="/appstore" element={
                        (session.accessRights.full_admin_access) ?
                            <AppStore
                                solutions={session.solutions}
                                tier={session.tier} />
                            : <NoAccess />
                    } />
                    <Route path="/" element={
                        <Navigate to={(pathname && pathname !== '/') ? pathname : defaultRedirect} />
                    } />
                </Routes>
            </div>
        </div>
        {showFooter && <Footer renewCredentials={() => setRenewCredentials(true)} restaurantId={metadata.id} />}
    </div>

}

export default App;
