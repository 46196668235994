import React, { Component, useEffect, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import _ from 'lodash'; // optimize import
import { config } from './Constants'
import SettingsIcon from '@mui/icons-material/Settings';
import Popover from '@mui/material/Popover';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { withTranslation, useTranslation } from "react-i18next";
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { v4 as uuidv4 } from 'uuid';
import { AddImage, successCallbackMultiImages, saveImages } from './utils/Images';
import { SaveButton } from './utils/Data';
import { loadMenu, Loader, emptyItem as newItem, emptySection as newSection, emptySubsection as newSubsection, translationCheckout } from './utils/MenuUtils';
import WbIridescentIcon from '@mui/icons-material/WbIridescent';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import './EditView.css'
import { PricingModal } from './utils/Disclaimers'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Cookies from 'js-cookie'
import PreviewView from './PreviewView';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import EastIcon from '@mui/icons-material/East';
import CheckIcon from '@mui/icons-material/Check';
import Popper from '@mui/material/Popper';
import { PriceChange, Sync } from '@mui/icons-material';
import { CurrencyExchange } from '@mui/icons-material';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
// import { driver } from "driver.js";
// import "driver.js/dist/driver.css";
import { markSetupDone } from './utils/general';
import { SyncLanguages, SyncPrices } from './MenuEdit/EditPrices';
const EditPrices = React.lazy(() => import('./MenuEdit/EditPrices'));
const ConvertPrices = React.lazy(() => import('./MenuEdit/ConvertPrices'));
const UploadMenu = React.lazy(() => import('./MenuEdit/UploadMenu'));
const DownloadMenu = React.lazy(() => import('./MenuEdit/DownloadMenu'));
var numberparser = require("number-parsing");


const acfilter = createFilterOptions();

const allIcons = [
  "egg-free.svg",
  "grain-rice.svg",
  "green-chilli-spicy-1.svg",
  "green-chilli-spicy-2.svg",
  "green-chilli-spicy-3.svg",
  "red-chilli-spicy-1.svg",
  "red-chilli-spicy-2.svg",
  "red-chilli-spicy-3.svg",
  "fried-chicken.svg",
  "leaves.svg",
  "natural.svg",
  "non-vegetarian.svg",
  "organic.svg",
  "circle-organic.svg",
  "peanut-beans.svg",
  "seafood.svg",
  "spinach.svg",
  "takeaway.svg",
  "veg.svg",
  "non-veg.svg",
  "vegan.svg",
  "meat-beef.svg",
  "almond.svg",
  "baguette.svg",
  "carrot.svg",
  "cheese.svg",
  "pork.svg",
  "strawberry.svg",
  "raspberry.svg",
  "pineapple.svg",
  "orange.svg",
  "salt.svg",
  "pepper.svg",
  "banana.svg",
  "cherry.svg",
  "mango.svg",
  "lemon.svg",
  "apple.svg"
]

function codeToLang({ t }) {
  return {
    "en": t("edit.english"),
    "fr": t("edit.french"),
    "es": t("edit.spanish"),
    "de": t("edit.german"),
    "in": "Indonesian",
    "it": t("edit.italian"),
    "ar": t("edit.arabic"),
    "fa": t("edit.persian"),
    "zh": t("edit.mandarinchinese"),
    "pt": "Português (BR)",
    "tr": "Türkçe",
    "hu": "Hungarian",
    "he": "Hebrew",
    "ru": "Russian",
    "kk": "Kazakh",
    "nl": "Dutch"
  }
}

function scrollToName({ name }) {
  try {
    const ele = document.getElementsByName(name)
    if (ele.length > 0) ele[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
  } catch (e) { }
}

async function sendEvent() {
  // Log analytics events (both GA and internal api) that a dish was added.
  try {
    if (window.add_dish_count) {
      window.add_dish_count += 1
    } else {
      window.add_dish_count = 1
    }

    if (window.add_dish_count === 5) {
      window.gtag('event', 'add_dish_5')
    }

    window.gtag('event', 'add_dish');
    window.gtag('event', 'conversion', { 'send_to': 'AW-642020249/7k-sCLzIiYUDEJnnkbIC' });
    if (window.sendEvent) window.sendEvent("add_dish", "")

  } catch (e) {
    console.log(e)
  }
}

function AddEleButton({ addSection }) {
  return <div>
    <div
      id="basic-button"
      className='add-ele clickable'
      onClick={(e) => {
        sendEvent()
        addSection()
      }}
    >
      <AddIcon />
    </div>
  </div>
}

function ExpandableField({ forceShow, expandedField, onNaturalExpand, label }) {
  const [showField, setShowField] = useState(forceShow ? true : false)

  if (showField) {
    return expandedField
  }

  return <div className={"field"}>
    <div className={"field-description"}>
      <span className='clickable' onClick={() => { setShowField(true); if (onNaturalExpand) onNaturalExpand() }}>
        <AddIcon />
        {label}
      </span>
    </div>
  </div>
}

function ListLabels({ labels, labelsJsonPath, additionalPossibleLabels, addEle, removeLabel, updateExampleLabels, title }) {
  return <>
    <div className={"field label-field"}>
      <div className={"field-description"}>{title}</div>
      <ul className={"labels"}> {labels.map((label, i) => {
        updateExampleLabels(label);
        return <li key={`${labelsJsonPath}[${i}]`}>
          <span className="badge">
            <div>{label}</div>
            <ClearIcon className={"clear-icon"} onClick={() => { removeLabel(i, labelsJsonPath) }} />
          </span>
        </li>
      })
      }
        <li>
          <Autocomplete
            onChange={(e, newValue) => {
              if (typeof newValue === 'string') {
                addEle(newValue, labelsJsonPath)
              } else if (newValue && newValue.inputValue) {
                addEle(newValue.inputValue, labelsJsonPath)
              } else if (newValue && newValue.title) {
                addEle(newValue.title, labelsJsonPath)
              }
            }}
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id={`${labelsJsonPath}-${labels.length}`}
            key={`${labelsJsonPath}-${labels.join('')}`}
            onBlur={(e) => {
              const newValue = e.target.value
              if (newValue) {
                addEle(newValue, labelsJsonPath)
              }
            }}
            sx={{ width: 200 }}
            size="small"
            options={additionalPossibleLabels}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.title;
            }}
            filterOptions={(options, params) => {
              const filtered = acfilter(options, params);

              const { inputValue } = params;
              if (inputValue !== '') {
                filtered.push({
                  inputValue,
                  title: <><span><KeyboardReturnIcon sx={{ transform: "scaleX(-1)" }} /></span>&#160;"<b>{inputValue}</b>"</>,
                });
              }

              return filtered;
            }}
            renderOption={(props, option) => <li {...props}>{option.title}</li>}
            renderInput={(params) => <TextField {...params} label="Tag" />}
          />
        </li>
      </ul>
    </div>
  </>
};

function filterIcons(searchTerm) {
  return allIcons.filter(icon => {
    return icon.includes(searchTerm.toLowerCase())
  })
}

function AddIconToEle({ addEleCallback, defaultOpen }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [iconsList, setIconsList] = useState(allIcons)

  const openOpper = (event) => {
    setAnchorEl(anchorEl ? anchorEl : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  useEffect(() => {
    if (defaultOpen) {
      const ele = document.getElementById("txt-icon-picker")
      setAnchorEl(ele)
    }
  }, [])

  return <div>
    <TextField id="txt-icon-picker" size="small" placeholder='Search' autoFocus={defaultOpen} onClick={openOpper} onChange={(e) => setIconsList(filterIcons(e.target.value))} />
    <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: "1200" }}>
      <div className='icon-picker'>
        {iconsList.map((icon, i) => <div className="clickable" onClick={() => addEleCallback(icon)}>
          <img src={`${config.url.PUBLIC}/icons/${icon}`} className='menu-icon' width="24" height="24" />
        </div>)}
      </div>
      <div onClick={() => setAnchorEl(null)} style={{ textAlign: "center", cursor: "pointer", marginTop: "1rem" }}><ClearIcon /></div>
    </Popper>
  </div>
}

function ListIcons({ icons, iconsJsonPath, addEle, removeIcon, title }) {
  return <>
    <div className={"field label-field"}>
      <div className={"field-description"}>{title}</div>
      <ul className={"labels"}> {icons.map((icon, i) => {
        return <li key={`${iconsJsonPath}[${i}]`}>
          <span className="badge">
            <img src={`${config.url.PUBLIC}/icons/${icon}`} className='menu-icon' width="24" height="24" />
            <ClearIcon className={"clear-icon"} onClick={() => { removeIcon(i, iconsJsonPath) }} />
          </span>
        </li>
      })
      }
        <li style={{ height: "2.5rem", display: "inline-flex", alignItems: "center" }}>
          <AddIconToEle addEleCallback={(newValue) => addEle(newValue, iconsJsonPath)} defaultOpen={icons.length === 0} />
        </li>
      </ul>
    </div>
  </>
};

const currencies = ["$", "£", "€", "AED", "AFN", "AUD", "BAM", "BBD", "BGN", "BSD", "BZD", "CAD", "CHF", "CNY", "CZK", "DKK", "DOP", "EGP", "EUR", "FJD", "FKP", "GBP", "HKD", "HNL", "HRK", "IDR", "IRR", "ISK", "JEP", "JPY", "KGS", "KHR", "KRW", "KYD", "LAK", "LBP", "LKR", "MNT", "NIO", "NPR", "NZD", "OMR", "PAB", "PEN", "PHP", "PKR", "PLN", "QAR", "RON", "RSD", "SAR", "SBD", "SGD", "SHP", "THB", "TRY", "UAH", "UZS", "VEF", "VND", "ZWD"]

function ListPrices({ prices, pricesJsonPath, removePrice, addPrice, dropDownChangeProps, defaultPriceFormat, changePriceFormat, defaultPriceWithDecimal, changePriceWithDecimal, onChangeProps }) {
  const [defaultCurrency, setCurrency] = useState(Cookies.get("currency"))
  const [priceFormat, setPriceFormat] = useState(defaultPriceFormat)
  const [priceWithDecimal, setPriceWithDecimal] = useState(defaultPriceWithDecimal)
  const [editPriceFormat, setEditPriceFormat] = useState(false)
  const [firstRender, setFirstRender] = useState(true)
  const { t } = useTranslation('app');

  useEffect(() => { setFirstRender(false) }, [])

  useEffect(() => {
    if (!firstRender) changePriceFormat(priceFormat)
  }, [priceFormat])

  useEffect(() => {
    if (!firstRender) changePriceWithDecimal(priceWithDecimal)
  }, [priceWithDecimal])

  return <div className={"field price-field"}>
    <div className={"field-description"}>{t('edit.prices.title')}</div>
    {prices.map((p, i) => (
      <span key={`${pricesJsonPath}[${i}]`}>
        <Autocomplete
          freeSolo
          id={`${pricesJsonPath}[${i}].prefix`}
          value={p.prefix}
          size="small"
          className='desc'
          options={[]}
          onInputChange={(e, newValue) => dropDownChangeProps(newValue, `${pricesJsonPath}[${i}].prefix`, true)}
          renderInput={(params) => <TextField {...params} label={t('edit.prices.prefix')} />  /**placeholder={t('edit.prices.prefix')} */}
        />
        <div className='currency'>
          <Autocomplete
            freeSolo
            id={`${pricesJsonPath}[${i}].c`}
            value={p.c}
            size="small"
            options={currencies}
            onInputChange={(e, newValue) => {
              setDefaultCurrency(newValue)
              setCurrency(newValue)
              dropDownChangeProps(newValue, `${pricesJsonPath}[${i}].c`, true)
            }}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.title;
            }}
            filterOptions={(options, params) => {
              const filtered = acfilter(options, params);

              const { inputValue } = params;

              if (inputValue !== '') {
                filtered.push({
                  inputValue,
                  title: <><b>{inputValue}</b></>,
                });
              }

              return filtered;
            }}
            renderOption={(props, option) => <li {...props}>{option.title || option}</li>}
            renderInput={(params) => <TextField {...params} label={t('edit.prices.c')} />}
          />
          <TextField
            defaultValue={p.amount || ""}
            size="small"
            label={t('edit.prices.price')}
            {...onChangeProps(`${pricesJsonPath}[${i}].amount`)}
            autoFocus={true}
            inputProps={{ type: "number", inputMode: 'numeric', pattern: '[0-9\.,]*' }} />
          {i === (prices.length - 1) && <div className='format-selector' onClick={() => setEditPriceFormat(true)}>Format</div>}
        </div>
        <ClearIcon className={"clear-icon"} onClick={() => { removePrice(i, pricesJsonPath) }} />
      </span>
    ))}
    {editPriceFormat && <div>
      <div>
        <div className={"price-format-select" + (priceFormat === '' ? " selected" : " clickable")} onClick={() => setPriceFormat('')}>1000.00 {priceFormat === '' && <CheckIcon />}</div>
        <div className={"price-format-select" + (priceFormat === 'us' ? " selected" : " clickable")} onClick={() => setPriceFormat('us')}>1,000.00{priceFormat === 'us' && <CheckIcon />}</div>
        <div className={"price-format-select" + (priceFormat === 'fr' ? " selected" : " clickable")} onClick={() => setPriceFormat('fr')}>1 000,00 {priceFormat === 'fr' && <CheckIcon />}</div>
      </div>
      <div>
        <div className={"price-format-select" + (!priceWithDecimal ? " selected" : " clickable")} onClick={() => setPriceWithDecimal(false)}>5 → 5 {!priceWithDecimal && <CheckIcon />}</div>
        <div className={"price-format-select" + (priceWithDecimal ? " selected" : " clickable")} onClick={() => setPriceWithDecimal(true)}>5 → 5.00{priceWithDecimal && <CheckIcon />}</div>
      </div>
    </div>}
    <div className="add-ele" onClick={() => {
      addPrice({ "prefix": "", "amount": null, "c": defaultCurrency || "" }, pricesJsonPath)
    }}><AddIcon /></div>
  </div>
}

function ListPricesAdvanced({ prices, pricesJsonPath, removePrice, addPrice, dropDownChangeProps, onChangeProps, exchangeRate }) {
  const [defaultCurrency, setCurrency] = useState(Cookies.get("currency"))
  const [_, setRefresh] = useState(Math.random())
  const { t } = useTranslation('app');

  return <div className={"field price-field"}>
    <div className={"field-description"}>{t('edit.prices.title')}</div>
    {prices.map((p, i) => (
      <div key={`${pricesJsonPath}-${i}-${prices.length}`} style={{ marginBottom: "1rem" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            size="small"
            id={`${pricesJsonPath}[${i}].prefix`}
            label={t('edit.prices.prefix')}
            value={p.prefix}
            fullWidth
            onChange={(e, newValue) => dropDownChangeProps(newValue, `${pricesJsonPath}[${i}].prefix`, true)}
          />
          <ClearIcon className={"clear-icon"} style={{ margin: "0 0.5rem" }} onClick={() => { removePrice(i, pricesJsonPath) }} />
        </div>
        <div style={{ display: "flex", marginTop: "1rem" }}>
          <TextField
            defaultValue={p.bp || ""}
            size="small"
            label={"USD"}
            onChange={e => {
              p.bp = e.target.value
              p.amount = Math.round(e.target.value * exchangeRate * 100) / 100
              setRefresh(Math.random())
            }}
            autoFocus={true}
            inputProps={{ type: "number", inputMode: 'numeric', pattern: '[0-9\.,]*' }} />
          <div style={{
            display: "flex",
            margin: "0 0.5rem",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            flexGrow: "1"
          }}>
            <span>{exchangeRate}</span>
            <span><EastIcon /></span>
          </div>
          <TextField
            key={p.amount}
            defaultValue={p.amount || ""}
            size="small"
            label={t('edit.prices.price')}
            {...onChangeProps(`${pricesJsonPath}[${i}].amount`)}
            sx={{ marginRight: "0.5rem" }}
            inputProps={{ type: "number", inputMode: 'numeric', pattern: '[0-9\.,]*' }} />
          <Autocomplete
            freeSolo
            id={`${pricesJsonPath}[${i}].c`}
            value={p.c}
            size="small"
            options={currencies}
            onInputChange={(e, newValue) => {
              setDefaultCurrency(newValue)
              setCurrency(newValue)
              dropDownChangeProps(newValue, `${pricesJsonPath}[${i}].c`, true)
            }}
            renderInput={(params) => <TextField {...params} label={t('edit.prices.c')} />}
          />
        </div>
      </div>
    ))}
    <div className="add-ele" onClick={() => { addPrice({ "prefix": "", "amount": null, "c": defaultCurrency || "" }, pricesJsonPath) }}><AddIcon /></div>
  </div>
}

function setDefaultCurrency(currency) {
  const current = new Date();
  const nextYear = new Date();
  nextYear.setFullYear(current.getFullYear() + 1);
  Cookies.set("currency", currency, { domain: config.cookies.domain, expires: nextYear })
}

function ListCustomizations({ customizations, customizationsJsonPath, setCustomizations, onChangeProps }) {
  const { t } = useTranslation('app');

  return <div className={"field price-field customization-field"}>
    <div className={"field-description"}>{t('edit.customizations.title')}</div>
    {customizations.map((customization, c) => (
      <span key={`${customizationsJsonPath}[${c}]`}>
        {customization.options.map((option, co) => {
          let optionJsonPath = `${customizationsJsonPath}[${c}].options`
          return <>
            <span className='customization-value'>
              <TextField
                style={{ width: "11rem" }}
                defaultValue={option.label || ""}
                size="small"
                placeholder={`${t('edit.customizations.choice')} ${co + 1}`}
                label={co === 0 ? t('edit.customizations.default-choice') : null}
                {...onChangeProps(`${optionJsonPath}[${co}].label`)}
                autoFocus={true} />
              {co >= 2 && <span style={{ position: "absolute", right: "0", top: "0.5rem" }}><ClearIcon className={"clear-icon"} onClick={() => {
                customization.options.splice(co, 1)
                setCustomizations(customizations)
              }} /></span>}
            </ span>
            <span>&#160;{t('general.or')}&#160;</span>
          </>
        })}
        <div className='field-description' style={{ marginBottom: "unset", marginRight: "0.5rem" }}><span className='clickable' onClick={() => {
          customization.options.push({ label: "" })
          setCustomizations(customizations)
        }}>{t('edit.customizations.add-choice')}</span></div>
        <ClearIcon className={"clear-icon"} onClick={() => {
          customizations.splice(c, 1)
          setCustomizations(customizations)
        }} />
      </span>
    ))}
    <div className="add-ele" onClick={() => {
      customizations.push({ "label": "", "options": [{ "label": "" }, { "label": "" }] })
      setCustomizations(customizations)
    }}><AddIcon /></div>
  </div>
}

function ListOptions({ options, optionsLabel, optionsJsonPath, dropDownChangeProps, onChangeProps, removeOption, addOption }) {
  const [defaultCurrency, setCurrency] = useState(Cookies.get("currency"))
  const [editLabel, setEditLabel] = useState(false)
  const { t } = useTranslation('app');

  return <div className={"field price-field"}>
    <div className={"field-description"}>
      {editLabel ? <>
        <input defaultValue={optionsLabel} placeholder="Enter custom option label" {...onChangeProps(`${optionsJsonPath}-label`)} />
        <CheckIcon onClick={() => setEditLabel(false)} />
      </>
        : <>
          {optionsLabel || t('edit.options.title')}
          <div className='edit-hide'><EditIcon onClick={() => setEditLabel(true)} /></div>
        </>}
    </div>
    {options.map((option, i) => (
      <span key={`${optionsJsonPath}[${i}]`}>
        <Autocomplete
          freeSolo
          id={`${optionsJsonPath}[${i}].prefix`}
          value={option.prefix}
          size="small"
          className='desc'
          options={[]} // TODO ["mayonnaise", "garlic dip", "no sauce"]
          onInputChange={(e, newValue) => dropDownChangeProps(newValue, `${optionsJsonPath}[${i}].prefix`, true)}
          renderInput={(params) => <TextField {...params} label={t('edit.prices.prefix')} />}
        />
        <div className='currency'>
          <Autocomplete
            freeSolo
            id={`${optionsJsonPath}[${i}].c`}
            value={option.c}
            size="small"
            options={currencies}
            onInputChange={(e, newValue) => {
              setDefaultCurrency(newValue)
              setCurrency(newValue)
              dropDownChangeProps(newValue, `${optionsJsonPath}[${i}].c`, true)
            }}
            renderInput={(params) => <TextField {...params} label={t('edit.prices.c')} />}
          />
          <TextField
            defaultValue={option.amount}
            size="small"
            label={t('edit.prices.price')}
            {...onChangeProps(`${optionsJsonPath}[${i}].amount`)}
            autoFocus={true}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9\.,]*' }} />
        </div>
        <ClearIcon className={"clear-icon"} onClick={() => { removeOption(i, optionsJsonPath) }} />
      </span>
    ))}
    <div className="add-ele" onClick={() => { addOption({ "prefix": "", "amount": null, "c": defaultCurrency || "" }, optionsJsonPath) }}><AddIcon /></div>
  </div>
}

function CreateMenu({ metadata, createMenu }) {
  const { t } = useTranslation('app');
  const menuNames = Object.entries(metadata.menus).map(keyValue => keyValue[1].label);
  var defaultNewMenuName = "New menu name"
  for (var i = menuNames.length + 1; i > 0; i--) {
    if (menuNames.includes(`Menu ${i}`)) continue
    defaultNewMenuName = `Menu ${i}`
    break
  }

  const [newMenuLanguage, setNewMenuLanguage] = useState({ "name": t("edit.english"), "code": "en" })
  const [newMenuName, setNewMenuName] = useState(defaultNewMenuName)
  const [selectedLangI, setSelectedLangI] = useState(0)

  const menuId = (menuNames.length === 0) ? "default" : undefined // If no menu, the first one is default menu (to no break setup)
  const availableLanguages = [
    [t("edit.english"), "en"],
    [t("edit.arabic"), "ar"],
    ["Dutch", "nl"],
    [t("edit.french"), "fr"],
    [t("edit.german"), "de"],
    ["Hebrew", "he"],
    ["Hungarian", "hu"],
    ["Indonesian", "in"],
    ["Kazakh", "kk"],
    [t("edit.italian"), "it"],
    [t("edit.mandarinchinese"), "zh"],
    [t("edit.persian"), "fa"],
    [t("edit.spanish"), "es"],
    ["Türkçe", "tr"],
    ["Russian", "ru"],
    ["Português (BR)", "pt"]
  ]

  return <>
    <div className="create-menu">
      <div>
        <div style={{ width: "fit-content", margin: "auto", fontSize: "x-large", fontWeight: "bold" }}>{t('edit.new-menu')}</div>
        <div style={{ margin: "2rem auto" }}>
          <TextField label={t('edit.create-menu.menu-name')} fullWidth defaultValue={newMenuName} onChange={(e) => setNewMenuName(e.target.value)} />
        </div>
        <div style={{ margin: "2rem auto" }}>
          <FormControl className="menu-lang-selector" fullWidth>
            <InputLabel id="menu-lang-label">{t('edit.select-lang')}</InputLabel>
            <Select
              labelId="menu-lang-label"
              id="lang-simple-select"
              value={selectedLangI}
              label={t('edit.select-lang')}
              onChange={(e) => {
                const selectedLang = e.target.value
                setSelectedLangI(selectedLang)
                setNewMenuLanguage({ "name": availableLanguages[selectedLang][0], "code": availableLanguages[selectedLang][1] })
              }}
            >
              {
                availableLanguages.map((l, i) => <MenuItem key={uuidv4()} value={i}>{l[0]}</MenuItem>)
              }
            </Select>
          </FormControl>
        </div>
        <div>
          <div className="tas-button" style={{ width: "100%" }} onClick={() => createMenu(newMenuName, newMenuLanguage, [], menuId)}>{t('general.create')}</div> {/*sampleMenus[newMenuLanguage["code"]]*/}
        </div>
      </div>
    </div>
  </>
}

function EditMenuParams({ metadata, markAsActive, selectedMenu, cloneMenu, removeMenu, closeMenuParam, updateMetadata }) {
  const menuNames = Object.entries(metadata.menus).map(keyValue => keyValue[1].label);
  const { t } = useTranslation('app');
  var newName = metadata.menus[selectedMenu].label;
  const [useInProd, setUseInProd] = useState(markAsActive)

  return <>
    <div style={{ display: "flex", flexDirection: "column" }} className='create-menu'>
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", left: "0.5rem", top: "0.5rem", backgroundColor: "white" }} onClick={() => cloneMenu(selectedMenu)}>
          <div className="tas-button outline" style={{ display: "flex" }}><span className="lg-screen" style={{ marginRight: "0.5rem" }}>Clone</span><span><FileCopyIcon /></span></div>
        </div>
        <div className="tas-button danger borderless"
          onClick={() => removeMenu(selectedMenu)}
          style={{ position: "absolute", right: "0.5rem", top: "0.5rem" }}>
          <span><DeleteIcon /></span>
        </div>
        <div style={{ width: "fit-content", margin: "auto", fontSize: "x-large", fontWeight: "bold" }}>{metadata.menus[selectedMenu].label}</div>
        <div style={{ margin: "1rem auto" }}>
          <input defaultValue={metadata.menus[selectedMenu].label} onChange={(e) => newName = e.target.value} />
        </div>
        {(menuNames.length > 1) && <div>
          <Checkbox
            style={{ color: '#5D935C' }}
            checked={useInProd}
            onChange={(e) => { setUseInProd(e.currentTarget.checked) }} />
          <span>{t('edit.create-menu.set-active.0')}</span>
          <span style={{ marginLeft: "0.5rem" }}>
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24" /></g><g><g><path d="M3,11h8V3H3V11z M5,5h4v4H5V5z" /><path d="M3,21h8v-8H3V21z M5,15h4v4H5V15z" /><path d="M13,3v8h8V3H13z M19,9h-4V5h4V9z" /><rect height="2" width="2" x="19" y="19" /><rect height="2" width="2" x="13" y="13" /><rect height="2" width="2" x="15" y="15" /><rect height="2" width="2" x="13" y="17" /><rect height="2" width="2" x="15" y="19" /><rect height="2" width="2" x="17" y="17" /><rect height="2" width="2" x="17" y="13" /><rect height="2" width="2" x="19" y="15" /></g></g></svg>
          </span>
        </div>}
        <div style={{ margin: "2rem auto auto", display: "flex", width: "fit-content" }}>
          <div className="tas-button outline" style={{ margin: "auto 0.5rem auto auto" }} onClick={closeMenuParam}><span>{t('edit.cancel')}</span></div>
          <div className="tas-button" style={{ margin: "auto auto auto 0.5rem" }} onClick={() => {
            metadata.menus[selectedMenu].label = newName;
            if (useInProd) {
              metadata.activeMenu = selectedMenu
            } else {
              if (metadata.activeMenu === selectedMenu || metadata.activeMenu === "") {
                // Deactivate only if the menu was previously active
                // Set to first menu in list
                metadata.activeMenu = Object.keys(metadata.menus)[0]
              }
            }
            updateMetadata(metadata).then(closeMenuParam)
          }}>{t('edit.save')}</div>
        </div>
      </div>
    </div>
  </>
}

function TranslationInterface({ addLanguage, metadata, selectedMenu, cancel }) {
  const { t } = useTranslation('app');
  const [modaluuid, setModaluuid] = useState(null)

  const availableLanguages = [
    [t("edit.english"), "en"],
    [t("edit.arabic"), "ar"],
    ["Dutch", "nl"],
    [t("edit.french"), "fr"],
    [t("edit.german"), "de"],
    ["Hebrew", "he"],
    ["Hungarian", "hu"],
    ["Indonesian", "in"],
    ["Kazakh", "kk"],
    [t("edit.italian"), "it"],
    [t("edit.mandarinchinese"), "zh"],
    [t("edit.persian"), "fa"],
    [t("edit.spanish"), "es"],
    ["Türkçe", "tr"],
    ["Russian", "ru"],
    ["Português (BR)", "pt"],
    [t("edit.other"), "other"],
  ].filter(lang => _.findIndex(metadata.menus[selectedMenu].supportedLanguages, (l) => { return l["code"] === lang[1] }, 0) === -1)

  const [selectedLangI, setSelectedLangI] = useState(0)

  const menuName = metadata.menus[selectedMenu].label

  return <div className="translation-menu">
    <PricingModal defaultShowModal={modaluuid} key={modaluuid} />
    <div className="translation-details">
      <div>Translate menu <b>{menuName}</b> to</div>
      <FormControl>
        <InputLabel id="lang-select-label">Language</InputLabel>
        <Select
          labelId="lang-select-label"
          id="lang-simple-select"
          value={selectedLangI}
          size="small"
          label="Language"
          onChange={(e) => setSelectedLangI(e.target.value)}
        >
          {
            availableLanguages.map((l, i) => <MenuItem key={uuidv4()} value={i}>{l[0]}</MenuItem>)
          }
        </Select>
      </FormControl>
    </div>
    <div className="translation-options">
      <div className="translation-option">
        <div
          className="tas-button outline"
          style={(selectedLangI === availableLanguages.length - 1) ? { pointerEvents: "none" } : {}}
          onClick={() => {
            if (selectedLangI === availableLanguages.length - 1) return
            addLanguage({ "name": availableLanguages[selectedLangI][0], "code": availableLanguages[selectedLangI][1] })
          }}><span>{t("edit.translation-request.manual-translation")}</span></div>
        {selectedLangI === availableLanguages.length - 1 && <div className="desc" style={{ color: "red" }}>{t("edit.translation-request.only-request")}</div>}
      </div>
      <div className="translation-option">
        <div
          onClick={() => translationCheckout({ menu: selectedMenu, language: availableLanguages[selectedLangI][0] })}
          className="tas-button outline purple"><span>{t("edit.translation-request.request")} ($5)</span></div>
        <div className="desc">
          <div>{t("edit.translation-request.price.0")}<span style={{ color: "#0066CC", cursor: "pointer" }} onClick={() => setModaluuid(uuidv4())}>{t("edit.translation-request.price.1")}</span></div>
        </div>
      </div>
      <div className="translation-option">
        <div className="tas-button outline danger borderless" onClick={cancel}><span>{t('edit.cancel')}</span></div>
      </div>
    </div>
  </div>
}

function EleMenu({ id, options }) {
  const [open, setOpen] = useState(false)
  const [anchor, setAnchor] = useState(null)

  if (!options) options = []

  return <div className="item-menu clickable">
    <EditIcon onClick={(e) => {
      setOpen(true)
      setAnchor(e.currentTarget)
    }} />
    <Popover
      id={id}
      open={open}
      anchorEl={anchor}
      onClose={() => {
        setOpen(false)
        setAnchor(null)
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className="item-menu-options">
        {options.map(option => option && <div
          key={`${id}-${option.label}`}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            option.onClick()
            setOpen(false)
          }}>{option.icon} {option.label}
        </div>)}
      </div>
    </Popover>
  </div>
}

function ConflictWindow({ keepAllChanges, keepLocalChanges, cancel, t }) {
  return <div className="fullscreen light"> {/** TODO: check how it looks like */}
    <div className="content">
      <div className="tas-title">{t('edit.merge.title')}</div>
      <div>{t('edit.merge.desc')}</div>
      <br />
      <div>
        <div>{t('edit.merge.exp.title')}:</div>
        <ul style={{ marginBottom: "0" }}>
          <li style={{ marginTop: "0.5rem" }}><em>{t('edit.merge.exp.options.0.0')}</em>: {t('edit.merge.exp.options.0.1')}</li>
          <li style={{ marginTop: "0.5rem" }}><em>{t('edit.merge.exp.options.1.0')}</em>: {t('edit.merge.exp.options.1.1')}</li>
        </ul>
      </div>
      <br />
      <div className="buttons">
        <div className='tas-button' onClick={keepAllChanges}>{t('edit.merge.buttons.0')}</div>
        <div className='tas-button outline' onClick={keepLocalChanges}><span>{t('edit.merge.buttons.1')}</span></div>
        <div><a type="button" onClick={cancel}>{t('edit.merge.buttons.2')}</a></div>
      </div>
    </div>
  </div>
}

class EditView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuData: undefined,
      menuDataOriginal: undefined,
      newModifications: false,
      inFocus: "",
      lang: "en",
      metadata: props.metadata,
      selectedMenu: null,
      selectedLanguage: null,
      editMenuParams: false,
      showConflictWindow: false,
      selectedSection: null,
      setup_state: props.session.solutions_setup.includes('digital-menu') ? "completed" : "pending"
    }

    this.successCallbackBind = successCallbackMultiImages.bind(this);
    this.saveImages = saveImages.bind(this);
    this.onChangeProps = this.onChangeProps.bind(this)
    this.removeEle = this.removeEle.bind(this)
    this.addEle = this.addEle.bind(this)
    this.updateExampleLabels = this.updateExampleLabels.bind(this)
    this.dropDownChangeProps = this.dropDownChangeProps.bind(this)
    this.createMenu = this.createMenu.bind(this)
    this.cloneMenu = this.cloneMenu.bind(this)
    this.removeMenu = this.removeMenu.bind(this)
    this.addLanguage = this.addLanguage.bind(this)
    this.moveUp = this.moveUp.bind(this)
    this.moveDown = this.moveDown.bind(this)
    this.remainingLabels = this.remainingLabels.bind(this)
    this.keepLocalChanges = this.keepLocalChanges.bind(this)
    this.keepAllChanges = this.keepAllChanges.bind(this)
    this.changeLanguage = this.changeLanguage.bind(this)
    this.changeMenu = this.changeMenu.bind(this)
    this.saveMenu = this.saveMenu.bind(this)
    this.setEleValue = this.setEleValue.bind(this)
    this.listSections = this.ListSections.bind(this)
    this.section = this.Section.bind(this)
    this.subsection = this.Subsection.bind(this)
    this.item = this.Item.bind(this)
    this.eleMenu = this.EleMenu.bind(this)
    this.changePrices = this.changePrices.bind(this)
    this.startTour = this.startTour.bind(this)


    const { t } = this.props;
    this.codeToLang = codeToLang({ t })

    this.exampleLabels = {
      "en": [{ "title": "dairy free" }, { "title": "chicken" }, { "title": "vegan" }, { "title": "vegetarian" }],
      "kk": [{ "title": "сүтті аз" }, { "title": "домалаксыз" }, { "title": "веган" }, { "title": "батырмалық" }],
      "in": [{ "title": "dairy free" }, { "title": "chicken" }, { "title": "vegan" }, { "title": "vegetarian" }],
      "fr": [{ "title": "végétarien" }, { "title": "viande" }, { "title": "poulet" }],
      "es": [{ "title": "sin lácteos" }, { "title": "pollo" }, { "title": "vegano" }],
      "de": [{ "title": "vegetarisch" }, { "title": "Fleisch" }, { "title": "Hähnchen" }],
      "it": [{ "title": "vegetariano" }, { "title": "carne" }, { "title": "pollo" }],
      "fa": [{ "title": "vegetarian" }, { "title": "meat" }, { "title": "chicken" }],
      "ar": [{ "title": "vegetarian" }, { "title": "meat" }, { "title": "chicken" }],
      "ru": [{ "title": "vegetarian" }, { "title": "meat" }, { "title": "chicken" }],
      "zh": [{ "title": "vegetarian" }, { "title": "meat" }, { "title": "chicken" }],
      "ml": [{ "title": "vegetarian" }, { "title": "meat" }, { "title": "chicken" }],
      "lo": [{ "title": "vegetarian" }, { "title": "meat" }, { "title": "chicken" }],
      "hu": [{ "title": "vegetarian" }, { "title": "meat" }, { "title": "chicken" }],
      "he": [{ "title": "vegetarian" }, { "title": "meat" }, { "title": "chicken" }],
      "tr": [{ "title": "vejetaryen" }, { "title": "et" }, { "title": "tavuk" }],
      "nl": [{ "title": "vegetariër" }, { "title": "kip" }],
      "pt": [{ "title": "livre de laticínios" }, { "title": "galinha" }, { "title": "vegan" }]
    }
  }

  componentDidMount() {
    var selectedMenu = this.state.metadata.activeMenu

    if ((!selectedMenu || !Object.keys(this.state.metadata.menus).includes(selectedMenu)) && Object.keys(this.state.metadata.menus).length > 0) {
      // There is at least one menu
      selectedMenu = Object.keys(this.state.metadata.menus)[0];
    }

    if (selectedMenu) {
      this.setState({ selectedMenu: selectedMenu })

      if (this.state.metadata.menus[selectedMenu].supportedLanguages.length > 0) {
        // This menu has at least one supported language
        this.setState({ selectedLanguage: this.state.metadata.menus[selectedMenu].supportedLanguages[0]["code"] })

        loadMenu(this.props.restaurantId, selectedMenu, this.state.metadata.menus[selectedMenu].supportedLanguages[0]["code"], this.props.renewCredentials)
          .then(data => { // TODO: handle menu not found is not valid json
            this.setState({
              menuData: data.menuData,
              selectedSection: data.menuData.length > 0 ? data.menuData[0].id : null,
              menuDataOriginal: JSON.parse(JSON.stringify(data.menuData)),
              menuOVersion: data.originalVersion
            })
          })
      }
    }
  }

  keepLocalChanges = () => {
    this.saveMenu('overwrite')
    this.setState({ showConflictWindow: false })  // TODO: use a then method
  }

  mergeItem(localItem, serverItem, originalItem) {
    const allKeys = Object.keys(localItem).concat(Object.keys(serverItem))
    const uniqueKeys = Array.from(new Set(allKeys))  // We do this because new keys may have been entered on server (e.g. hidden is not a permanent field)

    uniqueKeys.forEach(key => {
      if (key === "price") {
        if (JSON.stringify(localItem.price) === JSON.stringify(originalItem.price)) localItem.price = serverItem.price  // Better to compare for === incase new keys are added
      } else if (key === "options") {
        if (JSON.stringify(localItem.options) === JSON.stringify(originalItem.options)) localItem.options = serverItem.options
      } else if (key === 'labels') {
        if (JSON.stringify(localItem.labels) === JSON.stringify(originalItem.labels)) localItem.labels = serverItem.labels
      } else {
        if (localItem[key] === originalItem[key]) localItem[key] = serverItem[key]
      }
    })
  }

  mergeSection(localSection, serverSection, originalSection) {
    Object.keys(localSection).forEach(key => {
      if (key === 'items') {
        //TODO refactor
        const originalItemsIds = originalSection.items.map(item => item.id)
        const serverItemsIds = serverSection.items.map(item => item.id)

        localSection.items.forEach(localItem => {
          if (!serverItemsIds.includes(localItem.id)) return // This is a new section or was deleted on server (in which case we put it back)
          if (!originalItemsIds.includes(localItem.id)) return // TODO : should not be necessary

          const serverItem = serverSection.items[_.findIndex(serverSection.items, item => item.id === localItem.id)]
          const originalItem = originalSection.items[_.findIndex(originalSection.items, item => item.id === localItem.id)]

          this.mergeItem(localItem, serverItem, originalItem)
        })

        // There are new sections on server that we need to add
        const itemsToAddIds = serverItemsIds.filter(id => !originalItemsIds.includes(id))
        itemsToAddIds.forEach(itemId => {
          const item = serverSection.items[_.findIndex(serverSection.items, item => item.id === itemId)]
          localSection.items.push(item)
        })

      } else if (key === "subsections") {
        const originalSubsectionIds = originalSection.subsections.map(subsection => subsection.id)
        const serverSubsectionIds = serverSection.subsections.map(subsection => subsection.id)

        localSection.subsections.forEach(localSubsection => {
          if (!serverSubsectionIds.includes(localSubsection.id)) return // This is a new section or was deleted on server (in which case we put it back)
          if (!originalSubsectionIds.includes(localSubsection.id)) return // TODO : should not be necessary

          const serverSubsection = serverSection.subsections[_.findIndex(serverSection.subsections, subsection => subsection.id === localSubsection.id)]
          const originalSubsection = originalSection.subsections[_.findIndex(originalSection.subsections, subsection => subsection.id === localSubsection.id)]

          this.mergeSection(localSubsection, serverSubsection, originalSubsection)
        });

        // There are new subsections on server that we need to add
        const subsectionToAddIds = serverSubsectionIds.filter(id => !originalSubsectionIds.includes(id))
        subsectionToAddIds.forEach(subsectionId => {
          const subsection = serverSection.subsections[_.findIndex(serverSection.subsections, subsection => subsection.id === subsectionId)]
          localSection.subsections.push(subsection)
        })
      } else {
        // All keys other then items and subsections are not arrays
        if (localSection[key] === originalSection[key]) localSection[key] = serverSection[key]
      }
    })
  }

  keepAllChanges = () => {
    const originalSectionIds = this.state.menuDataOriginal.map(section => section.id)
    const serverSectionIds = this.state.serverMenuData.map(section => section.id)

    this.state.menuData.forEach(localSection => {
      if (!serverSectionIds.includes(localSection.id)) return // This is a new section or was deleted on server (in which case we put it back)
      if (!originalSectionIds.includes(localSection.id)) return // TODO : should not be necessary

      const serverSection = this.state.serverMenuData[_.findIndex(this.state.serverMenuData, section => section.id === localSection.id)]
      const originalSection = this.state.menuDataOriginal[_.findIndex(this.state.menuDataOriginal, section => section.id === localSection.id)]

      this.mergeSection(localSection, serverSection, originalSection)
    });

    // There are new sections on server that we need to add
    const sectionToAddIds = serverSectionIds.filter(id => !originalSectionIds.includes(id))
    sectionToAddIds.forEach(sectionId => {
      const section = this.state.serverMenuData[_.findIndex(this.state.serverMenuData, section => section.id === sectionId)]
      this.state.menuData.push(section)
    })

    this.saveMenu("overwrite")
  }

  saveMenu = async (method) => {
    fetch(`${config.url.API}/menu/${this.props.restaurantId}/${this.state.selectedMenu}/${this.state.selectedLanguage}`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include",
      body: JSON.stringify({
        menu: JSON.stringify(this.state.menuData),
        originalVersion: (this.state.menuOVersion) ? this.state.menuOVersion : 0,
        method: method
      })
    }).then(resp => {
      if (resp.status === 403) {
        this.props.renewCredentials()
      } else if (resp.status === 400) {
        return // Raise error message
      } else if (resp.status === 409) {
        resp.json().then(json => {
          this.setState({
            serverMenuData: json.serverMenuData,
            showConflictWindow: true,
          })
        })
        return // TODO: Handle conflict
      } else {
        // TODO : Check if success
        resp.json().then((json) => {
          this.setState({ menuDataOriginal: JSON.parse(JSON.stringify(this.state.menuData)), newModifications: false, menuOVersion: json.newVersion, showConflictWindow: false }); //inFocus: null, 
          // this.setState({ inFocus: null, menuDataOriginal: JSON.parse(JSON.stringify(this.state.menuData)), newModifications: false, menuOVersion: json.newVersion, serverMenuData: null, showConflictWindow: false });
          this.props.onSavedUpdated(true); // Why not false?
        })
      }
    })
  }

  removeLanguage = async () => {
    const { t } = this.props;
    if (!window.confirm(t('edit.confirmdelete'))) {
      return;
    }
    _.remove(this.state.metadata.menus[this.state.selectedMenu].supportedLanguages, (l) => { return l["code"] === this.state.selectedLanguage })
    this.state.menuData = []
    this.props.updateMetadata(this.state.metadata).then(() => {
      this.saveMenu("overwrite")

      if (this.state.metadata.menus[this.state.selectedMenu].supportedLanguages.length === 0) {
        this.setState({ selectedLanguage: null });
      } else {
        // TODO: put in method Switch language
        loadMenu(this.props.restaurantId, this.state.selectedMenu, this.state.metadata.menus[this.state.selectedMenu].supportedLanguages[0]["code"], this.props.renewCredentials)
          .then(data => { // TODO: handle menu not found is not valid json
            this.setState({ menuData: data.menuData, menuDataOriginal: JSON.parse(JSON.stringify(data.menuData)), menuOVersion: data.originalVersion, selectedLanguage: this.state.metadata.menus[this.state.selectedMenu].supportedLanguages[0]["code"] })
          })
      }
    })
  }

  removeMenu = async (menuKey) => {
    const { t } = this.props;
    if (!window.confirm(t('edit.confirmdelete'))) {
      return;
    }

    var newMetadata = JSON.parse(JSON.stringify(this.state.metadata))

    console.log("this.state.metadata", this.state.metadata)

    delete newMetadata.menus[menuKey];

    // Update the active menu
    const menuKeys = Object.keys(newMetadata.menus)

    if (newMetadata.activeMenu === menuKey) {
      if (menuKeys.length > 0) {
        newMetadata.activeMenu = menuKeys[0]
      } else {
        newMetadata.activeMenu = ""
      }
    }
    this.props.updateMetadata(newMetadata);

    if (menuKeys.length === 0) {
      this.setState({
        selectedMenu: null,
        menuData: [],
        menuDataOriginal: [],
        editMenuParams: false,
        metadata: newMetadata
      });
    } else {
      const newMenuKey = Object.keys(newMetadata.menus)[0]
      this.state.selectedMenu = newMenuKey
      if (newMetadata.menus[newMenuKey].supportedLanguages.length > 0) {
        loadMenu(this.props.restaurantId, newMenuKey, newMetadata.menus[newMenuKey].supportedLanguages[0]["code"], this.props.renewCredentials)
          .then(data => { // TODO: handle menu not found is not valid json
            this.setState({ metadata: newMetadata, editMenuParams: false, menuData: data.menuData, menuDataOriginal: JSON.parse(JSON.stringify(data.menuData)), menuOVersion: data.originalVersion, selectedMenu: newMenuKey, selectedLanguage: newMetadata.menus[newMenuKey].supportedLanguages[0]["code"] })
          })
      } else {
        this.setState({ metadata: newMetadata, editMenuParams: false, menuData: [], menuDataOriginal: [], selectedMenu: newMenuKey, selectedLanguage: null })
      }
    }
  }

  createMenu = async (menuName, supportedLanguage, menuData, menuId) => {
    if (menuData === undefined) menuData = []
    if (menuId === undefined) menuId = new Date().getTime().toString(); // We assume that a same restaurant does not create two menu at the same millisecond. Otherwise they will conflict.

    var menuMetadata = {
      "label": menuName,
      "supportedLanguages": [supportedLanguage]
    }

    this.state.metadata.menus[menuId] = menuMetadata

    // Set as prod menu if selected by user or if there are no other menu
    if (Object.keys(this.state.metadata.menus).length === 1) this.state.metadata.activeMenu = menuId

    this.state.selectedMenu = menuId
    this.state.selectedLanguage = supportedLanguage["code"]
    this.state.menuData = menuData
    this.state.menuDataOriginal = []

    this.props.updateMetadata(this.state.metadata).then(() => {
      this.saveMenu("overwrite") // To avoid conflict window after setup and creation of menu with same id
    })
  }

  cloneMenu = async (menuId) => {
    const cloneMenuId = new Date().getTime().toString(); // We assume that a same restaurant does not create two menu at the same millisecond. Otherwise they will conflict.

    fetch(`${config.url.API}/menu/clone/${this.props.restaurantId}/${menuId}/${cloneMenuId}`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include"
    }).then(data => {
      if (data.status === 200) {

        // The menu was cloned in the backend but we still have to update the metadata
        this.state.metadata.menus[cloneMenuId] = {
          "label": this.state.metadata.menus[menuId]["label"] + " Copy",
          "supportedLanguages": this.state.metadata.menus[menuId]["supportedLanguages"]
        }

        this.props.updateMetadata(this.state.metadata).then(() => {
          // Now that the metadata has been updated, load the clone menu
          this.props.onSavedUpdated(true);

          // If theres is at least one language
          if (Object.keys(this.state.metadata.menus[menuId]["supportedLanguages"]).length > 0) {
            const lang = this.state.metadata.menus[menuId]["supportedLanguages"][0]
            loadMenu(this.props.restaurantId, cloneMenuId, lang["code"])
              .then(data => { // TODO: handle menu not found is not valid json
                // TODO make it as a method
                this.setState({ menuData: data.menuData, menuDataOriginal: JSON.parse(JSON.stringify(data.menuData)), menuOVersion: data.originalVersion, selectedLanguage: lang["code"], editMenuParams: false, selectedMenu: cloneMenuId })
              })
          } else {
            // We do not have to load any menu since there is no menu
            this.setState({ menuData: [], menuDataOriginal: [], selectedLanguage: null, editMenuParams: false, selectedMenu: cloneMenuId })
          }
        })
      }
    })
  }

  addLanguage = async (lang, menuData) => {
    if (menuData === undefined) menuData = [] // TODO: copy from other supported language if any

    this.state.metadata.menus[this.state.selectedMenu].supportedLanguages.push({ "code": lang["code"], "name": lang["name"] })
    this.state.selectedLanguage = lang["code"]
    this.state.menuData = menuData
    this.props.updateMetadata(this.state.metadata).then(
      this.saveMenu("overwrite")
    )
  }

  dropDownChangeProps(value, jsonPath, unsaved) {

    this.setState(prev => {
      var newMenuData = [...prev.menuData]
      _.set(newMenuData, jsonPath, value);

      return { menuData: newMenuData }
    })

    if (unsaved && this.props.saved) this.props.onSavedUpdated(false);
  }

  setEleValue(eleJsonPath, value, giveFocusPath, forcedMenuRefresh) {

    this.setState(prev => {
      var newMenuData = [...prev.menuData]
      _.set(newMenuData, eleJsonPath, value);

      return { menuData: newMenuData, inFocus: giveFocusPath, forcedMenuRefresh }
    })

    if (this.props.saved) this.props.onSavedUpdated(false);
  }

  onChangeProps(jsonPath) {
    return {
      "onChange": (e) => {

        this.setState(prev => {
          var newMenuData = [...prev.menuData]
          _.set(newMenuData, jsonPath, e.target.value);

          return { menuData: newMenuData }
        })

        if (this.props.saved) this.props.onSavedUpdated(false);
      }
    }
  }

  addEle(element, elementsJsonPath, giveFocus) {
    var elements = elementsJsonPath === "" ? this.state.menuData : _.get(this.state.menuData, elementsJsonPath) || [];
    elements.push(element);

    if (elementsJsonPath !== "") {
      // We are not inserting a section
      this.setState(prev => {
        var newMenuData = [...prev.menuData]
        _.set(newMenuData, elementsJsonPath, elements);

        return { menuData: newMenuData }
      })
    }

    this.props.onSavedUpdated(false);

    if (giveFocus) {
      const eleJsonPath = `${elementsJsonPath}[${elements.length - 1}]`
      this.setState({ inFocus: `${eleJsonPath}.name`, edit: eleJsonPath });
    }
  }

  removeEle(elementIndex, elementsJsonPath) {
    if (elementsJsonPath === "") {
      // We remove a section 
      this.setState(prev => {
        const newMenuData = prev.menuData.filter((_, i) => i != elementIndex)
        const newSelectedSection = newMenuData.length > 0 ? newMenuData[0].id : null
        return {
          menuData: newMenuData,
          selectedSection: newSelectedSection
        }
      }, () => {
        this.props.onSavedUpdated(false);
      })
    } else {
      this.setState(prev => {
        var newMenuData = [...prev.menuData]
        var elements = _.get(newMenuData, elementsJsonPath);

        elements.splice(elementIndex, 1);
        _.set(newMenuData, elementsJsonPath, elements);

        return {
          menuData: newMenuData
        }
      }, () => {
        this.props.onSavedUpdated(false);
      })
    }
  }

  moveUp(index, parentJsonPath) {
    if (index === 0) {
      const pathParts = parentJsonPath.split('.')

      if (pathParts.length < 2) {
        return console.log("Do not move section.") // We are moving a section.
      }

      if (pathParts[pathParts.length - 1].startsWith("subsections")) {
        return console.log("Do not move subsection.") // We are moving a subsection
      }

      // We are moving an item
      if (pathParts[1].startsWith("subsections")) {
        // The item is in a subsection
        const subsectionIndex = parseInt(pathParts[1].split('[')[1].slice(0, -1))

        if (subsectionIndex === 0) {
          // Move to outside subsection
          this.setState(prev => {
            var newMenuData = [...prev.menuData]

            const newSectionItems = _.get(newMenuData, pathParts[0] + ".items")

            _.set(newMenuData, pathParts[0] + ".items", [...newSectionItems, _.get(newMenuData, parentJsonPath + "[0]")]) // index === 0
            _.set(newMenuData, parentJsonPath, _.get(newMenuData, parentJsonPath).slice(1))

            return { menuData: newMenuData }
          })
        } else {
          // Move to one section up
          const newSubsectionIndex = subsectionIndex - 1
          const newSubsectionPath = pathParts[0] + ".subsections[" + newSubsectionIndex + "]"

          this.setState(prev => {
            var newMenuData = [...prev.menuData]

            const newSubsectionItems = _.get(newMenuData, newSubsectionPath + ".items")

            _.set(newMenuData, newSubsectionPath + ".items", [...newSubsectionItems, _.get(newMenuData, parentJsonPath + "[0]")]) // index === 0
            _.set(newMenuData, parentJsonPath, _.get(newMenuData, parentJsonPath).slice(1))

            return { menuData: newMenuData }
          })
        }
      }
    } else {
      this.setState(prev => {
        var newMenuData = [...prev.menuData]

        // Handle the case where jsonPath is null -> sections don't have parents
        var ele = parentJsonPath ? _.get(newMenuData, parentJsonPath) : newMenuData;

        const indexTo = index - 1;
        [ele[indexTo], ele[index]] = [ele[index], ele[indexTo]]

        _.set(newMenuData, parentJsonPath, ele);

        return { menuData: newMenuData }
      })

      // this.setState({inFocus: null }, () => this.props.onSavedUpdated(false) )
      this.props.onSavedUpdated(false)
    }
  }

  moveDown(index, parentJsonPath) {
    var parentEle = parentJsonPath ? _.get(this.state.menuData, parentJsonPath) : this.state.menuData;

    if (index === parentEle.length - 1) {
      const pathParts = parentJsonPath.split('.')

      if (pathParts.length < 2) {
        return console.log("Do not move section.") // We are moving a section.
      }

      if (pathParts[pathParts.length - 1].startsWith("subsections")) {
        return console.log("Do not move subsection.") // We are moving a subsection
      }

      // We are moving an item

      const nSubsections = _.get(this.state.menuData, pathParts[0] + ".subsections")?.length
      if (!nSubsections) return console.log("No subsection to move to.")

      if (pathParts[1].startsWith("subsections")) {
        // The item is in a subsection
        const subsectionIndex = parseInt(pathParts[1].split('[')[1].slice(0, -1))

        if (subsectionIndex === nSubsections - 1) {
          return console.log("Cannot move a section below.")
        }

        // Move to subsection below
        this.setState(prev => {
          var newMenuData = [...prev.menuData]
          const newSubsectionItemsPath = pathParts[0] + ".subsections[" + (subsectionIndex + 1) + "].items"
          const newSubsectionItems = _.get(newMenuData, newSubsectionItemsPath)

          _.set(newMenuData, newSubsectionItemsPath, [_.get(newMenuData, parentJsonPath + "[" + index + "]"), ...newSubsectionItems])
          _.set(newMenuData, parentJsonPath, _.get(newMenuData, parentJsonPath).slice(0, -1))

          return { menuData: newMenuData }
        })
      } else {
        // Move from outside subsections to first subsection.
        this.setState(prev => {
          var newMenuData = [...prev.menuData]

          const newSubsectionPath = pathParts[0] + ".subsections[0]"
          const newSubsectionItems = _.get(newMenuData, newSubsectionPath + ".items")

          _.set(newMenuData, newSubsectionPath + ".items", [_.get(newMenuData, parentJsonPath + "[" + index + "]"), ...newSubsectionItems])
          _.set(newMenuData, parentJsonPath, _.get(newMenuData, parentJsonPath).slice(0, -1))

          return { menuData: newMenuData }
        })
      }
    } else {
      const indexTo = index + 1;

      this.setState(prev => {
        var newMenuData = [...prev.menuData]
        var ele = parentJsonPath ? _.get(newMenuData, parentJsonPath) : newMenuData;

        // Handle the case where jsonPath is null -> sections don't have parents

        [ele[indexTo], ele[index]] = [ele[index], ele[indexTo]]
        _.set(newMenuData, parentJsonPath, ele);

        return { menuData: newMenuData }
      })

    }
    this.props.onSavedUpdated(false)
  }

  remainingLabels(labels) {
    return this.exampleLabels[this.state.selectedLanguage].filter((label) => !labels.includes(label.title))
  }

  updateExampleLabels(label) {
    if (this.exampleLabels[this.state.selectedLanguage].filter(l => l.title === label).length === 0) {
      this.exampleLabels[this.state.selectedLanguage].push({ "title": label })
    }
  }

  changeMenuLp(newValue) {
    this.state.metadata.menus[this.state.selectedMenu].lp = newValue
    this.setState(prev => { return { metadata: JSON.parse(JSON.stringify(prev.metadata)) } })
    this.props.updateMetadata(this.state.metadata)
  }

  changeLanguage({ selectedLanguage, t }) {
    if (!this.props.saved && !window.confirm(t('unsaved-warning'))) {
      return;
    }
    this.props.onSavedUpdated(true);

    if (selectedLanguage === "new") {
      this.setState({ selectedLanguage: null })
    } else {
      this.setState({ menuData: undefined, menuDataOriginal: undefined })
      loadMenu(this.props.restaurantId, this.state.selectedMenu, selectedLanguage, this.props.renewCredentials)
        .then(data => {
          this.setState({ selectedSection: data.menuData?.length > 0 ? data.menuData[0].id : null, menuData: data.menuData, menuDataOriginal: JSON.parse(JSON.stringify(data.menuData)), menuOVersion: data.originalVersion, selectedLanguage })
        })
    }
  }

  changeMenu({ selectedMenu, t }) {
    if (!this.props.saved && !window.confirm(t('unsaved-warning'))) {
      return;
    }

    this.props.onSavedUpdated(true);
    document.getElementById("root").scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })

    if (selectedMenu === "new") {
      this.setState({ selectedMenu: null, editMenuParams: false })
    } else {
      if (this.state.metadata.menus[selectedMenu].supportedLanguages.length > 0) {
        this.setState({ menuData: undefined, menuDataOriginal: undefined })
        loadMenu(this.props.restaurantId, selectedMenu, this.state.metadata.menus[selectedMenu].supportedLanguages[0]["code"], this.props.renewCredentials)
          .then(data => {
            this.setState({ menuData: data.menuData, menuDataOriginal: JSON.parse(JSON.stringify(data.menuData)), menuOVersion: data.originalVersion, selectedMenu, selectedLanguage: this.state.metadata.menus[selectedMenu].supportedLanguages[0]["code"] })
          })
      } else {
        this.setState({ menuData: [], menuDataOriginal: [], selectedMenu, selectedLanguage: null })
      }
    }
  }

  // TODO: force refresh of hide and CS

  EleMenu({ parentJsonPath, jsonPath, eleIndex, t, ele, isItem }) {
    return <EleMenu
      id={`menu-${jsonPath}`}
      options={[
        {
          onClick: () => { this.setState({ edit: jsonPath }) },
          label: t('general.edit'),
          icon: <EditIcon />
        },
        isItem && {
          onClick: () => { this.setEleValue(`${jsonPath}.cs`, !ele.cs); }, // TODO: check if it shows
          label: t('general.cs'),
          icon: <AssistantPhotoIcon />
        },
        {
          onClick: () => {
            this.setEleValue(`${jsonPath}.hide`, !ele.hide);
          },
          label: t('edit.hide_unhide'),
          icon: <VisibilityIcon />
        },
        isItem && {
          onClick: () => {
            this.setEleValue(`${jsonPath}.hl`, !ele.hl);
          },
          label: t('general.highlight'),
          icon: <WbIridescentIcon />
        },
        {
          onClick: () => { this.moveUp(eleIndex, parentJsonPath) },
          label: t('general.move-before'),
          icon: <ArrowUpwardIcon />
        },
        {
          onClick: () => { this.moveDown(eleIndex, parentJsonPath) },
          label: t('general.move-after'),
          icon: <ArrowDownwardIcon />
        },
        {
          onClick: () => this.removeEle(eleIndex, parentJsonPath),
          label: t('edit.delete'),
          icon: <DeleteIcon />
        }]} />
  }

  Item({ item, itemIndex, parentJsonPath, t }) {
    let itemJsonPath = `${parentJsonPath}[${itemIndex}]`; // Same as key?
    const defaultCurrency = Cookies.get("currency") || "$"

    var images = item.images || []

    return (
      <div name={item.id || ""} className={"item" + (item.hide ? " hide" : "")}>
        {item.hide && <div className="hidden-item"><div>{t('edit.item.hidden')}</div></div>}
        {(item.cs && !item.hide) && <div className="chefSelection"><div>{t('edit.item.cs')}</div></div>}
        {item.hl && <div className="rainbow-container" >
          <div className="rainbow"></div>
        </div >}
        <div className="inner">
          <div className={"item-content"}>
            <div className={"field item-header"}>
              <div className="name-container" onClick={() => this.setState({ edit: itemJsonPath })}>
                {item.name ? <b>{item.name}</b> : <span style={{ color: 'grey' }}>{t('edit.item.name.title')}</span>}
              </div>
              {item.description && <div className="description" onClick={() => this.setState({ edit: itemJsonPath })}>{item.description}</div>}
              <div className={"positioning-arrows"}>
                {this.eleMenu({ jsonPath: itemJsonPath, eleIndex: itemIndex, t, ele: item, isItem: true, parentJsonPath })}
              </div>
            </div>
            <span onClick={() => this.setState({ edit: itemJsonPath })} style={{ cursor: "pointer" }}>
              <div>
                {(item.labels || []).map(label => <div className="tas-badge">{label}</div>)}
              </div>
              <div className="secondary-fieds">
                <div>
                  <div className="prices">
                    {(!item.price || item.price.length === 0) ? <div className="label">{t('edit.prices.add')}</div> : <>
                      <div className="label">{t('edit.prices.title')}&#160;&#160;</div>
                      <div>{(item.price || []).map(p => p.c ? <div>{`${p.prefix} ${p.c || ""} ${p.amount || ""}`}</div> : <div>{`${p.prefix} ${p.amount || ""} ${p.suffix || ""}`}</div>)}</div>
                    </>}
                  </div>
                  <div className="prices">
                    {(item.options?.length > 0) && <div className="label">{item.options.length}&#160;{item['options-label'] || t('edit.options.title')}&#160;&#160;&#160;</div>}
                    {(item.co?.length > 0) && <div className="label">{item.co.length}&#160;{t('edit.customizations.c')}&#160;&#160;</div>}
                  </div>
                  {item.notes && <div className="notes">
                    <div>{item.notes}</div>
                  </div>}
                </div>
                <div className="images-row">
                  <div className="images">
                    {images.map((image, i) => {
                      return <div className="thumbnail">
                        <div className="img"
                          style={{ width: "5rem", background: "url(" + encodeURI(`${config.url.PUBLIC}/restaurants/${this.props.restaurantId}/images/${image}`).replace(/\(/ig, "%28").replace(/\)/ig, "%29") + ") right center / contain no-repeat" }}
                        > {/** TODO: use encodeURIComponent for image */}
                        </div>
                      </div>
                    })}
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {this.state.edit === itemJsonPath && <div className="fullscreen">
          {/** TODO: move else where */}
          <div style={{ position: "absolute", inset: "0", zIndex: -1 }} onClick={() => {
            this.setState({ focus: null, edit: null })
          }}></div>
          <div className={"item-edit-content"}>
            <div>
              <div className={"field item-header"}>
                <input defaultValue={item.name} placeholder={t('edit.item.name.placeholder')} autoFocus={this.state.inFocus === `${itemJsonPath}.name`} {...this.onChangeProps(`${itemJsonPath}.name`)} />
              </div>
              <div className={"field"}>
                <input defaultValue={item.description} placeholder={t('edit.item.description.title')} {...this.onChangeProps(`${itemJsonPath}.description`)} />
              </div>
              <ExpandableField
                fieldDescription={t('edit.labels.title')}
                label={t('edit.labels.title')}
                expandedField={<ListLabels title={t('edit.labels.title')} key={`${itemJsonPath}.labels`} labels={item.labels} addEle={this.addEle} labelsJsonPath={`${itemJsonPath}.labels`} additionalPossibleLabels={this.remainingLabels(item.labels)} removeLabel={this.removeEle} updateExampleLabels={this.updateExampleLabels} hasFocus={this.state.inFocus === `${itemJsonPath}.labels`} />}
                forceShow={item.labels.length > 0}
              />
              <ExpandableField
                fieldDescription={t('edit.icons.title')}
                label={t('edit.icons.title')}
                expandedField={<ListIcons title={t('edit.icons.title')} key={`${itemJsonPath}.icons`} icons={item.icons || []} addEle={this.addEle} iconsJsonPath={`${itemJsonPath}.icons`} removeIcon={this.removeEle} hasFocus={this.state.inFocus === `${itemJsonPath}.icons`} />}
                forceShow={item.icons?.length > 0}
              />
              <ExpandableField
                fieldDescription={t('edit.prices.title')}
                label={t('edit.prices.title')}
                expandedField={(this.state.metadata?.id === "7965ba8a-d992-463b-9cc3-0359eec0ff69" || this.state.metadata?.id === 'ce47e5c7-ffda-4646-a213-79a10fc94cb1') ?
                  <ListPricesAdvanced exchangeRate={this.state.metadata.exchangeRate || 1} key={`${item.id}-prices-${item.price?.length}`} onChangeProps={this.onChangeProps} dropDownChangeProps={this.dropDownChangeProps} addPrice={this.addEle} removePrice={this.removeEle} prices={("price" in item) ? item.price : []} pricesJsonPath={`${itemJsonPath}.price`} /> :
                  <ListPrices
                    changePriceFormat={(value) => {
                      var newMetadata = JSON.parse(JSON.stringify(this.state.metadata))
                      newMetadata.priceFormat = value

                      this.setState({ metadata: newMetadata }, () => this.props.updateMetadata(this.state.metadata))
                    }}
                    changePriceWithDecimal={(value) => {
                      var newMetadata = JSON.parse(JSON.stringify(this.state.metadata))
                      newMetadata.priceWithDecimal = value

                      this.setState({ metadata: newMetadata }, () => this.props.updateMetadata(this.state.metadata))
                    }}
                    defaultPriceFormat={this.state.metadata.priceFormat || ''}
                    defaultPriceWithDecimal={this.state.metadata.priceWithDecimal || false}
                    key={`${item.id}-prices-${item.price?.length}`}
                    onChangeProps={this.onChangeProps}
                    dropDownChangeProps={this.dropDownChangeProps}
                    addPrice={this.addEle}
                    removePrice={this.removeEle}
                    prices={("price" in item) ? item.price : []}
                    pricesJsonPath={`${itemJsonPath}.price`} />}
                forceShow={("price" in item) ? item.price.length > 0 : false}
                onNaturalExpand={() => {
                  // TODO: This will bug if price is not defined in item
                  this.addEle({ "prefix": "", "amount": null, "c": defaultCurrency }, `${itemJsonPath}.price`)
                }}
              />
              <ExpandableField
                fieldDescription={item['options-label'] || t('edit.options.title')}
                label={t('edit.options.title')}
                expandedField={<ListOptions
                  key={`${item.id}-options-${item.options?.length}`}
                  optionsLabel={item['options-label']}
                  addOption={this.addEle}
                  removeOption={this.removeEle}
                  onChangeProps={this.onChangeProps}
                  dropDownChangeProps={this.dropDownChangeProps}
                  options={("options" in item) ? item.options : []}
                  optionsJsonPath={`${itemJsonPath}.options`} />}
                forceShow={("options" in item) ? item.options.length > 0 : false}
                onNaturalExpand={() => {
                  // TODO: This will bug if options is not defined in item
                  this.addEle({ "prefix": "", "amount": null, "c": defaultCurrency }, `${itemJsonPath}.options`)
                }}
              />
              <ExpandableField
                fieldDescription={t('edit.customizations.title')}
                label={t('edit.customizations.title')}
                expandedField={
                  <ListCustomizations
                    key={`${item.id}-customizations-${item.co?.length}`}
                    onChangeProps={this.onChangeProps}
                    customizations={("co" in item) ? item.co : []}
                    customizationsJsonPath={`${itemJsonPath}.co`}
                    setCustomizations={(value) => this.setEleValue(`${itemJsonPath}.co`, value)}
                  />
                }
                forceShow={("co" in item) ? item.co.length > 0 : false}
                onNaturalExpand={() => {
                  this.setEleValue(`${itemJsonPath}.co`, [{ "label": "", "options": [{ "label": "Cow milk (example)" }, { "label": "Almond milk" }] }], `${itemJsonPath}.co[0]-input`)
                }}
              />
              <ExpandableField
                label={t('edit.item.note.title')}
                fieldDescription={t('edit.item.note.title')}
                expandedField={<div className={"field price-field"}>
                  <div className={"field-description"}>{t('edit.item.note.title')}</div>
                  <input defaultValue={item.notes} autoFocus={true} {...this.onChangeProps(`${itemJsonPath}.notes`)} />
                </div>}
                forceShow={item.notes}
              />
              <div className="image-container">
                <AddImage tier={this.props.tier} images={images} jsonPath={itemJsonPath} restaurantId={this.props.restaurantId} successCallbackBind={this.successCallbackBind} saveImages={this.saveImages} />
              </div>
              <div style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "end"
              }}>
                <div
                  className="tas-button outline borderless"
                  style={{ marginRight: "0.5rem" }}
                  onClick={() => {
                    this.setState({ inFocus: null, edit: null })
                    if (item.id) scrollToName({ name: item.id })
                  }}>
                  <span>{t('finish')}</span>
                </div> {/** TODO: apply the changes */}
                <div
                  className="tas-button outline"
                  onClick={() => this.goToItemOrCreate(itemIndex + 1, parentJsonPath)}><span>{t('next-item')}</span>
                </div> {/** TODO: apply the changes */}
              </div>
            </div>
          </div>
        </div>}
      </div>);
  }

  goToItemOrCreate(itemIndex, parentJsonPath) {
    const allItems = _.get(this.state.menuData, parentJsonPath)

    if (itemIndex < allItems.length) {
      // the item exists, go to it
      this.setState({ inFocus: `${parentJsonPath}[${itemIndex}].name`, edit: `${parentJsonPath}[${itemIndex}]` })
    } else {
      // The item does not exist, create
      this.addEle(newItem(), parentJsonPath, true)
    }
  }

  Subsection({
    key, t, subsection, subsectionIndex, // TODO: same as key -> merge 
    parentJsonPath
  }) {
    const subsectionJsonPath = `${parentJsonPath}[${subsectionIndex}]`

    var images = subsection.images || []

    return (
      <li
        name={subsection.id || ""}
        className={"subsection" + (subsection.hide ? " hide" : "")}
      >
        {subsection.hide && <div className="hidden-item"><div>{t('edit.subsection.hidden')}</div></div>}
        <div className="inner">
          <div className={"subsection-content"}>
            <div className={"field item-header subsection-header"}>
              <span style={{ position: "absolute", transform: "translate(0, -110%)", fontSize: "80%", left: "0", top: "0", fontWeight: "bold" }}>Subsection</span>
              <div style={{ display: "flex", width: "100%" }}>
                <div className="name-container" onClick={() => this.setState({ edit: key })} style={{ cursor: "pointer", marginRight: "auto" }}>
                  {subsection.name ? <b>{subsection.name}</b> : <span>{t('edit.subsection.name.placeholder')}</span>}
                </div>
                {this.eleMenu({ jsonPath: subsectionJsonPath, eleIndex: subsectionIndex, t, parentJsonPath, ele: subsection })}
              </div>
              <div className='secondary-fieds'>
                {subsection.description && <div className="description">{subsection.description}</div>} {/** TODO: format? */}

                {subsection.footnote && <div>{subsection.footnote}</div>}
                <div className="header-images">{images.map(image => <div className="img"
                  style={{ background: "url(" + encodeURI(`${config.url.PUBLIC}/restaurants/${this.props.restaurantId}/images/${image}`).replace(/\(/ig, "%28").replace(/\)/ig, "%29") + ") center center / contain no-repeat" }}
                ></div>
                )}</div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.edit === subsectionJsonPath && <div className="fullscreen">
            {/** TODO: move else where */}
            <div style={{ position: "absolute", inset: "0", zIndex: -1 }} onClick={() => {
              this.setState({ focus: null, edit: null })
            }}></div>
            <div className={"item-edit-content"}>
              <div>
                <div className={"field item-header"} style={{ display: "flex" }}>
                  <input defaultValue={subsection.name} placeholder={t('edit.subsection.name.placeholder')} autoFocus={true} {...this.onChangeProps(`${subsectionJsonPath}.name`)} />
                </div>
                <ExpandableField
                  fieldDescription={t('edit.subsection.description.title')}
                  label={t('edit.section.description.add')}
                  expandedField={
                    <div className="field">
                      <input
                        defaultValue={subsection.description}
                        autoFocus={true}
                        {...this.onChangeProps(`${subsectionJsonPath}.description`)} />
                    </div>
                  }
                  forceShow={subsection.description}
                />
                <ExpandableField
                  fieldDescription={t('edit.subsection.footnote.title')}
                  label={t('edit.section.footnote.add')}
                  expandedField={
                    <div className="field">
                      <input
                        defaultValue={subsection.footnote}
                        autoFocus={true}
                        {...this.onChangeProps(`${subsectionJsonPath}.footnote`)} />
                    </div>}
                  forceShow={subsection.footnote}
                />
                <div className="image-container">
                  <AddImage
                    images={images}
                    tier={this.props.tier}
                    jsonPath={subsectionJsonPath}
                    restaurantId={this.props.restaurantId}
                    successCallbackBind={this.successCallbackBind}
                    saveImages={this.saveImages}
                  />
                </div>
                <div style={{
                  marginTop: "2rem",
                  display: "flex",
                  justifyContent: "end"
                }}>
                  <div
                    className="tas-button outline"
                    onClick={() => {
                      this.setState({ focus: null, edit: null })
                      if (subsection.id) scrollToName({ name: subsection.id })
                    }}><span>{t('finish')}</span>
                  </div> {/** TODO: apply the changes */}
                </div>
              </div>
            </div>
          </div>
        }
      </li >
    )
  }

  Section({ jsonPath, t, section }) {

    var images = section.images || []

    return <li
      name={section.id || ""}
      className={"section" + (section.hide ? " hide" : "")}
      key={`${section.id}-${jsonPath}`}
    >
      {section.hide && <div className="hidden-item"><div>{t('edit.section.hidden')}</div></div>}
      <div className="inner">
        <div className={"section-content"}>
          <div className={"field --section"}>
            <div className={"items"}>
              {section.items.length > 0 &&
                (section.items.map((item, itemIndex) => (
                  this.item({
                    t,
                    key: `${item.id}-${itemIndex}`,
                    parentJsonPath: `${jsonPath}.items`,
                    itemIndex,
                    item
                  })
                )))}
            </div>
          </div>
          {section.subsections.map((subsection, subsectionIndex) => (
            <div key={subsection.id}>
              <div className={"field --subsection"}>
                {this.subsection({
                  parentJsonPath: `${jsonPath}.subsections`,
                  subsection,
                  subsectionIndex,
                  t,
                  key: `${jsonPath}.subsections[${subsectionIndex}]` // TODO: check unicity
                })}
              </div>
              <div className={"field --subsection"}>
                <div className={"field-description"}></div>
                <div className={"items"}>
                  {subsection.items.map((item, itemIndex) => (
                    this.item({
                      t,
                      key: item.id,
                      parentJsonPath: `${jsonPath}.subsections[${subsectionIndex}].items`,
                      item,
                      itemIndex,
                    })
                  ))}
                  <div className='tas-button' onClick={() => this.addEle(newItem(), `${jsonPath}.subsections[${subsectionIndex}].items`, true)}>{t('edit.add-item-in-subsection')}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {this.state.edit === jsonPath && <div className="fullscreen">
        {/** TODO: move else where */}
        <div style={{ position: "absolute", inset: "0", zIndex: -1 }} onClick={() => {
          this.setState({ focus: null, edit: null })
        }}></div>
        <div className={"item-edit-content"}>
          <div>
            <div className="field item-header">
              <input
                defaultValue={section.name}
                placeholder={t('edit.section.name.placeholder')}
                autoFocus={this.state.inFocus === `${jsonPath}.name`}
                {...this.onChangeProps(`${jsonPath}.name`)}
              />
            </div>
            <ExpandableField
              fieldDescription={t('edit.section.description.title')}
              label={t('edit.section.description.add')}
              expandedField={
                <div className="field">
                  <input
                    defaultValue={section.description}
                    autoFocus={true}
                    {...this.onChangeProps(`${jsonPath}.description`)} />
                </div>
              }
              forceShow={section.description}
            />
            <ExpandableField
              fieldDescription={t('edit.section.footnote.title')}
              label={t('edit.section.footnote.add')}
              expandedField={
                <div className="field">
                  <input
                    defaultValue={section.footnote}
                    autoFocus={true}
                    {...this.onChangeProps(`${jsonPath}.footnote`)} />
                </div>
              }
              forceShow={section.footnote}
            />
            <div className={"image-container"}>
              <AddImage
                tier={this.props.tier}
                images={images}
                jsonPath={jsonPath}
                successCallbackBind={this.successCallbackBind}
                {...{ saveImages: this.saveImages, restaurantId: this.props.restaurantId }}
              />
            </div>
            <div style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: "end"
            }}>
              <div
                className="tas-button outline"
                onClick={() => {
                  this.setState({ focus: "", edit: null })
                  if (section.id) scrollToName({ name: section.id })
                }}><span>{t('finish')}</span>
              </div> {/** TODO: apply the changes */}
            </div>
          </div>
        </div>
      </div>
      }
    </li >
  }

  ListSections({ t }) {
    const selectedSecIndex = this.state.selectedSection ? this.state.menuData.findIndex(s => s.id === this.state.selectedSection) : -1

    return <ul className={"sections"} key={this.state.forcedMenuRefresh}>
      {this.state.menuData.length === 0 ?
        <div style={{ display: "flex", color: "grey", alignItems: "center", marginTop: "2rem", padding: "0 2rem" }}>
          <b>{t('edit.emptymenu')}</b>&#160;&#160;&#160;
          <AddEleButton
            addSection={() => {
              const newS = newSection({})
              this.addEle(newS, "", true)
              this.setState({ selectedSection: newS.id })
            }}
            key={`null-add-ele`}
          />
        </div>
        : <div>
          <div className='section-selectors-wrapper'>
            <div className='section-selectors'>
              {this.state.menuData.map((section, sectionIndex) => <div
                className={(section.id === this.state.selectedSection ? "selected" : "")}
                onClick={() => this.setState({ selectedSection: section.id })}>
                {section.hide && <span style={{ position: "absolute", top: "0", left: "0" }}><VisibilityOffIcon style={{ fill: "orange", background: "transparent" }} /></span>}
                <span style={{ paddingRight: "1rem", display: "flex", alignItems: "center" }}>{section.name ? <b>{section.name}</b> : <i>???</i>}</span>
                {(section.id === this.state.selectedSection) && this.eleMenu({ jsonPath: `[${sectionIndex}]`, eleIndex: sectionIndex, t, ele: section, parentJsonPath: "" })}
              </div>)}
              <span style={{ display: "flex", alignItems: "center", marginRight: "1rem" }}>&#160;&#160;&#160; 👉 &#160;
                <AddEleButton
                  addSection={() => {
                    const newS = newSection({})
                    this.addEle(newS, "", true)
                    this.setState({ selectedSection: newS.id })
                  }}
                  key={`null-add-ele`}
                />
              </span>
            </div>
            {(selectedSecIndex >= 0 && !this.state.menuData[selectedSecIndex].items.length && !this.state.menuData[selectedSecIndex].subsections.length) && <div style={{ padding: "2rem 0" }}><b>{this.state.menuData[selectedSecIndex].name}</b>&#160;{t('edit.isempty')}.</div>}
            <div style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}>
              <div className='tas-button' onClick={() => this.addEle(newItem(), `[${selectedSecIndex}].items`, true)}>{t('edit.add-item')}</div>
              <div style={{ margin: "0 1rem" }}>{t('general.or')} </div>
              <div className='tas-button' onClick={() => this.addEle(newSubsection(), `[${selectedSecIndex}].subsections`, true)}>{t('edit.add-subsection')}</div>
            </div>
          </div>
          {selectedSecIndex >= 0 && this.section({ jsonPath: `[${selectedSecIndex}]`, t, section: this.state.menuData[selectedSecIndex], sectionIndex: selectedSecIndex })}
        </div>
      }
    </ul>
  }

  changeP(p, keyToChange, keyAsInput, rate, roundStrategy) {
    if (p[keyAsInput]) try {
      if (roundStrategy === "cent") p[keyToChange] = Math.round(numberparser(p[keyAsInput]) * rate * 100) / 100
      else if (roundStrategy === "unit") p[keyToChange] = Math.round(numberparser(p[keyAsInput]) * rate)
      else if (roundStrategy === "10") p[keyToChange] = Math.round(numberparser(p[keyAsInput]) * rate / 10) * 10
      else if (roundStrategy === "100") p[keyToChange] = Math.round(numberparser(p[keyAsInput]) * rate / 100) * 100
      else if (roundStrategy === "1000") p[keyToChange] = Math.round(numberparser(p[keyAsInput]) * rate / 1000) * 1000
    } catch (e) {
      alert("failed to change price.")
    }
  }

  changePrices(factor, exchangeRate, roundStrategy) {
    if (factor != null) {
      this.state.menuData.forEach((section) => {
        section.items.forEach((item) => {
          item.price.forEach(p => this.changeP(p, "amount", "amount", factor, roundStrategy))
          item.options.forEach(o => this.changeP(o, "amount", "amount", factor, roundStrategy))
        })

        section.subsections.forEach((subsection) => {
          subsection.items.forEach((item) => {
            item.price.forEach(p => this.changeP(p, "amount", "amount", factor, roundStrategy))
            item.options.forEach(o => this.changeP(o, "amount", "amount", factor, roundStrategy))
          })
        })
      })
      this.setState({ priceChange: false }, () => this.props.onSavedUpdated(false))
    }

    if (exchangeRate != null) {
      this.state.menuData.forEach((section) => {
        section.items.forEach((item) => {
          item.price.forEach(p => this.changeP(p, "amount", "bp", exchangeRate, roundStrategy))
          item.options.forEach(o => this.changeP(o, "amount", "bp", exchangeRate, roundStrategy))
        })

        section.subsections.forEach((subsection) => {
          subsection.items.forEach((item) => {
            item.price.forEach(p => this.changeP(p, "amount", "bp", exchangeRate, roundStrategy))
            item.options.forEach(o => this.changeP(o, "amount", "bp", exchangeRate, roundStrategy))
          })
        })
      })
      this.setState({ priceConversion: false }, () => this.props.onSavedUpdated(false))
    }
  }

  startTour() {
    this.setState({ setup_state: "completed" }, () => {
      markSetupDone({ solution: "digital-menu" })
      this.props.updateSession({ ...this.props.session, solutions_setup: [...this.props.session.solutions_setup, 'digital-menu'] })
    })
  }


  render() {
    const { t } = this.props;

    const supportedLang = this.state.selectedMenu && this.state.metadata.menus[this.state.selectedMenu].supportedLanguages || []

    return <div className={"edit-content App"}>
      {(this.state.showConflictWindow) && <ConflictWindow
        keepAllChanges={this.keepAllChanges}
        keepLocalChanges={this.keepLocalChanges}
        cancel={() => this.setState({ showConflictWindow: false })}
        t={t}
      />}
      {this.state.openDownload && <React.Suspense fallback={<><Loader /></>}>
        <DownloadMenu
          menu={{ name: this.state.metadata.menus[this.state.selectedMenu].label, lang: this.codeToLang[this.state.selectedLanguage], content: this.state.menuData }}
          cancel={() => this.setState({ openDownload: false })} />
      </React.Suspense>}
      {this.state.openUpload && <React.Suspense fallback={<><Loader /></>}>
        <UploadMenu
          menu={{ name: this.state.metadata.menus[this.state.selectedMenu].label, lang: this.codeToLang[this.state.selectedLanguage], content: this.state.menuData }}
          cancel={() => this.setState({ openUpload: false })}
          uploadCallback={(uploadData, action) => {
            if (action === "overwrite") {
              this.setState({ menuData: uploadData, menuDataOriginal: uploadData, openUpload: false, forcedMenuRefresh: uuidv4() })
              this.props.onSavedUpdated(false)
            } else {
              this.createMenu("New Menu", { "name": t("edit.english"), "code": "en" }, uploadData, undefined)
              this.setState({ openUpload: false, forcedMenuRefresh: uuidv4() })
            }
          }} />
      </React.Suspense>}
      {this.state.openSync && <React.Suspense fallback={<><Loader /></>}>
        <SyncLanguages
          close={() => this.setState({ openSync: false })}
          selectedLanguage={this.state.selectedLanguage}
          selectedMenu={this.state.selectedMenu}
          selectedMenuData={this.state.menuData}
          selectedMenuLabel={this.state.metadata.menus[this.state.selectedMenu].label}
          restaurantId={this.props.restaurantId}
          allLanguages={this.state.metadata.menus[this.state.selectedMenu].supportedLanguages}
        />
      </React.Suspense>}
      {this.state.priceChange && <React.Suspense fallback={<><Loader /></>}>
        <EditPrices
          cancel={() => this.setState({ priceChange: false })}
          callback={(increasePerc, descreasePerc) => {
            this.changePrices(Math.max(0, (100 + increasePerc - descreasePerc)) / 100, null, "cent")
          }}
          menuInfo={{ name: this.state.metadata.menus[this.state.selectedMenu].label, lang: this.codeToLang[this.state.selectedLanguage] }} />
      </React.Suspense>}
      {this.state.priceConversion && <React.Suspense fallback={<><Loader /></>}>
        <ConvertPrices
          roundStrategy={this.state.metadata?.round || "cent"}
          currentExchangeRate={this.state.metadata?.exchangeRate || 1}
          cancel={() => this.setState({ priceConversion: false })}
          callback={(exchangeRate, round) => {
            this.state.metadata.exchangeRate = exchangeRate
            this.state.metadata.round = round || "cent"
            this.props.updateMetadata(this.state.metadata)
            this.changePrices(null, exchangeRate, round)
          }}
          menuInfo={{ name: this.state.metadata.menus[this.state.selectedMenu].label, lang: this.codeToLang[this.state.selectedLanguage] }} />
      </React.Suspense>}
      {(this.state.setup_state === "pending") ?
        <div style={{ margin: "5rem auto", padding: "1rem" }}>
          <h2>{t('edit.setup.title')}</h2>
          <div className='desc'>{t('edit.setup.desc')}</div>
          <br />
          <div className="buttons-wrapper">
            <div className='tas-button' onClick={() => this.startTour()}>{t('edit.setup.cta')}</div>
          </div>
        </div>
        : <>
          <div className="app-menu">
            <div className="first-row">
              <SaveButton key={this.props.saved} saved={this.props.saved} callback={() => { this.saveMenu() }} />
              {this.state.selectedMenu && <>
                <FormControl>
                  <Select
                    sx={{ background: "white" }}
                    id="menu-simple-select"
                    value={this.state.selectedMenu}
                    size="small"
                    onChange={(e) => this.changeMenu({ selectedMenu: e.target.value, t })}
                  >
                    {Object.keys(this.state.metadata.menus).map(
                      (menuKey) => <MenuItem value={menuKey} key={`menu-select-${menuKey}`}>
                        <div>{this.state.metadata.menus[menuKey].label}</div>
                      </MenuItem>
                    )}
                    <MenuItem value={"new"} style={{ paddingTop: "0.75rem", borderTop: "1px solid rgb(225, 225, 225)" }}>
                      {t('edit.new-menu')}
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <Select
                    sx={{ background: "white" }}
                    id="lang-simple-select-3"
                    value={this.state.selectedLanguage}
                    size="small"
                    onChange={(e) => {
                      this.changeLanguage({ selectedLanguage: e.target.value, t })
                    }}
                  >
                    {supportedLang.map((lang) => <MenuItem value={lang["code"]} key={uuidv4()}>{lang["name"]}</MenuItem>)}
                    <MenuItem style={{ paddingTop: "0.75rem", borderTop: "1px solid rgb(225, 225, 225)" }} value="new">{t('edit.translate')}</MenuItem>
                  </Select>
                </FormControl>
                <div className='clickable lg-screen' style={{ marginRight: "1rem" }} onClick={() => { if (this.state.selectedMenu) this.setState({ editMenuParams: true }) }} title="settings"><SettingsIcon /></div>
              </>}
              {supportedLang.length > 1 && <>
                <div style={{ display: "flex", alignItems: "center", padding: "0.5rem" }}>
                  <FormControl>
                    <InputLabel id="lp-select-label">{t("edit.lp.0")}</InputLabel>
                    <Select
                      labelId="lp-select-label"
                      label="Landing page"
                      sx={{ background: "white", minWidth: "8rem" }}
                      value={this.state.metadata.menus[this.state.selectedMenu].lp || false}
                      size="small"
                      onChange={(e) => this.changeMenuLp(e.target.value)}
                    >
                      <MenuItem value={false}>{t("edit.lp.1")}</MenuItem>
                      <MenuItem value={true}>{t("edit.lp.2")}</MenuItem>
                    </Select>
                  </FormControl>
                  <div onClick={() => this.setState({ openSync: true })} className='lg-screen tas-button grey outline borderless'><span><Sync /></span></div>
                </div>
              </>}
              {this.state.selectedMenu && <>
                <div onClick={() => this.setState({ openDownload: true })} className="lg-screen tas-button grey outline borderless"><span><FileDownloadIcon /></span></div>
                <div onClick={() => this.setState({ openUpload: true })} className="lg-screen tas-button grey outline borderless"><span><FileUploadIcon /></span></div>
                <div onClick={() => this.setState({ priceChange: true })} className="lg-screen tas-button grey outline borderless"><span><PriceChange /></span></div>
                {(this.state.metadata?.id === '7965ba8a-d992-463b-9cc3-0359eec0ff69' || this.state.metadata?.id === 'ce47e5c7-ffda-4646-a213-79a10fc94cb1') && <div onClick={() => this.setState({ priceConversion: true })} className="lg-screen tas-button grey outline borderless"><span><CurrencyExchange /></span></div>}
                <div style={{ marginLeft: "auto" }}>
                  {(this.state.metadata.menus[this.state.selectedMenu].supportedLanguages.length > 1) ?
                    <div onClick={() => { this.removeLanguage() }} className="tas-button danger borderless">
                      <span><DeleteIcon />{t("edit.remove-language")}</span>
                    </div>
                    : <div className="tas-button danger borderless" onClick={() => this.removeMenu(this.state.selectedMenu)}>
                      <span><DeleteIcon />{t("edit.remove-menu")}</span>
                    </div>
                  }
                </div>
              </>}
            </div>
          </div>
          <div className="app-content" name="app-content">
            {
              (this.state.editMenuParams) ?
                <section>
                  <EditMenuParams t={t} updateMetadata={this.props.updateMetadata} closeMenuParam={(() => this.setState({ editMenuParams: false })).bind(this)} cloneMenu={this.cloneMenu} removeMenu={this.removeMenu} markAsActive={this.state.metadata.activeMenu === this.state.selectedMenu} metadata={this.state.metadata} selectedMenu={this.state.selectedMenu} />
                </section>
                :
                (this.state.selectedMenu) ? (
                  this.state.selectedLanguage ?
                    (this.state.menuData === undefined) ?
                      <div style={{ width: "100%", display: "flex", minHeight: "10rem" }}>
                        <div style={{ width: "4rem", margin: "auto", textAlign: "center" }}>Loading...</div>
                      </div>
                      :
                      <section className="menu-edit">
                        <div>{this.listSections({ t })}</div>
                        {window?.innerWidth < 880 ? <></> : <div>
                          <PreviewView
                            restaurantId={this.props.restaurantId}
                            menuId={this.state.selectedMenu}
                            show={this.props.saved}
                            saveCallback={() => this.saveMenu()}
                            menuData={this.state.menuDataOriginal}
                            metadata={this.state.metadata} />
                        </div>}
                      </section>
                    : <section>
                      <TranslationInterface
                        addLanguage={this.addLanguage}
                        metadata={this.state.metadata}
                        selectedMenu={this.state.selectedMenu}
                        key={uuidv4()}
                        cancel={() => {
                          const fallbackLang = this.state.metadata.menus[this.state.selectedMenu].supportedLanguages.length > 0 ? this.state.metadata.menus[this.state.selectedMenu].supportedLanguages[0]["code"] : ""
                          this.changeLanguage({ selectedLanguage: fallbackLang, t })
                        }} />
                    </section>
                )
                  :
                  <section>
                    <CreateMenu metadata={this.state.metadata} t={t} createMenu={this.createMenu} markAsActive={Object.keys(this.state.metadata.menus).length === 0} />
                  </section>
            }
          </div>
        </>}
    </div >
  }
}

const EditViewTranslated = withTranslation('app')(EditView)

export default EditViewTranslated; // withRouter(EditViewTranslated);
