import React, { useEffect, useState } from 'react';
import { config } from '../Constants'
import { useTranslation } from "react-i18next";
import CreateIcon from '@mui/icons-material/Create';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VisibilityIcon from '@mui/icons-material/Visibility'; import './ContactView.css';
import Collapsible from 'react-collapsible';
import SupportTicketView from './SupportTicketView';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TextField from '@mui/material/TextField';
import QuizIcon from '@mui/icons-material/Quiz';
import { queryJsonData } from '../utils/MenuUtils';
import { useLocation, useParams } from 'react-router-dom';

function MessageHighlight({ title, body, status }) {
    return <div className="message-highlight">
        <div><VisibilityIcon /></div>
        {status === "waiting" && <div className="message-status"></div>}
        <div className="message-title">{title}</div>
        <div className="message-body">{body}</div>
    </div>
}

function ContactView({
    restaurantId,
    renewCredentials,
    viewSelected,
}) {
    const { subpage, ticketId } = useParams()
    const { state } = useLocation()

    const [title, setTitle] = useState(state?.title || "")
    const [message, setMessage] = useState(state?.message || "")
    const [sendingMessage, setSendingMessage] = useState(false)
    const [messageSuccessfullySubmitted, setMessageSuccessfullySubmitted] = useState(null)
    const [activeView, setActiveView] = useState(viewSelected || subpage || "write")
    const [tickets, setTickets] = useState([])

    const { t } = useTranslation('app')

    useEffect(() => {
        fetchTickets()
    }, [activeView])

    function fetchTickets() {
        queryJsonData({
            url: config.url.API + config.endpoints.support.tickets,
            verb: "GET",
            renewCredentials
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(data => {
                    setTickets([...data])
                })
            }
        })
    }

    function updateStatus({ ticketIndex, status }) { // TODO: optimize
        var newTickets = [...tickets]
        newTickets[ticketIndex]["status"] = status

        setTickets([...newTickets])
    }

    async function handleSubmit(e) {
        e.preventDefault();

        if (sendingMessage) return

        setSendingMessage(true)
        setMessageSuccessfullySubmitted(null)

        queryJsonData({
            url: config.url.API + config.endpoints.support.tickets,
            verb: "POST",
            body: { title, category: 'question', message },
            renewCredentials
        })
            .then((response) => {
                if (response.status === 200) {
                    setTitle("")
                    setMessage("")
                    setSendingMessage(false)
                    setMessageSuccessfullySubmitted(true)
                }
                else {
                    setSendingMessage(false)
                    setMessageSuccessfullySubmitted(false)
                }
            })
            .catch((error) => {
                console.error(error);
                setSendingMessage(false)
                setMessageSuccessfullySubmitted(false)  // TOOD: toastify
            })
    }

    const unreadMessage = tickets.some(ticket => ticket.status && ticket.status === "waiting")

    return <div id="contact-us" name="app-content">
        <div className="bubble-menu">
            {/* <div>
                <div
                    onClick={() => setActiveView("whatsapp")}
                    className={"menu-item " + (activeView === "whatsapp" ? "selected" : "")}
                    title="whatsapp">
                    <WhatsAppIcon style={{ height: "1.5rem", width: "1.5rem" }} />
                </div>
            </div> */}
            <div><div onClick={() => setActiveView("write")} className={"menu-item " + (activeView === "write" ? "selected" : "")} title="Create new" ><CreateIcon /></div></div>
            <div><div onClick={() => setActiveView("tickets")} className={"menu-item " + (activeView === "tickets" ? "selected" : "")} title="All messages">
                <MailOutlineIcon />
                {unreadMessage && <div className="message-status" style={{ position: "absolute", top: 0, right: "-1rem" }}></div>}
            </div></div>
        </div>
        <div style={{ margin: "1rem" }}>
            {(activeView === "write") && <div>
                <div style={{
                    fontSize: "175%",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "2rem"
                }}>{t("contact.maintitle")}</div>
                <TextField size="small" fullWidth value={title} onChange={(e) => setTitle(e.target.value)} label={t("contact.title")} />
                <TextField sx={{ margin: "1rem 0" }} multiline rows={4} size="small" fullWidth value={message} onChange={(e) => setMessage(e.target.value)} label={t("contact.message")} />
                <input type="submit" value={t("contact.send")} className="button tas-button wide" onClick={handleSubmit} />
                <div>
                    {sendingMessage && t("contact.messagebeingsent")}
                    {messageSuccessfullySubmitted && t("contact.messagesent")} {/** TODO: handle false case? */}
                </div>
            </div>}
            {(activeView === "tickets") && <div className="inbox">
                {tickets.length === 0 &&
                    <div style={{ textAlign: "center", marginTop: "2rem" }}>{t('contact.noticket')}</div>
                }
                {tickets.map((ticket, i) => <div key={ticket.id}> {/** this.ticketsRef[ticket.id] = ref */}
                    <Collapsible
                        open={ticketId == ticket.id} // ATTENTION: Cannot use === here because ticket.id is an int.
                        trigger={<MessageHighlight title={ticket.title} body={ticket.message} status={ticket.status} />}
                        lazyRender={true}>
                        <SupportTicketView
                            restaurantId={restaurantId}
                            ticketId={ticket.id}
                            ticketIndex={i}
                            updateStatus={updateStatus} />
                    </Collapsible>
                    { }
                </div>
                )}
            </div>}
            {(activeView === "whatsapp") && <div className="whatsapp-support">
                <div>
                    <p>{t('contact.whatsapp-add-us')}: <b>+41 79 479 34 65</b></p>
                    <p>{t('contact.whatsapp-qrcode')}:</p>
                </div>
                <div style={{
                    margin: "auto",
                    borderRadius: "8px",
                    backgroundImage: "url(/static/media/WhatsApp-Contact-230531.jpeg)",
                    maxWidth: "100%", width: "20rem", height: "24rem", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat"
                }} />
            </div>}
            <div>
                <div style={{
                    fontWeight: "bold",
                    padding: "2rem 0",
                    color: "rgba(0,0,0,0.3)",
                    textAlign: "center"
                }}>{t('or')}</div>
                <a href="https://docs.takeaseat.io/docs/intro" target="_blank" className='tas-button outline' style={{ width: "100%", padding: "0.75rem" }}><span><QuizIcon style={{ marginRight: "0.5rem" }} /> Documentation Center (English only)</span></a>
            </div>
        </div>
    </div>
}

export default ContactView;
