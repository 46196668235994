import React, { useEffect, useState } from 'react';
import { config } from '../Constants'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { queryJsonData } from '../utils/MenuUtils'


const rowStyle = {
    maxWidth: "960px",
    margin: "auto",
    paddingLeft: "1rem"
}

const solutionsInfo = {
    'digital-menu': {
        'image': "/images/solutions/digital-menu-2.png",
        'title': 'Digital menus',
        'desc': 'Create and manage menus with QR code or direct link.',
        'page': '/edit'
    },
    'public-page': {
        'image': "/images/solutions/public-page.png",
        'title': "Website",
        'desc': 'Get a modern website with image gallery, contact form, maps and more.',
        'page': '/website/edit'
    },
    'marketing': {
        'image': "/images/solutions/marketing.png",
        'title': 'Marketing (BETA)',
        'desc': 'Create and publish on social networks in one click.',
        'page': '/marketing'
    },
    'table-reservation': {
        'image': "/images/solutions/table-reservation.png",
        'title': "Table reservation",
        'desc': "Efficient system to accept online reservations",
        'page': '/reservations'
    },
    'ordering': {
        'image': "/images/solutions/ordering-2.png",
        'title': "Ordering",
        'desc': "Take and manage orders. Directly from the menus or from your staff devices.",
        'page': '/orders'
    },
    'surveys': {
        'image': "/images/solutions/surveys.jpg",
        'title': "Surveys",
        'desc': "Create surveys to measure customer satisfaction. Comes with link and QR code.",
        'page': '/surveys'
    }
}

function SolutionCard({ image, title, desc, current, activateCallback, deactivateCallback }) {
    return <Card sx={{ maxWidth: 300, display: "inline-block", marginBottom: "1rem", marginRight: "1rem" }}>
        <CardMedia
            component="img"
            height="140"
            image={`${config.url.PUBLIC}${image}`}
            alt="green iguana"
        />
        <CardContent>
            <Typography gutterBottom variant="h5" component="div">{title}</Typography>
            <Typography variant="body2" color="text.secondary">{desc}</Typography>
        </CardContent>
        <CardActions>
            {current ? <Button onClick={deactivateCallback}>
                <DeleteIcon />
                <span style={{ lineHeight: "initial" }}>Remove</span>
            </Button> : <Button onClick={activateCallback}>
                <AddIcon />
                <span style={{ lineHeight: "initial" }}>Activate</span>
            </Button>}
        </CardActions>
    </Card>
}

const allSolutions = ['digital-menu', 'table-reservation', 'ordering', 'marketing', 'public-page', 'surveys']

function AppStore({ solutions }) {
    useEffect(() => { }, [])

    const cleanedSolutions = Array.from(new Set(solutions || [])).filter(ele => ele)
    const moreSolutions = allSolutions.filter(solution => !cleanedSolutions.includes(solution))

    function activateSolution(solution) {
        queryJsonData({
            url: `${config.url.API}/solutions/activate`,
            body: { solution },
            renewCredentials: () => alert("DO LOGIN"),
            verb: "POST"
        }).then(resp => {
            if (resp.status === 200) window.location.href = solutionsInfo[solution].page
        })
    }

    function deactivateSolution(solution) {
        queryJsonData({
            url: `${config.url.API}/solutions/deactivate`,
            body: { solution },
            renewCredentials: () => alert("DO LOGIN"),
            verb: "POST"
        }).then(resp => {
            if (resp.status === 200) window.location.reload()
        })
    }

    return <div id="appstore">
        <div className="app-menu">
            <div className="first-row">

            </div>
        </div>
        <div className="app-content">
            <div style={rowStyle}>
                <div style={{ fontSize: "175%", margin: "1.5rem 0px" }}><b>Your current functionalities</b></div>
                <div>
                    {cleanedSolutions.map(solution => solutionsInfo[solution] ? <SolutionCard
                        current={true}
                        image={solutionsInfo[solution]["image"]}
                        title={solutionsInfo[solution]["title"]}
                        desc={solutionsInfo[solution]["desc"]}
                        deactivateCallback={() => deactivateSolution(solution)}
                    /> : <></>)}
                    {(cleanedSolutions.length === 0) && <div>
                        You have no app activated.
                    </div>}
                </div>
            </div>
            <div style={rowStyle}>
                <div style={{ fontSize: "175%", margin: "1.5rem 0px" }}><b>More functionalities</b></div>
                <div>
                    {moreSolutions.map(solution => <SolutionCard
                        current={false}
                        image={solutionsInfo[solution]["image"]}
                        title={solutionsInfo[solution]["title"]}
                        desc={solutionsInfo[solution]["desc"]}
                        activateCallback={() => activateSolution(solution)}
                    />)}
                    {(moreSolutions.length === 0) && <div style={{ paddingBottom: "2rem" }}>
                        All apps have been activated.
                    </div>}
                </div>
            </div>
        </div>
    </div>
}

export default AppStore;