import { config } from '../Constants'


export function Loader() {
    // Hack use header to offset from the nav bar...
    return <div className="header" style={{ position: "absolute", inset: "0", display: "flex", alignItems: "center", justifyContent: "center" }}>
        Loading...
    </div>
}

export function emptyItem() {
    return {
        "id": Math.random().toString(),
        "name": "",
        "description": "",
        "labels": [],
        "price": [],
        "options": [],
        "notes": ""
    }
}

export function emptySection() {
    return {
        "id": Math.random().toString(),
        "name": "",
        "description": "",
        "footnote": "",
        "items": [],
        "subsections": []
    }
}

export function emptySubsection() {
  return {
    "id": Math.random().toString(),
    "name": "",
    "items": [],
    "description": "",
    "footnote": ""
  }
}

export function singleItemMenu() {
    var emptySec = emptySection()
    emptySec.items.push(emptyItem())
    return [emptySec]
}

export function emptyMenu() {
    return [emptySection()]
}

// TODO: merge all three methods below

export async function getJsonData(url, renewCredentials) {
    return fetch(url, {
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include"
    }).then(resp => {
        if (resp.status === 403) {
            if (renewCredentials) renewCredentials()
            return {}
        } else if (resp.status === 404) {
            return {} // Should return RESP instead
        } else {
            return resp.json() // Should return RESP instead
        }
    })
}

export async function putJsonData(url, jsonBody, renewCredentials) {
    return fetch(url, {
        method: 'put',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify(jsonBody)
    }).then(resp => {
        if (resp.status === 403) {
            if (renewCredentials) renewCredentials()
            return resp
        } else {
            return resp
        }
    })
}

export async function queryJsonData({url, body, renewCredentials, verb}) {
    return fetch(url, {
        method: verb,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify(body)
    }).then(resp => {
        if (resp.status === 403) {
            if (renewCredentials) renewCredentials()
            return resp
        } else {
            return resp
        }
    })
}

export async function loadMenu(restaurantId, menuId, languageCode, renewCredentials) {
    if ([restaurantId, menuId, languageCode].every(v => v)) { // Check that all parameters are defined
        const url = `${config.url.API}/menu/${restaurantId}/${menuId}/${languageCode}`
        return getJsonData(url, renewCredentials)
    }
    return []
}

export function translationCheckout({ menu, language }) {
  fetch(`${config.url.API}/stripe/translation-request`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: "include",
    body: JSON.stringify({ menu, language })
  }).then(resp => {
        /*if (resp.status === 403) {
            this.props.renewCredentials();
        } else*/ if (resp.status === 200) {
      resp.json().then(r => window.location.href = r.url);
    }
  })
  window.gtag('event', 'start_translation_checkout');
}