import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "./SetupPage.scss"
import { saveMetadata } from '../utils/MetadataUtils';
import { logout } from '../utils/LoadInformation';
import LogoutIcon from '@mui/icons-material/Logout';
import LoadingButton from '@mui/lab/LoadingButton'; // TODO: optimize the import
import TextField from '@mui/material/TextField';
import { Autocomplete, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { countries } from 'countries-list';
import { config } from '../Constants';
import { toast } from 'react-toastify';
import { CheckCircle } from '@mui/icons-material';
import posthog from 'posthog-js'

function parseCountries() {
	var dict = {}
	var currencies = {}

	Object.entries(countries).forEach(([countryCode, countryDetails]) => {
		dict[`${countryDetails["name"]}`.toString()] = { code: countryCode, currency: countryDetails['currency'].split(',')[0] }

		if (countryDetails["native"] !== countryDetails["name"]) {
			dict[`${countryDetails["native"]}`.toString()] = { code: countryCode, currency: countryDetails['currency'].split(',')[0] }
		}

		currencies[countryDetails['currency']] = 1
	})

	dict["Switzerland"] = { code: "CH", currency: "CHF" }
	dict["Schweiz"] = { code: "CH", currency: "CHF" }
	dict["Suisse"] = { code: "CH", currency: "CHF" }
	dict["Svizzera"] = { code: "CH", currency: "CHF" }
	dict["Liban"] = { code: "LB", currency: "LBP" }

	currencies = ["$", ...Object.keys(currencies)]

	return [dict, currencies];
}

const allCountries = parseCountries()[0]

const businessTypes = ["restaurant", "bar", "hotel", "event-organizer", "private-individual", "other"]

function InfoSection({ defaultLoading, onNext, metadata, refreshMetadata }) {
	const [loading, setLoading] = useState(defaultLoading)
	const [name, setName] = useState('')
	const [businessType, setBusinessType] = useState(null)
	const [country, setCountry] = useState(null)
	const { t } = useTranslation('app');

	async function saveInformation() {
		if (!name.trim() || !country || !businessType) {
			toast.warn("Missing information")
			return null
		}

		if (loading) return null

		setLoading(true)

		return Promise.all([
			fetch(config.url.API + config.endpoints['business-setup'], { // TODO: no need to have restaurant id in the route
				method: 'PATCH',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				credentials: "include",
				body: JSON.stringify({
					"name": name.trim(),
					"country": country,
					"business_type": businessType?.id.replace("-", " "), // TODO: replace, it s ugly
				})
			}).then(resp => {
				return true
			}),
			fetch(config.url.API + config.endpoints['init-alias'], {
				method: 'post',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				credentials: "include",
				body: JSON.stringify({ name: name.trim() })
			})
				.then(resp => {
					if (resp.status === 200) {
						if (window.sendEvent) {
							window.sendEvent("complete_onboarding_step_1", "")
						}

						resp.json().then(data => {
							saveMetadata({ restaurantId: metadata.id, metadata: { ...metadata, name: name.trim(), alias: data.alias } }).then(_ => {
								refreshMetadata({ ...metadata, name: name.trim(), alias: data.alias })
							})
						})
					}
					return true
				})
		])
	}

	return <div className={"setup-page-content"}>
		<div style={{ textAlign: "center", marginBottom: "1rem" }}>
			<img alt="Take a Seat logo" src={`https://takeaseat.io/media/icons/tas-230526.svg`} width={75} />
		</div>
		<div className="page-title">{t('setup.your-information.title')}</div>
		<div className='field'>
			<TextField
				fullWidth
				label={t('setup.your-information.name-label')}
				variant="outlined"
				// className={this.state.missingName ? 'missing-info' : ''}
				value={name}
				onChange={e => {
					setName(e.target.value)
				}}
			/>
		</div>
		<div className='field'>
			<Autocomplete
				fullWidth
				value={businessType}
				// className={this.state.missingType ? 'missing-info' : ''}
				options={
					businessTypes.map(businessType => {
						return { 'label': t(`setup.business-types.${businessType}`), 'id': businessType }
					})
				}
				onChange={(e, newValue) => setBusinessType(newValue)}
				renderInput={(params) => <TextField {...params} label={t('setup.your-information.btype-label')} />}
			/>
		</div>
		<div className="field">
			<Autocomplete
				value={country}
				id="country-select"
				// className={this.state.missingCountry ? 'missing-info' : ''}
				options={Object.keys(allCountries)}
				onChange={(e, newValue) => setCountry(newValue)}
				renderInput={(params) => <TextField {...params} label={t('setup.your-information.country-label')} />}
			/>
		</div>
		<LoadingButton className='tas' color="inherit" sx={{ color: "white" }} loading={loading} onClick={() => {
			saveInformation().then(resp => {
				if (resp === null) return

				setLoading(false)
				if (resp) onNext()
			})
		}}>{t('setup.next')}</LoadingButton>
	</div >
}

function PriceDisplay({ price, currency, period }) {
	const { t } = useTranslation(['pricing']);

	return <div className="service-tier-price">
		<span className="price">${price}</span>
		{period && <span>/{t(`paid-${period}`)}</span>}
	</div>
}

function SelectPlanSection({ onNext }) {
	const [period, setPeriod] = useState("monthly")
	const [loadingPremium, setLoadingPremium] = useState(false)
	const [loadingStandard, setLoadingStandard] = useState(false)
	const { t } = useTranslation('pricing');

	function selectPlan(plan) {
		fetch(config.url.API + '/plan', {
			method: "POST",
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ plan, period }),
			credentials: "include"
		}).then(resp => {
			if (resp.status === 200) {
				onNext(plan)
			} else {
				toast.warn("Something went wrong. Please contact the team at support@takeaseat.io")
			}
		})
	}

	return <div className={"setup-page-content pricing-page"}>
		<div className={"page-title"}>{t('title')}</div>
		<div style={{ textAlign: "center", marginTop: "-1rem", marginBottom: "1.5rem" }}>{t('desc')}</div>
		<div style={{ marginBottom: "1.5rem", textAlign: "center" }}>
			<ToggleButtonGroup
				color="primary"
				value={period}
				exclusive
				onChange={(e, selectedPeriod) => setPeriod(selectedPeriod)}
			>
				<ToggleButton value="monthly">{t('monthly')}</ToggleButton>
				<ToggleButton value="yearly">{t('yearly')}&#160;&#160;<b>-50%</b></ToggleButton>
			</ToggleButtonGroup>
		</div>
		<div className='plans'>
			<div>
				<div className={"service-tier starter " + period}>
					<div className="title">{t('ads.title')}</div>
					<PriceDisplay price={
						period === "monthly" ? 0 :
							period === "yearly" ? 0 : 0
					} currency="USD" period={''} />
					{/* <div className="service-tier-desc">7 days free trial</div> */}
					<div className="highlights">
						<div><CheckCircle />{t('free.highlights.0')}</div>
						<div><CheckCircle />{t('free.highlights.1')}</div>
						<div><CheckCircle />{t('free.highlights.2')}</div>
						<div><CheckCircle />{t('free.highlights.3')}</div>
						<div><CheckCircle />{t('free.highlights.4')}</div>
						<div style={{ color: 'var(--tas-primary-color, #2cb052)' }}><CheckCircle />{t('ads.show')}</div>
						<LoadingButton className='tas' color="inherit" sx={{ fontFamily: "inherit", color: "white", marginTop: "1.5rem" }} loading={loadingStandard} onClick={() => selectPlan("ads")}>{t('select')}</LoadingButton>
					</div>
				</div>
			</div>
			<div>
				<div className={"service-tier starter " + period}>
					<div className="title">{t('free.title')}</div>
					<PriceDisplay price={
						period === "monthly" ? 8.9 :
							period === "yearly" ? 4.45 : 4.45
					} currency="USD" period={period} />
					{/* <div className="service-tier-desc">7 days free trial</div> */}
					<div className="highlights">
						<div><CheckCircle />{t('free.highlights.0')}</div>
						<div><CheckCircle />{t('free.highlights.1')}</div>
						<div><CheckCircle />{t('free.highlights.2')}</div>
						<div><CheckCircle />{t('free.highlights.3')}</div>
						<div><CheckCircle />{t('free.highlights.4')}</div>
						<div style={{ color: 'var(--tas-primary-color, #2cb052)' }}><CheckCircle />{t('trial')}</div>
						<LoadingButton className='tas' color="inherit" sx={{ fontFamily: "inherit", color: "white", marginTop: "1.5rem" }} loading={loadingStandard} onClick={() => {
							setLoadingStandard(true)
							selectPlan("standard")
						}}>{t('select')}</LoadingButton>
					</div>
				</div>
			</div>
			<div>
				<div className={"service-tier premium " + period}>
					<div className="title">{t('premium.title')}</div>
					<PriceDisplay price={
						period === "monthly" ? 29.9 :
							period === "yearly" ? 14.95 : 14.95
					} currency="USD" period={period} />
					{/* <div className="service-tier-desc">{t('premium.desc')}</div> */}
					<div className="highlights">
						<div><CheckCircle />{t('premium.highlights.0')}</div>
						<div><CheckCircle />{t('premium.highlights.3')}</div>
						<div><CheckCircle />{t('premium.highlights.4')}</div>
						<div><CheckCircle />{t('premium.highlights.1')}</div>
						<div><CheckCircle />{t('premium.highlights.2')}</div>
						<div style={{ color: 'var(--tas-pro-color, #eabb2b)' }}><CheckCircle />{t('trial')}</div>
					</div>
					<LoadingButton className='tas pro' color="inherit" sx={{ fontFamily: "inherit", color: "white", marginTop: "1.5rem" }} loading={loadingPremium} onClick={() => {
						setLoadingPremium(true)
						selectPlan("gold")
					}}>{t('select')}</LoadingButton>
				</div>
			</div>
		</div>
	</div>
}

function SetupPage({ refreshMetadata, prebuilt, onLogout, metadata }) {
	const [setupStepIndex, setSetupStepIndex] = useState(0)
	const [setupStep, setSetupStep] = useState(null)

	const setupSteps = ['info', 'select-plan']

	useEffect(() => {
		setSetupStep(setupSteps[setupStepIndex])
	}, [setupStepIndex])

	useEffect(() => {
		if (window.sendEvent) window.sendEvent(`navigation`, `setup_mount`)
		posthog.capture('my event', { property: 'setup_mount' })
	}, [])

	return <div id="setup-page">  {/** TODO: delete css no longer used*/}
		<div id='logout-button-container'>
			<button onClick={() => {
				if (window.sendEvent) window.sendEvent(`navigation`, `logout`) // TODO: Check if gets stored
				logout(onLogout)
			}} title="Logout">
				<LogoutIcon />
			</button>
		</div>
		{setupStep === 'info' &&
			<InfoSection metadata={{ ...metadata, "theme": prebuilt ? "custom" : "" }} refreshMetadata={refreshMetadata} defaultLoading={false} onNext={() => setSetupStepIndex(setupStepIndex + 1)} />
		}
		{setupStep === 'select-plan' &&
			<SelectPlanSection onNext={(selectedPlan) => {
				window.gtag('event', 'setup_finished');
				if (selectedPlan === "ads") window.gtag('event', 'conversion', { 'send_to': 'AW-642020249/ykZJCNa038sCEJnnkbIC' })
				else if (selectedPlan === "standard") window.gtag('event', 'conversion', { 'send_to': 'AW-642020249/Oma2CLLf0e4YEJnnkbIC' })
				else if (selectedPlan === "gold") window.gtag('event', 'conversion', { 'send_to': 'AW-642020249/WE0-CLXf0e4YEJnnkbIC' })
				else window.gtag('event', 'conversion', { 'send_to': 'AW-642020249/tDCVCJzD0e4YEJnnkbIC' })

				if (window.sendEvent) {
					window.sendEvent("complete_onboarding_step_3", "")
					window.sendEvent("onboarding_completed", "")
				}

				const newMetadata = {
					...metadata,
					ads: selectedPlan === 'ads', // TODO: use enum
					setup: "completed",
					menus: prebuilt ? {
						default: {
							label: "Menu 1",
							supportedLanguages: [{ "name": "English", "code": "en" }],
						}
					} : {},
					activeMenu: prebuilt ? "default" : ""
				}
				saveMetadata({ restaurantId: metadata.id, metadata: newMetadata }).then(resp => {
					if (resp.status === 200) {
						refreshMetadata(newMetadata)
					} else {
						toast.warn("Something went wrong. Please contact us at support@takeaseat.io")
					}
				})
			}} />
		}
		<div className='copyright'>Take a Seat© 2023</div>
	</div>
}

export default SetupPage;
