import { config } from "../Constants"

export function markSetupDone({ solution }) {
    return fetch(config.url.API + '/solutions/setup/completed', {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify({ solution })
    })
}

export function markOnboardingDone() {
    return fetch(config.url.API + '/setup/completed', {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: "include",
    })
}

export async function checkConnectivity() {
    const response = await fetch("/static/version.json", {
        method: "GET",
        signal: AbortSignal.timeout(5000)
    }).catch(error => {
        console.error("error", error)
        return new Response(
            "Network issue",
            {
                status: 400,
            }
        )
    })

    if (response.status === 200) return true
    else return false
}

export const TasLoader = () => {
    return <div className={"splash-screen"}>
        <svg width="22.447mm" height="17.909mm" version="1.1" viewBox="0 0 22.447 17.909" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(-110.57 -60.892)" fill="none">
                <g transform="matrix(1.269 0 0 1.269 -11.238 -118.66)" strokeWidth=".5">
                    <path stroke="#2CB052" d="m96.599 143.28c2.4528-0.51162 4.5756-1.3847 7.3584-1.5349l1.8725 0.0183c2.019 0.12141 4.8933 1.0189 7.2593 1.5207l-8.2178 1.933z" />
                    <path stroke="#0098FF" d="m96.251 144.01 8.2532 2.0071 0.0168 9.2449-2.1118-0.69584c-0.14624-0.0904-0.31129-0.1-0.3648-0.58862l-0.0239-5.9557-4.2732-1.075c-0.96928-0.53789-1.5987-1.3944-1.4963-2.9368z" />
                    <path stroke="#EF7902" d="m113.42 143.98-8.2532 2.0071-0.0168 9.2449 2.1118-0.69584c0.14625-0.0904 0.3113-0.1 0.3648-0.58862l0.0239-5.9557 4.2732-1.075c0.96928-0.53789 1.5987-1.3944 1.4963-2.9368z" />
                </g>
            </g>
        </svg>
    </div>
}