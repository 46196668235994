import React, { Component, useEffect, useState } from 'react';
import { config } from './Constants'
import { withTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

// todo: redirect or navigate?

function EmailVerificationPage({ onValidate }) {
	const [noAccountToVerify, setNoAccountToVerify] = useState(false)
	const { verificationCode } = useParams();
	const navigate = useNavigate();


	useEffect(() => {
		fetch(`${config.url.API}/register/verify`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			credentials: "include",
			body: JSON.stringify({ verificationCode })
		}).then(resp => {
			if (resp.status === 200) {
				window.gtag('event', 'conversion', { 'send_to': 'AW-642020249/4zT1CPaWhcwCEJnnkbIC' });
				return resp.json();
			} else if (resp.status === 400) {
				setNoAccountToVerify(true)
			} else {
				alert("failed to validate code: " + resp);
			}
		}).then(resp => {
			if (resp) {
				window._env_.GA_CONFIGS.forEach((c) => {
					window.gtag("config", c, { 'user_id': resp.ga_user });
				});
				window.gtag('event', 'verify_email');
				// The true sign_up event (conversion event) happens after verification of the email.
				window.gtag('event', 'sign_up');
				onValidate(resp.id, resp.lang, resp.prebuilt);
			}
		});
	}, [])

	useEffect(() => {
		if (noAccountToVerify) {
			return navigate('/login')
		}
	}, [noAccountToVerify])
}

const EmailVerificationPageTranslated = withTranslation('emailverification')(EmailVerificationPage)

export default EmailVerificationPageTranslated;
