import React, { Component, useState, useEffect } from 'react';
import { config } from '../Constants';
import _ from 'lodash'; // optimize import
import { useTranslation, withTranslation } from "react-i18next";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { loadAddress, loadTelephone, loadSocialAccounts, loadBusinesses } from '../utils/LoadInformation';
import { countries } from 'countries-list';
import { v4 as uuidv4 } from 'uuid';
import { PremiumDisclaimer, ContactUsDisclaimer, ComparePlans } from '../utils/Disclaimers'
import { EditAndSaveButton } from '../utils/Data';
import { PricingModal } from '../utils/Disclaimers'
import { getJsonData, putJsonData, queryJsonData } from '../utils/MenuUtils'
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import LanguageIcon from '@mui/icons-material/Language';
import UploadIcon from '@mui/icons-material/Upload';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { uploadLogo } from '../utils/Images';
import './BusinessInformation.scss'
import { Box, CustomTextField, TasLoadingButton } from '../CustomComponents/AllPurpose';
import { InputAdornment } from '@mui/material';
import { Add, ContentCopy } from '@mui/icons-material';
import { toast } from 'react-toastify';

function SaveBox({ onSave, onCancel }) {
  const [cancelling, setCancelling] = useState(false)
  const [saving, setSaving] = useState(false)
  const { t } = useTranslation('app');

  return <div style={{ display: "flex", gap: "1rem" }}>
    <TasLoadingButton className="white fullwidth" loading={cancelling} onClick={() => {
      setCancelling(true)

      onCancel(success => {
        setCancelling(false)
        if (!success) toast.error("Error")
      })
    }} label={t('edit.cancel')} />
    <TasLoadingButton className="fullwidth" loading={saving} onClick={() => {
      setSaving(true)

      onSave((success) => {
        setSaving(false)
        if (!success) toast.error("Error")
      })
    }} label={t("edit.save")} />
  </div>
}

function NameAndLogo({
  defaultName,
  updateMetadata,
  metadata,
  renewCredentials
}) {

  const [name, setName] = useState(defaultName)
  const [_, setUploading] = useState(false) // TODO: use uploading
  const [intro, setIntro] = useState("")// TODO: ave
  const [editing, setEditing] = useState(false)
  const [logoSrc, setLogoSrc] = useState(metadata.logo?.src || '')
  const { t } = useTranslation('app');

  function successUpload(filename) {
    updateMetadata({ ...metadata, logo: { src: filename, alt: "Logo" } });
    setLogoSrc(filename)
    setUploading(false)
  }

  function failedUpload() {
    setUploading(false)
    toast.error("Could not upload image")
  }

  useEffect(() => {
    queryJsonData({
      url: config.url.API + config.endpoints['business-introduction'],
      verb: 'GET',
      renewCredentials
    }).then(resp => {
      if (resp.status === 200) {
        resp.json().then(setIntro)
      }
    })
  }, [])

  return <Box>
    <div className='box-title'>{t('identity.business-details.title')}</div>
    <div>{t('identity.business-details.desc')}</div>
    <p id="logo-edit">
      {logoSrc && <img style={{ width: "5rem", maxHeight: "3.2rem" }} src={`${config.url.PUBLIC}/restaurants/${metadata.id}/${logoSrc}`} alt={`Logo not found.`} />}
      <label htmlFor="upload-logo" className='tas white' id="upload-logo-label">
        <UploadIcon /> {t("identity.logo.upload")}
      </label>
      <input
        id="upload-logo"
        type="file"
        accept="image/*"
        onChange={(e) => {
          setUploading(true)
          uploadLogo(e, metadata.id, successUpload, failedUpload)
        }} />
    </p>
    <p>
      <label><b>{t('identity.business-details.name.label')}</b></label>
      <CustomTextField
        fullWidth
        sx={{ marginTop: "0.25rem" }}
        size='small'
        label={null}
        placeholder={t('identity.business-details.name.placeholder')}
        value={name}
        onChange={e => {
          setName(e.target.value)
          setEditing(true)
        }}
      />
    </p>
    <p>
      <label><b>{t('identity.business-details.intro.label')}</b></label>
      <CustomTextField
        fullWidth
        sx={{ marginTop: "0.25rem" }}
        size='small'
        multiline
        rows={5}
        label={null}
        placeholder={t('identity.business-details.intro.placeholder')}
        value={intro}
        onChange={e => {
          setIntro(e.target.value)
          setEditing(true)
        }}
      />
    </p>
    {editing && <SaveBox
      onSave={(callback) => {
        Promise.all([
          queryJsonData({
            url: config.url.API + config.endpoints['business-name'],
            body: { name },
            verb: "PATCH"
          }),
          queryJsonData({
            url: config.url.API + config.endpoints['business-introduction'],
            body: { introduction: intro },
            verb: "PATCH"
          })
        ]).then(responses => {
          console.log("responses", responses)

          if (responses.some(resp => resp.status !== 200)) {
            callback(false)
          } else {
            callback(true)
            setEditing(false)
            updateMetadata({ ...metadata, name });
          }
        })
          .catch((error) => {
            callback(false)
          });
      }}
      onCancel={(callback) => {
        callback(true)
        setEditing(false)
        setName(defaultName)
        return true
      }} />}
  </Box >
}

function YourURLs({ defaultAlias, restaurantId, onSave, tier, customDomain }) {
  const [editing, setEditing] = useState(false)
  const [alias, setAlias] = useState(defaultAlias)
  const [showPremiumDisclaimer, setShowPremiumDisclaimer] = useState(false)
  const [showContactUs, setShowContactUs] = useState(false)
  const { t } = useTranslation('app');

  function changeAlias(e) {
    setAlias(e.target.value.toLowerCase().trimStart().replace(/[  ]/ig, "-").replace(/[^a-z\-0-9]/ig, "-").replace(/-+/ig, '-'))
  }

  const businessDomain = customDomain ? `https://${customDomain}` : config.url.SITES.replace('$1', alias || restaurantId)

  return <>
    <Box style={{
      display: "flex",
      flexDirection: "column",
    }}>
      <div className='box-title'>{t('identity.urls.title')}</div>
      <div>{t('identity.urls.desc')}</div>
      <p style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <CustomTextField
          onFocus={() => setEditing(true)}
          onChange={changeAlias}
          size="small"
          value={editing ? (customDomain || alias) : businessDomain}
          InputProps={{
            startAdornment: <InputAdornment position="start"><LanguageIcon /></InputAdornment>,
            endAdornment: <InputAdornment position="end" sx={{ cursor: "pointer" }} onClick={() => {
              navigator.clipboard.writeText(customDomain ? `https://${customDomain}` : config.url.SITES.replace('$1', alias || restaurantId));
              toast.success(t("general.link-copied"))
            }}><ContentCopy /></InputAdornment>,
          }} />
        <CustomTextField
          size="small"
          onFocus={() => setEditing(true)}
          onChange={changeAlias}
          value={editing ? (customDomain || alias) : (businessDomain + '/menu')}
          InputProps={{
            startAdornment: <InputAdornment position="start"><RestaurantMenuIcon /></InputAdornment>,
            endAdornment: <InputAdornment position="end" sx={{ cursor: "pointer" }} onClick={() => {
              navigator.clipboard.writeText(customDomain ? `https://${customDomain}/menu` : (config.url.SITES.replace('$1', alias || restaurantId) + '/menu'));
              toast.success(t("general.link-copied"))
            }}><ContentCopy /></InputAdornment>,
          }} />
        <CustomTextField
          size="small"
          onFocus={() => setEditing(true)}
          onChange={changeAlias}
          value={editing ? (customDomain || alias) : (businessDomain + '/reservation')}
          InputProps={{
            startAdornment: <InputAdornment position="start"><TableRestaurantIcon /></InputAdornment>,
            endAdornment: <InputAdornment position="end" sx={{ cursor: "pointer" }} onClick={() => {
              navigator.clipboard.writeText(customDomain ? `https://${customDomain}/reservation` : (config.url.SITES.replace('$1', alias || restaurantId) + '/reservation'));
              toast.success(t("general.link-copied"))
            }}><ContentCopy /></InputAdornment>,
          }} />
        {!customDomain ? <div>
          <div
            style={{ padding: "0.5rem 1rem" }}
            className="tas-button outline borderless"
            onClick={() => tier === "gold" ? setShowContactUs(true) : setShowPremiumDisclaimer(true)}>
            <span>
              <Add /> {t('identity.urls.own-domain')}
            </span>
          </div>
        </div>
          : <div>{customDomain}</div>}
      </p>
      {showPremiumDisclaimer && <PremiumDisclaimer closeCallback={() => setShowPremiumDisclaimer(false)} />}
      {showContactUs && <ContactUsDisclaimer closeCallback={() => setShowContactUs(false)} />}
      {editing && <SaveBox
        onSave={(callback) => {
          onSave(alias).then(resp => {
            if (resp.status === 200) {
              setEditing(false)
              callback(true)
            } else {
              callback(false)
            }
          })
        }}
        onCancel={(callback) => {
          callback(true)
          setEditing(false)
          setAlias(defaultAlias)

          document.getElementById("alias-already-taken").style.display = "none"; // TODO: refactor
          document.getElementById("alias-invalid").style.display = "none";
          return true
        }} />}
      <div>
        <div id="alias-invalid" style={{ color: "red", fontStyle: "italic", display: "none" }}>{t('identity.urls.error.invalid')}</div>
        <div id="alias-already-taken" style={{ color: "red", fontStyle: "italic", display: "none" }}>{t('identity.urls.error.duplicated')}</div>
      </div>
    </Box>
  </>
}

function YourAddress({ defaultTelephone, labelsToCode, defaultStreet, defaultStreetAdditional, defaultPostalCode, defaultCity, defaultCountry, renewCredentials }) {
  const [editing, setEditing] = useState(false)
  const [street, setStreet] = useState(defaultStreet)
  const [street_additional, setStreetAdditional] = useState(defaultStreetAdditional)
  const [postal_code, setPostalCode] = useState(defaultPostalCode)
  const [city, setCity] = useState(defaultCity)
  const [country, setCountry] = useState(defaultCountry)
  const [telephone, setTelephone] = useState(defaultTelephone)
  const { t } = useTranslation('app');

  return <Box>
    <div className='box-title'>{t('identity.address.title')}</div>
    <p>
      <label><b>{t('identity.address.country.placeholder')}</b></label>
      <CustomTextField
        fullWidth
        sx={{ marginTop: "0.25rem" }}
        size='small'
        label={null}
        placeholder={t('identity.address.country.placeholder')}
        value={country}
        onChange={e => {
          setEditing(true)
          setCountry(e.target.value)
        }} />
    </p>
    <p style={{ display: "flex", gap: "1rem" }}>
      <div style={{ flexGrow: "1" }}>
        <label><b>{t('identity.address.city.placeholder')}</b></label>
        <CustomTextField
          fullWidth
          sx={{ marginTop: "0.25rem" }}
          size='small'
          label={null}
          placeholder={t('identity.address.city.placeholder')}
          value={city}
          onChange={e => {
            setEditing(true)
            setCity(e.target.value)
          }} />
      </div>
      <div style={{ flexGrow: "1" }}>
        <label><b>{t('identity.address.zipcode.placeholder')}</b></label>
        <CustomTextField
          fullWidth
          sx={{ marginTop: "0.25rem" }}
          size='small'
          label={null}
          placeholder={t('identity.address.zipcode.placeholder')}
          value={postal_code}
          onChange={e => {
            setEditing(true)
            setPostalCode(e.target.value)
          }} />
      </div>
    </p>
    <p>
      <label><b>{t('identity.address.line1.placeholder')}</b></label>
      <CustomTextField
        fullWidth
        sx={{ marginTop: "0.25rem" }}
        size='small'
        label={null}
        placeholder={t('identity.address.line1.placeholder')}
        value={street}
        onChange={e => {
          setEditing(true)
          setStreet(e.target.value)
        }} />
    </p>
    <p>
      <label><b>{t('identity.address.line2.placeholder')}</b></label>
      <CustomTextField
        fullWidth
        sx={{ marginTop: "0.25rem" }}
        size='small'
        label={null}
        placeholder={t('identity.address.line2.placeholder')}
        value={street_additional}
        onChange={e => {
          setEditing(true)
          setStreetAdditional(e.target.value)
        }} />
    </p>
    <div style={{ display: "flex", marginBottom: "0.5rem" }}>
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry={(country) ? labelsToCode[country] || '' : "CH"}
        placeholder={t('setup.your-information.telephone-label')}
        value={telephone}
        onChange={(value) => {
          setEditing(true)
          setTelephone(value)
        }}
      />
    </div>
    {editing && <SaveBox
      onSave={(callback) => {
        queryJsonData({
          url: config.url.API + config.endpoints['business-address'],
          verb: "PATCH",
          body: { telephone, street, street_additional, postal_code, city, country },
          renewCredentials
        })
          .then(resp => {
            if (resp.status === 200) {
              setEditing(false)
              callback(true)
            } else {
              callback(false)
            }
          })
          .catch((error) => {
            callback(false)
          });
      }}
      onCancel={(callback) => {
        callback(true)
        setEditing(false)
        setStreet(defaultStreet)
        setStreetAdditional(defaultStreetAdditional)
        setPostalCode(defaultPostalCode)
        setCity(defaultCity)
        setCountry(defaultCountry)
        setTelephone(defaultTelephone)
      }} />}
  </Box>
}


const Plan = ({ tier, hasPlan }) => {
  const { t } = useTranslation('app');

  return <Box style={{
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    flexWrap: "wrap"
  }}>
    <div style={{ fontSize: "125%" }}>
      <b>{t('identity.plan.current')} </b>
      {(tier === 'gold') && <span style={{ color: "#eabb2b" }}><b>{t('identity.plan.enabled')}</b></span>}
      {(tier === 'standard') && <b>{t('identity.plan.basic')}</b>}
      {(tier === 'ads') && <b>{t('identity.plan.ads')}</b>}
    </div>
    {hasPlan ?
      (tier !== "ads" && <div className="tas-button" onClick={() => {
        fetch(`${config.url.API}/stripe/customer-portal-session`, {
          method: 'post',
          headers: {
            'Accept': 'application/json'
          },
          credentials: "include",
          body: {}
        }).then(resp => {
          if (resp.status === 403) {
            this.props.renewCredentials();
          } else if (resp.status === 200) {
            resp.json().then(r => window.location.href = r.url);
          }
        })
      }}><span>{t('identity.plan.manage')}</span></div>
      ) :
      <div><b>{t('identity.plan.trial')}</b></div>}
    <div style={{ marginLeft: "auto" }}><ComparePlans /></div>
  </Box>
}

function saveUser({ user }) {
  // TODO: pass renewCredentials
  return putJsonData(`${config.url.API}/user`, user)
}

function updateUser({ user }) {
  // TODO: pass renewCredentials ; put in utils
  return fetch(`${config.url.API}/user`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: "include",
    body: JSON.stringify(user)
  })
}

function deleteUser({ user }) {
  // TODO: pass renewCredentials
  return fetch(`${config.url.API}/user`, {
    method: 'delete',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: "include",
    body: JSON.stringify({ email: user.email })
  }).then(resp => {
    if (resp.status === 403) {
      //if (renewCredentials) renewCredentials() // TODO
      return resp
    } else {
      return resp
    }
  })
}

function UserRow({ user, isLast, addUser, onDelete }) {
  const [mode, setMode] = useState("delete")
  const [hasFullAdminAccess, setHasFullAdminAccess] = useState(user.full_admin_access)
  const [hasOrdersAccess, setHasOrdersAccess] = useState(user.orders_access)

  return <tr>
    <td className="email" title={user.email}> {user.email} </td>
    <td>
      <Checkbox
        className="not-changeable"
        checked={user.owner}
        onChange={() => { }}
      />
    </td>
    <td>
      <Checkbox
        className={user.owner ? "not-changeable" : ""}
        checked={hasFullAdminAccess}
        onChange={(e) => {
          if (user.owner) return

          const newCheck = e.target.checked
          user.full_admin_access = newCheck
          setMode("edit")
          setHasFullAdminAccess(newCheck)
          if (newCheck) setHasOrdersAccess(true)
        }}
      />
    </td>
    <td>
      <Checkbox
        className={(user.owner || hasFullAdminAccess) ? "not-changeable" : ""}
        checked={hasOrdersAccess}
        onChange={(e) => {
          if (user.owner || hasFullAdminAccess) return

          user.orders_access = e.target.checked
          setMode("edit")
          setHasOrdersAccess(e.target.checked)
        }}
      />
    </td>
    <td>{user.creation_time.slice(0, 10)}</td>
    <td>{!user.owner && <EditAndSaveButton mode={mode}
      onSave={() => { updateUser({ user }).then((resp) => { if (resp.status === 200) setMode("delete") }) }}
      onDelete={() => deleteUser({ user }).then(resp => {
        if (resp.status === 200) onDelete()
      })} />}</td>
    <td>{isLast && <AddCircleIcon
      className="clickable"
      onClick={() => addUser()}
    />}</td>
  </tr>
}

function UserModal({ user, defaultShowModal, onSave }) {
  const [showModal, setShowModal] = useState(defaultShowModal);
  const [hasFullAdminAccess, setHasFullAdminAccess] = useState(user.full_admin_access)
  const [hasOrdersAccess, setHasOrdersAccess] = useState(user.orders_access)

  const handleClose = () => {
    setShowModal(false);
  };

  return <Modal
    open={showModal}
    onClose={handleClose}
    className={"modal-view user-modal"}
  >
    <div className="modal-content" style={{ background: "white" }}>
      <div>
        <div className="field">
          <div>Email:</div>
          <div style={{ width: "calc(100% - 7rem)" }}>
            <input type="email" placeholder="email" onChange={(e) => user.email = e.target.value} />
          </div>
        </div>
        <div className="field">
          <div>Password:</div>
          <div style={{ width: "calc(100% - 7rem)" }}>
            <input type="text" placeholder="password" onChange={(e) => user.password = e.target.value} />
          </div>
        </div>
        <div className="field">
          <div>Has full admin access:</div>
          <div>
            <Checkbox
              checked={hasFullAdminAccess}
              onChange={(e) => {
                const newCheck = e.target.checked
                user.full_admin_access = newCheck;
                setHasFullAdminAccess(newCheck)
                if (newCheck) {
                  user.orders_access = true
                  setHasOrdersAccess(true)
                }
              }}
            />
          </div>
        </div>
        <div>
          Has access to orders:
          <Checkbox
            checked={hasOrdersAccess}
            onChange={(e) => {
              if (hasFullAdminAccess) {
                setHasOrdersAccess(true)
              } else {
                user.orders_access = e.target.checked;
                setHasOrdersAccess(e.target.checked)
              }
            }}
          />
        </div>
      </div>
      <div>
        <div className="tas-button" onClick={() => {
          saveUser({ user }).then(() => { onSave(user); handleClose(); });
        }} style={{ marginInline: "auto", marginTop: "1rem" }}>Save</div>
      </div>
    </div>
  </Modal >
}

function Users({ tier }) {
  const [users, setUsers] = useState([])
  // const [userEmail, setUserEmail] = useState("")
  const [comparePlans, setComparePlans] = useState(false)
  const [modalUuid, setModalUuid] = useState(null)
  const { t } = useTranslation('app');

  useEffect(() => {
    // getJsonData(`${config.url.API}/user/email`).then(jsonData => {
    //   setUserEmail(jsonData.email || "Something went wrong")
    // })

    if (tier === "gold") getJsonData(`${config.url.API}/users`).then(jsonData => {
      if (Object.keys(jsonData).length === 0) {
        // tODO: better handle of 403 error
        setUsers([])
      } else {
        setUsers(jsonData)
      }
    })
  }, [])

  return <div>
    <div style={{ margin: "1rem 0.5rem 0.5rem" }}><b>{t('user-access')}</b></div>
    <Box style={{ margin: "0.5rem" }}>
      {(tier === 'gold') ? <table className="users-table">
        <thead>
          <tr>
            <th>{t('general.email')}</th>
            <th>{t('owner')}</th>
            <th>{t('full-access')}</th>
            <th>{t('sidebar.orders-t')}</th>
            <th>{t('added-on')}</th>
            <th style={{ width: "2rem" }}></th>
            <th style={{ width: "2rem" }}></th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, i) => <UserRow
            key={`${user.email}`}
            user={user}
            isLast={i === users.length - 1}
            addUser={() => setModalUuid(uuidv4())}
            onDelete={() => {
              users.splice(i, 1)
              setUsers([...users])
            }}
          />)}
        </tbody>
      </table> : <>
        <div className="tas-button locked" onClick={() => setComparePlans(uuidv4())}><span>{t('add-collab')}</span></div>
      </>
      }
      {modalUuid && <UserModal
        key={modalUuid}
        defaultShowModal={true}
        user={{ owner: false, full_admin_access: false, orders_access: false, creation_time: "" }}
        onSave={(user) => setUsers([...users, user])} />}
      {comparePlans && <PricingModal defaultShowModal={comparePlans} key={comparePlans} />}
    </Box>
  </div>
}

function YourSocialAccounts({ defaultFacebook, defaultTwitter, defaultInstagram, renewCredentials }) {
  const [facebook, setFacebook] = useState(defaultFacebook)
  const [instagram, setInstagram] = useState(defaultInstagram)
  const [twitter, setTwitter] = useState(defaultTwitter)
  const [editing, setEditing] = useState(false)

  const { t } = useTranslation('app');

  return <Box>
    <div className='box-title'><b>{t('setup.your-information.social-label')}</b></div>
    {t('identity.address.line2.placeholder')}
    <p>
      <CustomTextField
        fullWidth
        sx={{ marginTop: "0.25rem" }}
        size='small'
        label={null}
        placeholder="Facebook"
        value={facebook}
        onChange={e => {
          setFacebook(e.target.value)
          setEditing(true)
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start" sx={{ color: "#121212" }}><FacebookIcon /></InputAdornment>,
        }} />
    </p>
    <p>
      <CustomTextField
        fullWidth
        sx={{ marginTop: "0.25rem" }}
        size='small'
        label={null}
        placeholder="Instagram"
        value={instagram}
        onChange={e => {
          setEditing(true)
          setInstagram(e.target.value)
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start" sx={{ color: "#121212" }}><InstagramIcon /></InputAdornment>,
        }} />
    </p>
    <p>
      <CustomTextField
        fullWidth
        sx={{ marginTop: "0.25rem" }}
        size='small'
        label={null}
        placeholder="Twitter"
        value={twitter}
        onChange={e => {
          setEditing(true)
          setTwitter(e.target.value)
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start" sx={{ color: "#121212" }}><TwitterIcon /></InputAdornment>,
        }} />
    </p>
    {editing && <SaveBox
      onSave={(callback) => {
        queryJsonData({
          url: config.url.API + config.endpoints['business-socials'],
          verb: "PATCH",
          body: { facebook, twitter, instagram },
          renewCredentials
        })
          .then(resp => {
            if (resp.status === 200) {
              setEditing(false)
              callback(true)
            } else {
              callback(false)
            }
          })
          .catch((error) => {
            callback(false)
          });
      }}
      onCancel={(callback) => {
        callback(true)
        setEditing(false)
        setFacebook(defaultFacebook)
        setInstagram(defaultInstagram)
        setTwitter(defaultTwitter)
      }} />}
  </Box >
}

class IdentityView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metadata: props.metadata,
      address: {
        street: "",
        street_additional: "",
        postal_code: "",
        city: "",
        country: ""
      },
      telephone: "",
      newModifications: false,
      progress: null,
      inFocus: '',
      setAlias: false,
      keyAddress: uuidv4(),
      saved: true,
      addAccountModal: false,
      businesses: [],
      newBusinessRequest: false
    }

    this.infoToSave = {}
    this.loadData = this.loadData.bind(this);
    this.updateAlias = this.updateAlias.bind(this);
    this.labelsToCode = this.countryLabelsToCode(); // TODO: refactor with the possibility to have labels for the options
  }

  componentDidMount() {
    this.loadData();
  }

  countryLabelsToCode() {
    var dict = []

    Object.entries(countries).forEach(([countryCode, countryDetails]) => {
      dict[`${countryDetails["name"]} / ${countryDetails["native"]}`.toString()] = countryCode
    })

    return dict;
  }

  async loadData() {
    loadAddress().then(
      address => this.setState({ address, keyAddress: uuidv4() })
    )

    loadTelephone().then(
      telephone => this.setState({ telephone, keyAddress: uuidv4() })
    )

    loadBusinesses().then(resp => {
      if (resp.status === 200) {
        resp.json().then(data => this.setState({ businesses: data }))
      } else {
        this.setState({ businesses: [] })
      }
    })

    loadSocialAccounts().then(resp => {
      if (resp.status === 200) resp.json().then(data => {
        this.setState({
          facebook: data["facebook"],
          twitter: data["twitter"],
          instagram: data["instagram"],
          socialKeys: uuidv4()
        })
      })
    })
  }

  updateAlias = (value) => {
    // We remove the error messages
    document.getElementById("alias-already-taken").style.display = "none";
    document.getElementById("alias-invalid").style.display = "none";

    // We try to set the new alias
    return fetch(`${config.url.API}/metadata/${this.props.restaurantId}/alias`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include",
      body: JSON.stringify({ alias: value })
    }).then(res => {
      if (res.status == 200) {
        // Update metadata to include the alias. Metadata file has been updated by the backend
        this.state.metadata.alias = value
        this.props.updateMetadataWithoutSaving(this.state.metadata)
      } else if (res.status == 400) {
        res.json().then(function (x) {
          if (x.reason == "duplicated_alias") {
            document.getElementById("alias-already-taken").style.display = "block";
          } else if (x.reason == "invalid_alias") {
            document.getElementById("alias-invalid").style.display = "block";
          }
        });
      } else if (res.status == 403) {
        this.props.renewCredentials()
      } else if (res.status >= 400) {
        console.log("Error returned by server: " + res.statusText);
      }
      return res
    });
  }

  updateMetadata(e, jsonPath) {
    const oldValue = _.get(this.state.metadata, jsonPath)  // In case updating metadata failed, we will revert the value
    _.set(this.state.metadata, jsonPath, e.target.value)
    this.props.updateMetadata(this.state.metadata).then(success => {
      if (success) {
        this.setState({})
      } else {
        _.set(this.state.metadata, jsonPath, oldValue)
        this.setState({})
      }
    })
  }

  render() {
    const { t } = this.props
    return <div>
      <Modal
        open={this.state.addAccountModal}
        onClose={() => this.setState({ addAccountModal: false })}
        className={"modal-view user-modal"}
      >
        <div className="modal-content" style={{ background: "white", width: "90%", maxWidth: "30rem" }}>
          <div>
            <div style={{ fontSize: "200%", textAlign: "center" }}>🚀</div>
            <div style={{ fontSize: "150%", textAlign: "center", margin: "0.5rem 0" }}>
              <b>{t('identity.add-account.0')}</b>
            </div>
          </div>
          <br />
          <div>{t('identity.add-account.1')}</div>
          <br />
          <div style={{ textAlign: "center" }}>
            <div className='tas-button' onClick={() => {

              if (this.state.newBusinessRequest) return

              this.setState({ newBusinessRequest: true })

              fetch(config.url.API + '/business/new', {
                method: "PUT",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                credentials: "include"
              }).then(resp => {
                if (resp.status === 200) {
                  window.location.reload()
                } else {
                  alert("Something went wrong. Please contact the team.")
                  this.setState({ newBusinessRequest: false })
                }
              })
            }}>{this.state.newBusinessRequest ? 'Loading...' : t('identity.add-account.2')}</div>
          </div>
        </div>
      </Modal>
      <div className="app-menu">
        <div className="first-row">
          {this.state.businesses?.length > 1 ? <FormControl >
            <InputLabel id="biz-select-label">{"Business name"}</InputLabel>
            <Select
              label={"Business name"}
              sx={{ background: "white", minWidth: "10rem" }}
              id="biz-select"
              value={this.props.restaurantId}
              size="small"
              onChange={(e) => {
                if (e.target.value && e.target.value != "new") {
                  fetch(`${config.url.API}/switch/${e.target.value}`, {
                    method: 'get',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                    },
                    credentials: "include"
                  }).then(resp => {
                    if (resp.status === 200) {
                      window.location.reload()
                    } else {
                      alert("Could not change account. Please contact team.")
                    }
                  })
                }
              }}
            >
              {this.state.businesses.map(business => {
                return <MenuItem value={business.id} key={`biz-${business.id}`}>
                  <div>{business.name}</div>
                </MenuItem>
              })}
              <MenuItem onClick={() => this.setState({ addAccountModal: true })} value={"new"} key={`biz-select-add`}>
                <div>{t('identity.add-biz')}</div>
              </MenuItem>
            </Select>
          </FormControl> :
            <button className='tas' onClick={() => this.setState({ addAccountModal: true })} >{t('identity.add-biz')}</button>}
        </div>
      </div>
      <PricingModal defaultShowModal={this.props.comparePlans} />
      <div className={"identity-content"}>
        <Plan restaurantId={this.props.restaurantId} tier={this.props.tier} hasPlan={this.props.hasPlan} />
        <div className='flex-row'>
          <NameAndLogo
            defaultName={this.props.name}
            metadata={this.state.metadata}
            updateMetadata={this.props.updateMetadata}
            renewCredentials={this.props.renewCredentials}
          />
          <YourURLs
            defaultAlias={this.state.metadata.alias}
            restaurantId={this.props.restaurantId}
            onSave={this.updateAlias}
            tier={this.props.tier}
            customDomain={this.props.customDomain}
          />
        </div>
        <YourAddress
          key={this.state.keyAddress}
          defaultStreet={this.state.address.street}
          defaultStreetAdditional={this.state.address.street_additional}
          defaultPostalCode={this.state.address.postal_code}
          defaultCity={this.state.address.city}
          defaultCountry={this.state.address.country}
          defaultTelephone={this.state.telephone}
          labelsToCode={this.labelsToCode}
          renewCredentials={this.props.renewCredentials}
        />
        <YourSocialAccounts
          key={this.state.socialKeys}
          defaultFacebook={this.state.facebook}
          defaultInstagram={this.state.instagram}
          defaultTwitter={this.state.twitter}
          renewCredentials={this.props.renewCredentials}
        />
        <Users tier={this.props.tier} />
      </div>
    </div>
  }
}

const IdentityViewTranslated = withTranslation('app')(IdentityView)

export default IdentityViewTranslated;
