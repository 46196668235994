import React, { Component, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import './Edit.css'
import CloudUpload from '@mui/icons-material/CloudUpload';
import { Switch } from '../CustomComponents/CustomSwitch'
import { uploadImageWithThumbnail } from '../utils/Images';
import Collapsible from 'react-collapsible';
import { putJsonData, queryJsonData } from '../utils/MenuUtils'
import { config } from '../Constants'
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import { loadAddress, loadEmail, loadTelephone } from '../utils/LoadInformation';
import { v4 as uuidv4 } from 'uuid';
import Popover from '@mui/material/Popover';
import { PremiumDisclaimer } from '../utils/Disclaimers'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { SaveButton } from '../utils/Data';
import PreviewView from './PreviewView';
import { MenuItem, Select, Typography } from '@mui/material';


export function FullscreenImage({ image, deleteCallback, closeCallback, t }) {
  /* PUT in utils */
  return <div className="fullscreen" style={{ display: "flex" }}>
    <div style={{ width: "100%", height: "80vh", position: "absolute", inset: "0", cursor: "pointer" }} onClick={closeCallback}></div>
    <div style={{ margin: "auto", zIndex: "1" }}>
      <img
        alt="Dish"
        style={{ margin: "auto", objectFit: "contain", width: "50rem", maxHeight: "80vh", maxWidth: "100%" }}
        src={image} />
      <div style={{ textAlign: "center" }}>
        <div style={{ margin: "1rem", display: "inline-block" }} className="tas-button" onClick={closeCallback}>{t('app:close')}</div>
        {deleteCallback && <div style={{ margin: "1rem", display: "inline-flex" }} className="tas-button" onClick={() => deleteCallback(image)}>
          <div>{t('app:edit.delete')}</div>
          <div style={{ marginLeft: "0.7rem" }}><DeleteIcon /></div>
        </div>}
      </div>
    </div>
  </div>
}

function HeroField({ defaultCheck, defaultImage, updateCallback, restaurantId, t, tier }) {

  // TODO: support alt for images
  const imageOptions = [
    "/images/hero/adam-jaime-dmkmrNptMpw-unsplash.jpg",
    "/images/hero/nathan-dumlao-6VhPY27jdps-unsplash.jpg",
    "/images/hero/jia-jia-shum-Fn8f-BYxVQs-unsplash.jpg",
    "/images/hero/jakub-dziubak-XtUd5SiX464-unsplash.jpg",
    "/images/hero/nordwood-themes-Tmz8FThN_BE-unsplash.jpg",
    "/images/hero/elaine-casap-qgHGDbbSNm8-unsplash.jpg",
    "/images/hero/wood-fired-oven-ge32e09c1e_1920.jpg",
    "/images/hero/wine-ga1c23bbb2_1920.jpg",
    "/images/hero/pizza-g58f4b1c42_1920.jpg"
  ]

  const [checked, setChecked] = useState(defaultCheck)
  const [uploading, setUploading] = useState(false)
  const [fullscreenImage, setFullscreenImage] = useState("")
  const [selectedImage, setImage] = useState(defaultImage)

  function uploadCallback(imageName) {
    setUploading(false)
    updateCallback("hero_image", `/restaurants/${restaurantId}/images/${imageName}`)
    setImage(`/restaurants/${restaurantId}/images/${imageName}`)
  }

  function thumbnailImageName(imageName) {
    var parts = imageName.split("/")
    parts[parts.length - 1] = "thumbnail-" + parts[parts.length - 1]

    return parts.join("/")
  }

  return <div className="field-container">
    {fullscreenImage && <FullscreenImage image={fullscreenImage} closeCallback={() => setFullscreenImage("")} t={t} />}
    <div className="inline-field title" style={{ position: "absolute", color: "rgba(0,0,0,0)" }}>{t('hero-field.title')}</div> {/* Hack to make text non clickable */}
    <Collapsible
      open={checked} /* TODO: not working very well */
      overflowWhenOpen="visible"
      onOpen={() => updateCallback("include_hero_image", true)} // Not a big fan of this
      onClose={() => updateCallback("include_hero_image", false)}
      trigger={
        <div className="inline-field title">
          <div>{t('hero-field.title')}</div>
          <Switch defaultChecked={checked} onClick={() => {
            const newCheck = !checked
            setChecked(newCheck)
          }}
            t={t} />
        </div>}
    >
      <>
        <div className="field" style={{ display: "block", margin: "1rem 0" }}>
          <div className="thumbnail">
            <div className="img" style={{
              background: "url(" + encodeURI(`${config.url.PUBLIC}${thumbnailImageName(selectedImage)}`).replace(/\(/ig, "%28").replace(/\)/ig, "%29") + ") center center / cover no-repeat"
            }}
              onClick={() => setFullscreenImage(`${config.url.PUBLIC}${selectedImage}`)}
            ></div>
          </div>
        </div>
        <div className="more-options">
          <div>
            <div style={{ padding: "0.5rem 0" }}>{t('hero-field.select')}</div>
            <div className="inline-field">
              {imageOptions.map(image => {
                return <div className={"thumbnail" + (selectedImage === image ? " selected" : "")}>
                  <div className="img" style={{
                    background: "url(" + encodeURI(`${config.url.PUBLIC}${thumbnailImageName(image)}`).replace(/\(/ig, "%28").replace(/\)/ig, "%29") + ") center center / cover no-repeat"
                  }}
                    onClick={() => {
                      setImage(image)
                      updateCallback("hero_image", image)
                    }}></div>
                </div>
              })}
              <div>
                <form id="heroForm">
                  <label className="thumbnail upload">
                    {uploading ?
                      <>Loading...</>
                      : <>
                        <input multiple type="file" accept="image/*" onChange={(e) => {
                          setUploading(true)
                          uploadImageWithThumbnail(e, restaurantId, uploadCallback, tier)
                          document.getElementById("heroForm").reset();
                        }}
                          style={{ display: "none" }} />
                        <div style={{ textAlign: "center" }}>{t('app:general.upload-image')}</div>
                        <div><CloudUpload /></div>
                      </>
                    }
                  </label>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    </Collapsible>
  </div>
}

function GalleryField({ defaultCheck, images, restaurantId, updateCallback, removeCallback, t, tier }) {
  const [checked, setChecked] = useState(defaultCheck)
  const [uploading, setUploading] = useState(false)
  const [fullscreenImage, setFullscreenImage] = useState("")
  const [fullscreenImageName, setFullscreenImageName] = useState("")

  function successUpload(fileName) {
    setUploading(false)
    updateCallback("gallery_images", fileName)
  }

  return <div className="field-container">
    {fullscreenImage && <FullscreenImage image={fullscreenImage} closeCallback={() => { setFullscreenImage(""); setFullscreenImageName(""); }} deleteCallback={() => { removeCallback('gallery_images', fullscreenImageName); setFullscreenImage(""); setFullscreenImageName(""); }} t={t} />}
    <div className="inline-field title" style={{ position: "absolute", color: "rgba(0,0,0,0)" }}>{t('gallery.title')}</div> {/* Hack to make text non clickable */}
    <Collapsible
      open={checked} /* TODO: not working very well */
      overflowWhenOpen="visible"
      onOpen={() => updateCallback("include_gallery", true)} // Not a big fan of this
      onClose={() => updateCallback("include_gallery", false)}
      trigger={
        <div className="inline-field title">
          <div>{t('gallery.title')}</div>
          <Switch defaultChecked={checked} onClick={() => { const newCheck = !checked; setChecked(newCheck); }} t={t} />
        </div>
      }
    >
      <div className="more-options" style={{ marginTop: "1rem" }}>
        <div className="inline-field" style={{ position: "relative" }}>
          {images.map(image => {
            return <div className="thumbnail">
              <div className="img" style={{
                background: "url('" + encodeURI(`${config.url.PUBLIC}/restaurants/${restaurantId}/images/thumbnail-${image}`) + "') center center / cover no-repeat"
              }}
                onClick={() => {
                  setFullscreenImage(encodeURI(`${config.url.PUBLIC}/restaurants/${restaurantId}/images/${image}`, true))
                  setFullscreenImageName(image)
                }}></div>
            </div>
          })}
          <label className="thumbnail upload">
            {uploading ? <>Loading...</> : <>
              <input multiple type="file" accept="image/*" onChange={(e) => {
                setUploading(true)
                uploadImageWithThumbnail(e, restaurantId, successUpload, tier)
              }}
                style={{ display: "none" }} />
              <div style={{ textAlign: "center" }}>{t('app:general.upload-image')}</div>
              <div><CloudUpload /></div>
            </>}
          </label>
        </div>
      </div>
    </Collapsible>
  </div>
}

function MenuField({ defaultCheck, menus, allMenus, updateCallback, t }) {
  const [checked, setChecked] = useState(defaultCheck)
  const [selectedMenus, setSelectedMenus] = useState(menus ? menus : [])
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? uuidv4() : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <div className="field-container">
    <div className="inline-field title">
      <div>{t('menus.title')}</div>
      <Switch defaultChecked={checked} onClickCallback={() => {
        const newCheck = !checked;
        setChecked(newCheck);
        updateCallback("include_menus", newCheck);

        // if no menu in menus, put all of them
        if (selectedMenus.length === 0) {
          setSelectedMenus([...allMenus])
          updateCallback("menus", allMenus);
        }
      }}
        t={t} />
    </div>
    {checked && <div style={{ marginTop: "1rem" }}>
      <div>{t('menus.included')}</div>
      <div className="field" name="menus">
        {selectedMenus.map((menu, i) => {
          return <div className="inline-field" key={`${menu.id}`}>
            <div className="content">
              <div><input defaultValue={menu.label} placeholder="Menu name" disabled /></div>
            </div>
            <div className="clear-icon-container"
              onClick={() => {
                selectedMenus.splice(i, 1)
                setSelectedMenus([...selectedMenus])
                updateCallback('menus', selectedMenus)
              }}><ClearIcon /></div>
          </div>
        })}
        <div className="inline-field">
          <div className="content">
            <div>
              <div className="tas-button outline" style={{ width: "100%", justifyContent: "center" }}
                onClick={handleClick}><span>{t('menus.add')}</span></div>
            </div>
          </div>
        </div>
        <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }
          }
        >
          <ul style={{ width: "100%", textAlign: "center", margin: "0", padding: "0" }}>
            {allMenus.map(menu => {
              let selectedMenusIds = selectedMenus.map(menu => menu.id)
              if (selectedMenusIds.includes(menu.id)) return // Menu already in list of selected menus
              return <li value={menu.id} onClick={() => {
                const newSelectedMenus = [...selectedMenus, menu]
                setSelectedMenus(newSelectedMenus)
                updateCallback('menus', newSelectedMenus)
              }}>{menu.label}</li>
            })}
          </ul>
        </Popover>
      </div>
    </div>
    }
  </div >
}

function WhatsAppButton({ defaultCheck, defaultNumber, updateCallback, t }) {
  const [checked, setChecked] = useState(defaultCheck)
  const [alreadyMounted, setAlreadyMounted] = useState(false)

  useEffect(() => {
    setAlreadyMounted(true)
  }, [])

  useEffect(() => {
    if (alreadyMounted) updateCallback("include_whatsapp", checked)
  }, [checked])

  return <div className="field-container">
    <div className="inline-field title">
      <div>{t('whatsapp.title')}</div>
      <Switch defaultChecked={checked} onClickCallback={() => setChecked(!checked)} t={t} />
    </div>
    {checked && <div style={{ marginTop: "1rem" }}>
      <TextField
        size="small"
        label={t('whatsapp.desc')}
        placeholder="+123456789"
        onChange={(e) => updateCallback("whatsapp_number", e.target.value)}
        defaultValue={defaultNumber} />
    </div>}
  </div>
}


function ContactFormField({ defaultCheck, updateCallback, isPremium, defaultEmail, t }) {
  const [checked, setChecked] = useState(defaultCheck)
  const [showPremiumInfo, setShowPremiumInfo] = useState(false)

  return <div className="field-container">
    <div className="inline-field title">
      <div className={isPremium ? "" : "locked"}>{t('contact-form.title')}</div>
      <Switch locked={!isPremium} premium={true} defaultChecked={checked} onClickCallback={() => {
        if (isPremium) {
          const newCheck = !checked;
          setChecked(newCheck)
          updateCallback("include_contact_form", newCheck)
        } else {
          setShowPremiumInfo(true)
        }
      }}
        t={t} />
    </div>
    {checked && <div style={{ marginTop: "1rem" }}>
      <div>{t('contact-form.desc')}</div>
      <div>{defaultEmail}</div>
    </div>}
    {showPremiumInfo && <PremiumDisclaimer closeCallback={() => setShowPremiumInfo(false)} />}
  </div>
}

function ReservationField({ defaultCheck, updateCallback, t }) {
  const [checked, setChecked] = useState(defaultCheck)

  return <div className="field-container">
    <div className="inline-field title">
      <div>{t('reservation.title')}</div>
      <Switch defaultChecked={checked} onClickCallback={() => {
        const newCheck = !checked;
        setChecked(newCheck)
        updateCallback("include_reservation", newCheck)
      }}
        t={t} />
    </div>
    {checked && <div style={{ marginTop: "1rem" }}>
      <div>{t('reservation.desc')} <Link to="/reservations/settings">admin.takeaseat.io/reservations/settings</Link> </div>
    </div>}
  </div>
}

function ContactDetails({ defaultCheck, updateCallback, defaultAddress, defaultTelephone, defaultEmail, t }) {
  const [checked, setChecked] = useState(defaultCheck)
  const [address, setAddress] = useState(defaultAddress)
  const [telephone, setTelephone] = useState(defaultTelephone)
  const [email, setEmail] = useState(defaultEmail)

  return <div className="field-container contact-details">
    <div className="inline-field title" style={{ position: "absolute", color: "rgba(0,0,0,0)" }}>{t('contact-details.title')}</div> {/* Hack to make text non clickable */}
    <Collapsible
      open={checked} /* TODO: not working very well */
      overflowWhenOpen="visible"
      onOpen={() => updateCallback("include_contact_details", true)} // Not a big fan of this
      onClose={() => updateCallback("include_contact_details", false)}
      trigger={
        <div className="inline-field title">
          <div>{t('contact-details.title')}</div>
          <Switch defaultChecked={checked} onClickCallback={() => { const newCheck = !checked; setChecked(newCheck); }} t={t} />
        </div>
      }
    >
      <div className="more-options">
        <div className="field">
          <div>{t('app:general.address')}:</div>
          <div className="field">
            <textarea rows="5" defaultValue={address} onChange={(e) => {
              setAddress(e.target.value)
              updateCallback("address", e.target.value)
            }} /></div>
        </div>
        <div className="field">
          <div>{t('app:general.phone')}:</div>
          <div className=""> <input defaultValue={telephone} onChange={(e) => {
            setTelephone(e.target.value)
            updateCallback("telephone", e.target.value)
          }} /></div>
        </div>
        <div className="field">
          <div>{t('app:general.email')}:</div>
          <div className=""> <input defaultValue={email} onChange={(e) => {
            setEmail(e.target.value)
            updateCallback("email", e.target.value)
          }} /></div>
        </div>
        <div>
        </div>
      </div>
    </Collapsible>
  </div>
}

function OpeningHoursField({ defaultCheck, defaultMessage, updateCallback, default_hours_labels, default_hours_values, t }) {
  const [checked, setChecked] = useState(defaultCheck)
  const [message, setMessage] = useState(defaultMessage)
  const [hlabels, setHlabels] = useState(default_hours_labels)
  const [hvalues, setHvalues] = useState(default_hours_values)

  useEffect(() => setMessage(defaultMessage), [defaultMessage])
  useEffect(() => setHlabels(default_hours_labels), [default_hours_labels])
  useEffect(() => setHvalues(default_hours_values), [default_hours_values])

  return <div className="field-container hours">
    <div className="inline-field title" style={{ position: "absolute", color: "rgba(0,0,0,0)" }}>{t('hours.title')}</div> {/* Hack to make text non clickable */}
    <Collapsible
      open={checked} /* TODO: not working very well */
      overflowWhenOpen="visible"
      onOpen={() => updateCallback("include_opening_hours", true)} // Not a big fan of this
      onClose={() => updateCallback("include_opening_hours", false)}
      trigger={
        <div className="inline-field title">
          <div>{t('hours.title')}</div>
          <Switch defaultChecked={checked} onClick={() => { const newCheck = !checked; setChecked(newCheck); }} t={t} />
        </div>
      }
    >
      <div className="more-options">
        <div className="field" name="days">
          {hlabels.map((label, i) => {
            return <div className="inline-field" key={`${i}-${hlabels.length}`}>
              <div className="content">
                <div><input value={label} placeholder={t('app:general.day')} onChange={(e) => {
                  hlabels[i] = e.target.value
                  updateCallback('hours_labels', hlabels)
                }
                } /></div>
                <div><input value={hvalues[i]} placeholder="11:00 - 15:00" onChange={(e) => {
                  hvalues[i] = e.target.value
                  updateCallback('hours_values', hvalues)
                }} /></div>
              </div>
              <div className="clear-icon-container"
                onClick={() => {
                  hvalues.splice(i, 1)
                  hlabels.splice(i, 1)
                  setHlabels([...hlabels])
                  setHvalues([...hvalues])
                  updateCallback('hours_labels', hlabels)
                  updateCallback('hours_values', hvalues)
                }}><ClearIcon /></div>
            </div>
          }
          )}
          <div className="inline-field">
            <div className="content">
              <span>
                <div style={{ padding: "0.25rem" }}>
                  <div className="tas-button outline" style={{ width: "100%", justifyContent: "center" }}
                    onClick={() => {
                      setHlabels([...hlabels, ""])
                      setHvalues([...hvalues, ""])
                    }}><span>{t('hours.add')}</span></div>
                </div>
              </span>
            </div>
            <div className="clear-icon-container"
              style={{ visibility: "hidden" }}
            ><ClearIcon /></div>
          </div>
        </div>
        <div className="field">
          <div>{t('hours.text')}</div>
          <div className="">
            <textarea
              rows="5"
              value={message}
              onChange={(e) => { setMessage(e.target.value); updateCallback("opening_hours_message", e.target.value) }} />
          </div>
        </div>
      </div>
    </Collapsible>
  </div>
}

function MapField({ defaultCheck, updateCallback, defaultAddress, t }) {
  const [checked, setChecked] = useState(defaultCheck)
  const [address, setAddress] = useState(defaultAddress)

  return <div className="field-container">
    <div className="inline-field title">
      <div>{t('map.title')}</div>
      <Switch defaultChecked={checked} onClickCallback={() => {
        const newCheck = !checked;
        setChecked(newCheck);
        updateCallback("include_map", newCheck)
      }}
        t={t} />
    </div>
    {checked && <div style={{ marginTop: "1rem" }}>
      <div>{t('map.desc')}</div>
      <input defaultValue={address} onChange={(e) => { setAddress(e.target.value); updateCallback("address", e.target.value) }} />
    </div>}
  </div>
}

function WaterMark({ defaultCheck, isPremium, updateCallback, t }) {
  const [showPremiumInfo, setShowPremiumInfo] = useState(false)
  const [checked, setCheck] = useState(defaultCheck)

  return <div className="field-container">
    <div className="inline-field title">
      <div className={isPremium ? "" : "locked"}>{t('watermark.title')}</div>
      <Switch locked={!isPremium} premium={true} defaultChecked={checked} onClickCallback={() => {
        if (!isPremium) {
          setShowPremiumInfo(true)
        } else {
          const newCheck = !checked;
          setCheck(newCheck);
          updateCallback("include_watermark", newCheck)
        }
      }}
        t={t} />
    </div>
    {checked && <div style={{ marginTop: "1rem" }}>
      <div>{t('watermark.desc')}</div>
    </div>}
    {showPremiumInfo && <PremiumDisclaimer closeCallback={() => setShowPremiumInfo(false)} />}
  </div>
}

function MessageField({ defaultCheck, defaultMessage, defaultTitle, defaultParagraphs, updateCallback, t }) {
  // TODO: catch if undefined

  const [checked, setChecked] = useState(defaultCheck)
  const [message, setMessage] = useState(defaultMessage)
  const [paragraphs, setParagraphs] = useState(defaultParagraphs ? defaultParagraphs : [])
  const [title, setTitle] = useState(defaultTitle)

  useEffect(() => setMessage(defaultMessage), [defaultMessage])
  useEffect(() => setParagraphs(defaultParagraphs ? defaultParagraphs : []), [defaultParagraphs])
  useEffect(() => setTitle(defaultTitle), [defaultTitle])

  return <div className="field-container message">
    <div className="inline-field title" style={{ position: "absolute", color: "rgba(0,0,0,0)" }}>{t('message.title')}</div> {/* Hack to make text non clickable */}
    <Collapsible
      open={checked} /* TODO: not working very well */
      overflowWhenOpen="visible"
      onOpen={() => updateCallback("include_message", true)} // Not a big fan of this
      onClose={() => updateCallback("include_message", false)}
      trigger={
        <div className="inline-field title">
          <div>{t('message.title')}</div>
          <Switch defaultChecked={checked} onClickCallback={() => { const newCheck = !checked; setChecked(newCheck); }} t={t} />
        </div>
      }
    >
      <div className="more-options">
        <div className="field">
          {/* <div>{t('message.enter-title')}</div> */}
          <TextField
            value={title}
            size="small"
            label="Title"
            onChange={(e) => {
              setTitle(e.target.value)
              updateCallback("message_title", e.target.value)
            }}
          />
          <TextField
            value={message}
            multiline
            size="small"
            label="Content"
            minRows={4}
            onChange={(e) => {
              setMessage(e.target.value)
              updateCallback("message_content", e.target.value)
            }}
          />
        </div>
        {paragraphs?.map((paragraph, i) => <div className='field' key={`${i}-${paragraphs.length}`}>
          <TextField
            value={paragraph.title}
            size="small"
            label="Paragraph title"
            onChange={(e) => {
              paragraph.title = e.target.value
              updateCallback("paragraphs", paragraphs)
            }}
          />
          <TextField
            value={paragraph.content}
            multiline
            size="small"
            label="Content"
            minRows={4}
            onChange={(e) => {
              paragraph.content = e.target.value
              updateCallback("paragraphs", paragraphs)
            }}
          />
          <DeleteIcon
            sx={{ position: "absolute", top: "1.125rem", right: "1.125rem", cursor: "pointer" }}
            onClick={() => {
              setParagraphs(old => {
                old.splice(i, 1)
                return [...old]
              })
              updateCallback("paragraphs", paragraphs)
            }}
          />
        </div>)}
        <br />
        <div
          className="tas-button outline"
          style={{ width: "100%", placeContent: "center", }}
          onClick={() => {
            setParagraphs([...paragraphs, { "title": "", "content": "" }])
          }}><span>Add paragraph</span></div>
      </div>
    </Collapsible>
  </div>
}

const codeToLanguage = {
  "en": "english",
  "ar": "arabic",
  "ku": "kurdish"
  // TODO: add other languages
}

const allLanguages = ["en", "ar", "ku"] // TODO: add more

function LanguageSelectorV2({ defaultLanguages, saveLanguages, changeLanguage }) {
  const [languages, setLanguages] = useState(defaultLanguages || ['en', 'ar', 'ku'])
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0])
  const { t } = useTranslation('app')
  const [componentInitiated, setComponentInitiated] = useState(false)

  useEffect(() => {
    if (defaultLanguages && !componentInitiated) return setComponentInitiated(true)

    // saveLanguages(languages)
    setSelectedLanguage(languages[0])
  }, [languages])

  useEffect(() => {
    if (defaultLanguages) {
      setLanguages(defaultLanguages)
    }
  }, [defaultLanguages])

  useEffect(() => {
    changeLanguage(selectedLanguage)
  }, [selectedLanguage])

  // TODO: save languages
  // TODO: propagage selectedLanguages
  // TODO: Add select label

  return <>
    {/* <FormControl sx={{ width: "8rem" }}>
    <InputLabel id="website-ln-select-label">Website language</InputLabel> */}
    <Select
      size="small"
      id="website-ln-select"
      // label="Website language"
      value={selectedLanguage}
      defaultValue={"en"}
      onChange={(e) => {
        if (languages.includes(e.target.value)) {
          // Remove only on button click. Here we select the language only.
          setSelectedLanguage(e.target.value)
        } else {
          // We add the language
          setLanguages([...languages, e.target.value])
        }
      }}
    >
      {allLanguages.filter(ln => languages.includes(ln)).map(ln => <MenuItem value={ln}>
        <Typography variant="inherit">{t(`edit.${codeToLanguage[ln]}`)}</Typography>
        {/* {ln !== "en" && <ListItemSecondaryAction>
          <IconButton onClick={() => {
            setLanguages([...languages.filter(ele => ele != ln)])
          }}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>} */}
      </MenuItem>)}
      {allLanguages.filter(ln => !languages.includes(ln)).map(ln => <MenuItem value={ln}>
        <Typography variant="inherit">{t(`edit.${codeToLanguage[ln]}`)}</Typography>
        {/* <ListItemSecondaryAction>
          <IconButton >
            <Add />
          </IconButton>
        </ListItemSecondaryAction> */}
      </MenuItem>
      )}
      {/** todo: tranlate */}
      {/** todo: add other languages */}
    </Select>
    {/* </FormControl> */}
  </>
}

class EditView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Load from DB
      include_hero_image: null,
      hero_image: "",
      include_message: null,
      message_title: "",
      message_content: "",
      include_menus: null,
      menus: [],
      include_gallery: null,
      gallery_images: [],
      include_contact_form: null,
      include_opening_hours: null,
      opening_hours_message: "",
      hours_values: [],
      hours_labels: [],
      include_map: null,
      saved: true,
      paragraphs: [],
      mapKey: uuidv4(),
      contactDetailsKey: uuidv4(),
      selectedLanguage: "en",
      //isPremium: this.props.tier !== "standard" // TODO: do method to get if is premium
    }

    this.allMenus = (() => {
      // We convert dict od menus to list of menus for storing into DB more easily
      return Object.keys(props.metadata["menus"]).map(menuKey => {
        let menu = props.metadata["menus"][menuKey]
        menu.id = menuKey
        return menu
      })
    })()
    this.dataToSave = {}
    this.jsonMetadata = this.jsonMetadata.bind(this)
    this.putDataToSave = this.putDataToSave.bind(this)
    this.removeDataToSave = this.removeDataToSave.bind(this)
    this.savePPMetadata = this.savePPMetadata.bind(this)
    this.loadMetadataData = this.loadMetadataData.bind(this)
  }

  jsonMetadata() {
    return { ...this.dataToSave, restaurant_id: this.props.restaurantId } // TODO: specific to one restaurant, languages: ["en", "ar", "ku"]
  }

  putDataToSave(key, value) {
    if (key === "gallery_images") {
      // gallery_images is an array
      if (!Object.keys(this.dataToSave).includes(key)) this.dataToSave[key] = this.state.gallery_images
      this.dataToSave[key].unshift(value)
    } else if (key === "include_contact_details") {
      this.dataToSave["include_contact_details"] = value
      // If address has not been modified from its defualt value, we store the default value again. This is necessary for the first time a restaurant creates its public page
      this.dataToSave["address"] = this.state.address
      this.dataToSave["telephone"] = this.state.telephone
      this.dataToSave["email"] = this.state.email
    } else {
      this.dataToSave[key] = value
    }
    if (this.state.saved) window.gtag('event', 'conversion', { 'send_to': 'AW-642020249/TztwCOvykoUDEJnnkbIC' });

    this.setState({ saved: false, refreshPreview: uuidv4() })
  }

  removeDataToSave(key, value) {
    if (key === "gallery_images") {
      if (!Object.keys(this.dataToSave).includes(key)) this.dataToSave[key] = this.state.gallery_images
      const index = this.dataToSave[key].indexOf(value);
      if (index > -1) {
        this.dataToSave[key].splice(index, 1);
      }
    } else {
      this.dataToSave[key] = value
    }
    this.setState({ saved: false })
  }

  savePPMetadata() {
    if (!this.state.saving) {
      this.setState({ saving: true })

      if (this.state.selectedLanguage === "en") {
        putJsonData(
          `${config.url.API}/public-page/${this.props.restaurantId}/metadata`,
          this.jsonMetadata(),
          this.props.renewCredentials
        ).then(resp => {
          if (resp.status === 200) {
            this.setState({ saved: true, saving: false })
          }
        })
      } else {
        putJsonData(
          `${config.url.API}/public-page/${this.props.restaurantId}/${this.state.selectedLanguage}/metadata`,
          this.jsonMetadata(),
          this.props.renewCredentials
        ).then(resp => {
          if (resp.status === 200) {
            this.setState({ saved: true, saving: false })
          }
        })
      }
    }
  }

  componentDidMount() {
    fetch(`${config.url.PUBLIC}/restaurants/${this.props.restaurantId}/public-page/metadata.json`, {
      method: "GET",
    })
      .then(resp => {
        if (resp.status === 403) return this.props.renewCredentials()

        if (resp.status === 200) resp.json().then(jsonData => {
          if (Object.keys(jsonData).length === 0) return

          this.setState({ ...jsonData, dataLoaded: true })

          // Populate missing values with restaurant information
          loadAddress().then(address => {
            this.registeredAddress = [address.street, address.street_additional, [address.postal_code, address.city, address.country].filter(Boolean).join(" ")].filter(Boolean).join(" ")
            this.setState({
              contactDetailsKey: uuidv4(),
              mapKey: uuidv4(),
              address: jsonData["address"] ? jsonData["address"] : this.registeredAddress
            })
          })

          if (!jsonData["menus"]) {
            this.setState({ menusKey: uuidv4(), menus: this.allMenus })
          } else {
            this.setState({ menusKey: uuidv4(), menus: jsonData["menus"].map(menuStr => JSON.parse(menuStr)) })
          }

          if (!jsonData["telephone"]) {
            loadTelephone().then(telephone => {
              this.setState({ contactDetailsKey: uuidv4(), telephone })
            })
          }

          if (!jsonData["email"]) {
            queryJsonData({
              url: config.url.API + config.endpoints['business-email'],
              verb: "GET"
            }).then(email => {
              this.setState({ contactDetailsKey: uuidv4(), email })
            })
          }
        })
      })
  }

  loadMetadataData() {
    if (this.state.selectedLanguage === "en") {
      fetch(`${config.url.PUBLIC}/restaurants/${this.props.restaurantId}/public-page/metadata.json`, {
        method: "GET",
      })
        .then(resp => {
          if (resp.status === 403) return this.props.renewCredentials()

          if (resp.status === 200) resp.json().then(jsonData => {
            if (Object.keys(jsonData).length === 0) return

            var ppMetadata = jsonData
            delete ppMetadata["languages"]

            this.setState({ ...ppMetadata, dataLoaded: true })
          })
        })
    } else {
      fetch(`${config.url.PUBLIC}/restaurants/${this.props.restaurantId}/public-page/metadata_${this.state.selectedLanguage}.json`, {
        method: "GET",
      })
        .then(resp => {
          if (resp.status === 403) return this.props.renewCredentials()

          if (resp.status === 200) resp.json().then(jsonData => {

            if (Object.keys(jsonData).length === 0) return

            var ppMetadata = jsonData
            delete ppMetadata["languages"]

            this.setState({ ...ppMetadata, dataLoaded: true })
          })
        })
    }
  }

  render() {
    const { t, i18n } = this.props;
    return <div className={"edit-content App"} id="public-page-edit">

      <div className="app-menu">
        <div className="first-row">
          <div>
            <SaveButton key={this.state.saved} saved={this.state.saved} callback={() => this.savePPMetadata()} />
          </div>
          {this.state.saved && <div className="tas-button borderless outline" style={{ marginLeft: "auto", marginRight: "0" }}>
            <span>
              <a href={this.props.customDomain ? `https://${this.props.customDomain}` : config.url.SITES.replace('$1', this.props.metadata.alias || this.props.restaurantId)} target="_blank" style={{ margin: "auto" }}>{t('view')} <OpenInNewIcon /></a>
            </span>
          </div>}
        </div>
      </div>
      <div className="app-content">
        <div style={{ width: "100%", padding: "1rem" }}>
          {this.state.dataLoaded && <>
            {this.props.restaurantId === 'ce47e5c7-ffda-4646-a213-79a10fc94cb1' && <div className="field-container" style={{ gap: "0.5rem", display: "flex", alignItems: "center" }}>
              You are editing the
              <LanguageSelectorV2 defaultLanguages={this.state.languages}
                // saveLanguages={((languages) => {
                //   this.setState({ languages }, () => {
                //     this.putDataToSave("languages", languages)
                //     this.savePPMetadata()
                //   })
                // }).bind(this)} 
                changeLanguage={(language) => this.setState({ selectedLanguage: language, refreshPreview: Math.random() }, this.loadMetadataData)} />
              version.
            </div>}
            <HeroField
              tier={this.props.tier}
              defaultCheck={this.state.include_hero_image}
              defaultImage={this.state.hero_image}
              updateCallback={this.putDataToSave}
              restaurantId={this.props.restaurantId}
              t={t} />
            <MessageField
              defaultCheck={this.state.include_message}
              updateCallback={this.putDataToSave}
              defaultMessage={this.state.message_content}
              defaultParagraphs={this.state.paragraphs}
              defaultTitle={this.state.message_title}
              t={t} />
            <MenuField
              key={this.state.menusKey}
              defaultCheck={this.state.include_menus}
              menus={this.state.menus}
              allMenus={this.allMenus}
              updateCallback={this.putDataToSave}
              t={t} />
            <GalleryField
              tier={this.props.tier}
              restaurantId={this.props.restaurantId}
              images={this.state.gallery_images} defaultCheck={this.state.include_gallery}
              updateCallback={this.putDataToSave}
              removeCallback={this.removeDataToSave}
              t={t} />
            <OpeningHoursField
              defaultCheck={this.state.include_opening_hours}
              defaultMessage={this.state.opening_hours_message}
              default_hours_labels={this.state.hours_labels}
              default_hours_values={this.state.hours_values}
              updateCallback={this.putDataToSave}
              t={t} />
            <ReservationField
              defaultCheck={this.state.include_reservation}
              updateCallback={this.putDataToSave}
              t={t} />
            <ContactFormField
              defaultCheck={this.state.include_contact_form}
              updateCallback={this.putDataToSave}
              isPremium={this.props.tier !== "standard"}
              defaultEmail={this.state.email}
              t={t} />
            <WhatsAppButton
              defaultCheck={this.state.include_whatsapp}
              updateCallback={this.putDataToSave}
              defaultNumber={this.state.whatsapp_number}
              t={t}
            />
            <MapField
              key={`${this.state.mapKey}`} // Necessary because we load address asynchronously
              defaultCheck={this.state.include_map}
              updateCallback={this.putDataToSave}
              defaultAddress={this.state.address}
              t={t} />
            <ContactDetails
              key={`${this.state.contactDetailsKey}`} // Necessary because we load address, phone, email asynchronously
              defaultCheck={this.state.include_contact_details}
              defaultAddress={this.state.address}
              defaultTelephone={this.state.telephone}
              defaultEmail={this.state.email}
              updateCallback={this.putDataToSave}
              t={t} />
            <WaterMark
              key={`${this.props.tier !== "standard"}`} // Necessary because we load address, phone, email asynchronously
              defaultCheck={this.state.include_watermark}
              isPremium={this.props.tier !== "standard"}
              updateCallback={this.putDataToSave}
              t={t} />
          </>}
        </div>
        <div>
          <PreviewView
            restaurantId={this.props.restaurantId}
            language={this.state.selectedLanguage}
            websiteData={this.dataToSave || {}}
            show={this.state.saved}
            refresh={this.state.refreshPreview}
            saveCallback={() => this.savePPMetadata()}
            customDomain={this.props.customDomain}
            alias={this.props.metadata.alias} />
        </div>
      </div>
    </div >
  }
}

const EditViewTranslated = withTranslation(['publicpage', 'app'])(EditView)

export default EditViewTranslated;
