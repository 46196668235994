import React, { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { config } from '../Constants';
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import PricingPage from '../PricingPage'
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import './Disclaimers.css'
import CheckIcon from '@mui/icons-material/Check';
import { GridView, Logout } from '@mui/icons-material';


export function UpgradeModal() {
  // TODO: make not only for gold
  const [showModal, setShowModal] = React.useState(true);

  const handleClose = () => {
    setShowModal(false);
  };

  return <Modal
    open={showModal}
    onClose={handleClose}
    className={"modal-view upgrade"}
  >
    <div className="modal-content">
      <h1><div>🎉</div><div>Congratulations for upgrading to PRO!</div><div>🎉</div></h1>
      <div onClick={() => setShowModal(false)} className="tas-button outline purple"><span>Close</span></div>
    </div>
  </Modal>
}

export function TranslationRequestModal() {
  const [showModal, setShowModal] = React.useState(true);

  const handleClose = () => {
    setShowModal(false);
  };

  return <Modal
    open={showModal}
    onClose={handleClose}
    className={"modal-view upgrade"}
  >
    <div className="modal-content">
      <h1>Translation request received</h1>
      <div>We will come back to your shortly</div>
      <br />
      <br />
      <div onClick={() => setShowModal(false)} className="tas-button outline purple"><span>Close</span></div>
    </div>
  </Modal>
}

export function PricingModalTrigger({ text }) {
  const [modalId, setModalId] = useState(null)
  return <>
    <span onClick={() => setModalId(uuidv4())} style={{ color: "#007bff", cursor: "pointer" }}>
      {text}
    </span>
    <PricingModal key={modalId} defaultShowModal={modalId} />
  </>
}

export function PricingModal({ defaultShowModal, stayOpen, title }) {
  const [showModal, setShowModal] = React.useState(defaultShowModal || false);

  const handleClose = () => {
    if (!stayOpen) setShowModal(false);
  };

  return <Modal
    open={showModal}
    onClose={handleClose}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    className={"modal-view pricing"}
  >
    <div className="tier-plan">
      {(!stayOpen) && <ClearIcon className="clear-icon" onClick={() => setShowModal(false)} />}
      <PricingPage title={title} />
    </div>
  </Modal>
}

export function InvoicesModal({ invoices, onLogout }) {
  const { t } = useTranslation('app')

  return <Modal
    open={true}
    className={"modal-view invoices"}
  >
    <div className='content'>
      <h2>{t('invoices.pending')}</h2>
      <div className='invoices-container'>
        {invoices.map(invoice => <div className='invoice'>
          <div><b>{t('invoices.invoice')} #{invoice.number}</b></div>
          <div>{invoice.amount_remaining / 100} {invoice.currency.toUpperCase()} </div>
          <div>
            <a href={invoice.url}><button className='tas-button'>{t('invoices.pay')}</button></a>
          </div>
        </div>)}
      </div>
      <div style={{ marginTop: "6rem" }}>
        <div onClick={onLogout} style={{ cursor: "pointer" }}><Logout style={{ marginRight: "1rem" }} />{t('sidebar.logout')}</div>
      </div>
    </div>
  </Modal>
}

export function UpgradeButton() {
  const [showLoader, setShowLoader] = React.useState(false)
  const { t } = useTranslation('app')

  return <div
    className="tas-button pro outline"
    style={{ display: "inline-block", position: "relative" }}
    onClick={() => {
      setShowLoader(true)
      fetch(`${config.url.API}/stripe/checkout-session`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify({ plan: "monthly" })
        //body: JSON.stringify({ plan: "yearly" })
        //body: JSON.stringify({ plan: "lifetime" })
      }).then(resp => {
        if (resp.status === 403) {
          this.props.renewCredentials();
        } else if (resp.status === 200) {
          resp.json().then(r => window.location.href = r.url);
        }
      })
      window.gtag('event', 'conversion', { 'send_to': 'AW-642020249/AJ56CLnJ44cDEJnnkbIC' });
      window.gtag('event', 'buy_now');
    }}>
    <span>
      <span style={showLoader ? { visibility: "hidden" } : {}}>{t('identity.plan.upgrade')}</span>
      {showLoader && <div className="loader">Loading...</div>}
    </span>
  </div>
}

export function ComparePlans() {
  const { t } = useTranslation('app')
  const [modaluuid, setModaluuid] = React.useState(null);
  return <>
    <div
      className="tas-button outline borderless"
      onClick={() => setModaluuid(uuidv4())}>
      <span>
        <GridView />
        {t('app:disclaimers.premium.compare')}
      </span>
    </div>
    <PricingModal defaultShowModal={modaluuid} key={modaluuid} />
  </>
}

export function PremiumDisclaimer({ closeCallback }) {
  const { t } = useTranslation('app')

  return <div className="locked-feature">
    <div style={{ width: "100%" }}>
      <div>{t('app:disclaimers.premium.desc')}</div>
      <div style={{ width: "100%", display: "flex", marginTop: "0.5rem" }}>
        <div style={{ textAlign: "center", width: "100%" }}>
          <UpgradeButton />
          <div style={{ width: "1rem", display: "inline-block" }}></div>
          <ComparePlans />
        </div>
      </div>
    </div>
    <div style={{ margin: "auto 0 auto auto", cursor: "pointer" }} onClick={closeCallback}><ClearIcon /></div>
  </div>
}

export function ContactUsDisclaimer({ closeCallback }) {
  // TODO: Rename, this disclaimer is to ask to connect domain
  const { t } = useTranslation('app')

  return <div className="locked-feature">
    <div style={{ width: "100%" }}>
      <div>{t('disclaimers.domain.0')}<Link to="/support/write">{t('disclaimers.domain.1')}</Link>.</div>
    </div>
    <div style={{ margin: "auto 0 auto auto", cursor: "pointer" }} onClick={closeCallback}><ClearIcon /></div>
  </div>
}

export function ProFeatureBadge() {
  const { t } = useTranslation('app')

  return <div className="pro-feature">
    <div>{t('pro-feature')} <CheckIcon /></div>
  </div>
}

export function BetaFeatureBadge() {
  const { t } = useTranslation('app')

  return <div className="beta-feature">
    <div>BETA</div>
  </div>
}
