import React, { Component } from 'react';
import './PricingPage.css';
import { withTranslation, useTranslation } from "react-i18next";
import CheckIcon from '@mui/icons-material/Check';
import { Helmet } from 'react-helmet';
import Collapsible from 'react-collapsible';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { config } from './Constants';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoadingButton from '@mui/lab/LoadingButton'; // TODO: optimize the import


function PriceDisplay({ price, currency, period }) {
    const { t } = useTranslation(['pricing']);

    return <div className="service-tier-price">
        <span className="price">${price}</span>
        {period && <span>/{t(`paid-${period}`)}</span>}
    </div>
}

class PricingPage extends Component {
    constructor(props) {
        super(props);
        this.state = { plan: "monthly", showLoader: false }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event, newPlan) {
        if (newPlan) this.setState({ plan: newPlan });
    };

    render() {
        const { t } = this.props;

        return <>
            <Helmet>
                <title>Take a Seat - Pricing page</title>
                <meta name="description" content="Pricing page of Take a Seat services." />
            </Helmet>
            <div id="pricing" className="page pricing-page">
                <h1 className="title">{this.props.title || t('main-title')}</h1>
                <div className="desc">{t('desc')}</div>
                <div style={{ marginBottom: "4px", textAlign: "center" }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={this.state.plan}
                        exclusive
                        onChange={this.handleChange}
                    >
                        <ToggleButton value="monthly">{t('monthly')}</ToggleButton>
                        <div style={{ margin: "auto 1rem" }}>/</div>
                        <ToggleButton value="yearly">{t('yearly')}&#160;&#160;<b>-50%</b></ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div className="container">
                    <div className={"service-tier starter " + this.state.plan}>
                        <div className="title">{t('ads.title')}</div>
                        <PriceDisplay price={
                            this.state.plan === "monthly" ? 0 :
                                this.state.plan === "yearly" ? 0 : 0
                        } currency="USD" period={''} />
                        {/* <div className="service-tier-desc">7 days free trial</div> */}
                        <div className="highlights">
                            <div><CheckCircleIcon />{t('free.highlights.0')}</div>
                            <div><CheckCircleIcon />{t('free.highlights.1')}</div>
                            <div><CheckCircleIcon />{t('free.highlights.2')}</div>
                            <div><CheckCircleIcon />{t('free.highlights.4')}</div>
                            <div style={{ color: 'var(--tas-primary-color, #2cb052)' }}><CheckCircleIcon />{t('ads.show')}</div>
                            <LoadingButton className='tas' color="inherit" sx={{ fontFamily: "inherit", color: "white", marginTop: "1.5rem" }} loading={this.state.showLoaderA} onClick={() => {
                                // window.gtag('event', 'start_stripe_checkout');
                                this.setState({ showLoaderA: true })
                                fetch(`${config.url.API}/plan`, {
                                    method: 'post',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    credentials: "include",
                                    body: JSON.stringify({ plan: 'ads', period: this.state.plan, update_metadata: true })
                                }).then(resp => {
                                    if (resp.status === 403) {
                                        this.props.renewCredentials();
                                    } else if (resp.status === 200) {
                                        window.location.href = '/business-info'
                                    }
                                })
                            }}>{t('select')}</LoadingButton>
                        </div>
                    </div>
                    <div className={"service-tier starter " + this.state.plan}>
                        <div className="title">{t('free.title')}</div>
                        <PriceDisplay price={
                            this.state.plan === "monthly" ? 8.9 :
                                this.state.plan === "yearly" ? 4.45 : 4.45
                        } currency="USD" period={this.state.plan} />
                        {/* <div className="service-tier-desc">{t('free.desc')}</div> */}
                        <div className="highlights">
                            <div><CheckCircleIcon />{t('free.highlights.0')}</div>
                            <div><CheckCircleIcon />{t('free.highlights.1')}</div>
                            <div><CheckCircleIcon />{t('free.highlights.2')}</div>
                            <div><CheckCircleIcon />{t('free.highlights.3')}</div>
                            <div><CheckCircleIcon />{t('free.highlights.4')}</div>
                        </div>
                        <LoadingButton
                            className='tas'
                            color="inherit"
                            sx={{ fontFamily: "inherit", color: "white", marginTop: "1.5rem" }}
                            loading={this.state.showLoaderS}
                            onClick={() => {
                                window.gtag('event', 'start_stripe_checkout');
                                this.setState({ showLoaderS: true })
                                fetch(`${config.url.API}/stripe/checkout-session`, {
                                    method: 'post',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    credentials: "include",
                                    body: JSON.stringify({ plan: this.state.plan, tier: "standard" })
                                }).then(resp => {
                                    if (resp.status === 403) {
                                        this.props.renewCredentials();
                                    } else if (resp.status === 200) {
                                        resp.json().then(r => window.location.href = r.url);
                                    }
                                })
                                window.gtag('event', 'conversion', { 'send_to': 'AW-642020249/AJ56CLnJ44cDEJnnkbIC' });
                                window.gtag('event', 'buy_now');
                            }}>
                            {t('buy')}
                        </LoadingButton>
                        <Collapsible
                            className="s-only"
                            triggerWhenOpen={<div className="trigger open"><RemoveIcon /> <b>{t('plan-features')}</b></div>}
                            trigger={<div className="trigger"><AddIcon /> <b>{t('plan-features')}</b></div>}>
                            <table className="pricing-table">
                                <tbody>
                                    <tr><th colSpan="2">{t('free.features.menus.title')}</th></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.0')}</th><td>{t('unlimited')}</td></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.1')}</th><td>{t('unlimited')}</td></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.2')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.3')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.4')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.5')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.6')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.7')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.8')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('premium.features.website.features.2')}</th><td></td></tr>
                                    <tr><th scope="row">{t('premium.features.website.features.0')}</th><td></td></tr>
                                    <tr><th scope="row">{t('premium.features.website.features.3')}</th><td>{t('free.features.translation')}</td></tr>
                                    <tr><th scope="row">{t('premium.features.website.features.4')}</th><td></td></tr>
                                    <tr><th colSpan="2">{t('free.features.website.title')}</th></tr>
                                    <tr><th scope="row">{t('free.features.website.features.0')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.website.features.1')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.website.features.2')}</th><td>{t('unlimited')}</td></tr>
                                    <tr><th scope="row">{t('free.features.website.features.3')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.website.features.4')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.website.features.5')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('premium.features.website.features.2')}</th><td></td></tr>
                                    <tr><th scope="row">{t('premium.features.website.features.0')}</th><td></td></tr>
                                    <tr><th scope="row">{t('premium.features.website.features.1')}</th><td></td></tr>
                                    <tr><th colSpan="2">{t('free.features.reservations.title')} <span style={{ color: "#f7778e" }}> New</span></th></tr>
                                    <tr><th scope="row">{t('free.features.reservations.features.0')}</th><td>25</td></tr>
                                    <tr><th scope="row">{t('free.features.reservations.features.1')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.reservations.features.2')}</th><td><CheckIcon /></td></tr>
                                    <tr><th colSpan="2">{t('free.features.contact-tracing.title')}</th></tr>
                                    <tr><th scope="row">{t('free.features.contact-tracing.features.0')}</th><td><CheckIcon /></td></tr>
                                    <tr><th colSpan="2">{t('free.features.analytics.title')}</th></tr>
                                    <tr><th scope="row">{t('free.features.analytics.features.0')}</th><td><CheckIcon /></td></tr>
                                    <tr><th colSpan="2">{t('free.features.support.title')}</th></tr>
                                    <tr><th scope="row">{t('free.features.support.medium')}</th><td><CheckIcon /></td></tr>
                                    {/* <tr><th scope="row">{t('free.features.support.whatsapp')}</th><td></td></tr> */}
                                    <tr><th scope="row">{t('free.features.support.priority')}</th><td></td></tr>
                                    {/* <tr><th scope="row">{t('free.features.support.manager')}</th><td></td></tr> */}
                                </tbody>
                            </table>
                        </Collapsible>
                    </div>
                    <div className={"service-tier premium " + this.state.plan}>
                        <div className="title">{t('premium.title')}</div>
                        <PriceDisplay price={
                            this.state.plan === "monthly" ? 29.9 :
                                this.state.plan === "yearly" ? 14.95 : 14.95
                        } currency="USD" period={this.state.plan} />
                        {/* <div className="service-tier-desc">{t('premium.desc')}</div> */}
                        <div className="highlights">
                            <div><CheckCircleIcon />{t('premium.highlights.0')}</div>
                            <div><CheckCircleIcon />{t('premium.highlights.3')}</div>
                            <div><CheckCircleIcon />{t('premium.highlights.4')}</div>
                            <div><CheckCircleIcon />{t('premium.highlights.1')}</div>
                            <div><CheckCircleIcon />{t('premium.highlights.2')}</div>
                        </div>
                        <LoadingButton
                            className='tas pro'
                            color="inherit"
                            sx={{ fontFamily: "inherit", color: "white", marginTop: "1.5rem" }}
                            loading={this.state.showLoaderP}
                            onClick={() => {
                                window.gtag('event', 'start_stripe_checkout');
                                this.setState({ showLoaderP: true })
                                fetch(`${config.url.API}/stripe/checkout-session`, {
                                    method: 'post',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    credentials: "include",
                                    body: JSON.stringify({ plan: this.state.plan })
                                }).then(resp => {
                                    if (resp.status === 403) {
                                        this.props.renewCredentials();
                                    } else if (resp.status === 200) {
                                        resp.json().then(r => window.location.href = r.url);
                                    }
                                })
                                window.gtag('event', 'conversion', { 'send_to': 'AW-642020249/AJ56CLnJ44cDEJnnkbIC' });
                                window.gtag('event', 'buy_now');
                            }}>
                            {t('buy')}
                        </LoadingButton>
                        <Collapsible
                            className="s-only"
                            triggerWhenOpen={<div className="trigger open"><RemoveIcon /> <b>{t('plan-features')}</b></div>}
                            trigger={<div className="trigger"><AddIcon /> <b>{t('plan-features')}</b></div>}>
                            <table className="pricing-table">
                                <tbody>
                                    <tr><th colSpan="2">{t('free.features.menus.title')}</th></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.0')}</th><td>{t('unlimited')}</td></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.1')}</th><td>{t('unlimited')}</td></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.2')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.3')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.4')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.5')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.6')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.7')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.menus.features.8')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('premium.features.website.features.2')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('premium.features.website.features.0')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('premium.features.website.features.3')}</th><td>{t('premium.features.translation')}</td></tr>
                                    <tr><th scope="row">{t('premium.features.website.features.4')}</th><td><CheckIcon /></td></tr>
                                    <tr><th colSpan="2">{t('free.features.website.title')}</th></tr>
                                    <tr><th scope="row">{t('free.features.website.features.0')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.website.features.1')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.website.features.2')}</th><td>{t('unlimited')}</td></tr>
                                    <tr><th scope="row">{t('free.features.website.features.3')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.website.features.4')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.website.features.5')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('premium.features.website.features.2')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('premium.features.website.features.0')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('premium.features.website.features.1')}</th><td><CheckIcon /></td></tr>
                                    <tr><th colSpan="2">{t('free.features.reservations.title')} <span style={{ color: "#f7778e" }}> New</span></th></tr>
                                    <tr><th scope="row">{t('free.features.reservations.features.0')}</th><td>{t('unlimited')}</td></tr>
                                    <tr><th scope="row">{t('free.features.reservations.features.1')}</th><td><CheckIcon /></td></tr>
                                    <tr><th scope="row">{t('free.features.reservations.features.2')}</th><td><CheckIcon /></td></tr>
                                    <tr><th colSpan="2">{t('free.features.contact-tracing.title')}</th></tr>
                                    <tr><th scope="row">{t('free.features.contact-tracing.features.0')}</th><td><CheckIcon /></td></tr>
                                    <tr><th colSpan="2">{t('free.features.analytics.title')}</th></tr>
                                    <tr><th scope="row">{t('free.features.analytics.features.0')}</th><td><CheckIcon /></td></tr>
                                    <tr><th colSpan="2">{t('free.features.support.title')}</th></tr>
                                    <tr><th scope="row">{t('free.features.support.medium')}</th><td><CheckIcon /></td></tr>
                                    {/* <tr><th scope="row">{t('free.features.support.whatsapp')}</th><td><CheckIcon /></td></tr> */}
                                    <tr><th scope="row">{t('free.features.support.priority')}</th><td><CheckIcon /></td></tr>
                                    {/* <tr><th scope="row">{t('free.features.support.manager')}</th><td><CheckIcon /></td></tr> */}
                                </tbody>
                            </table>
                        </Collapsible>
                    </div>
                </div>
                <div className="md-plus-only">
                    <table className="pricing-table">
                        <thead>
                            <tr>
                                <th style={{ border: "0px" }}></th>
                                <th>
                                    <div className="title">{t('premium.title')}</div>
                                </th>
                                <th>
                                    <div className="title">{t('free.title')} & {t('ads.title')}</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><th colSpan="3">{t('free.features.menus.title')}</th></tr>
                            <tr><th scope="row">{t('free.features.menus.features.0')}</th><td>{t('unlimited')}</td><td>{t('unlimited')}</td></tr>
                            <tr><th scope="row">{t('free.features.menus.features.1')}</th><td>{t('unlimited')}</td><td>{t('unlimited')}</td></tr>
                            <tr><th scope="row">{t('free.features.menus.features.2')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                            <tr><th scope="row">{t('free.features.menus.features.3')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                            <tr><th scope="row">{t('free.features.menus.features.4')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                            <tr><th scope="row">{t('free.features.menus.features.5')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                            <tr><th scope="row">{t('free.features.menus.features.6')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                            <tr><th scope="row">{t('free.features.menus.features.7')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                            <tr><th scope="row">{t('free.features.menus.features.8')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                            <tr><th scope="row">{t('premium.features.website.features.2')}</th><td><CheckIcon /></td><td></td></tr>
                            <tr><th scope="row">{t('premium.features.website.features.0')}</th><td><CheckIcon /></td><td></td></tr>
                            <tr><th scope="row">{t('premium.features.website.features.3')}</th><td>{t('premium.features.translation')}</td><td>{t('free.features.translation')}</td></tr>
                            <tr><th scope="row">{t('premium.features.website.features.4')}</th><td><CheckIcon /></td><td></td></tr>
                            <tr><th colSpan="3">{t('free.features.website.title')}</th></tr>
                            <tr><th scope="row">{t('free.features.website.features.0')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                            <tr><th scope="row">{t('free.features.website.features.1')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                            <tr><th scope="row">{t('free.features.website.features.2')}</th><td>{t('unlimited')}</td><td>{t('unlimited')}</td></tr>
                            <tr><th scope="row">{t('free.features.website.features.3')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                            <tr><th scope="row">{t('free.features.website.features.4')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                            <tr><th scope="row">{t('free.features.website.features.5')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                            <tr><th scope="row">{t('premium.features.website.features.2')}</th><td><CheckIcon /></td><td></td></tr>
                            <tr><th scope="row">{t('premium.features.website.features.0')}</th><td><CheckIcon /></td><td></td></tr>
                            <tr><th scope="row">{t('premium.features.website.features.1')}</th><td><CheckIcon /></td><td></td></tr>
                            <tr><th colSpan="3">{t('free.features.reservations.title')} <span style={{ color: "#f7778e" }}> New</span></th></tr>
                            <tr><th scope="row">{t('free.features.reservations.features.0')}</th><td>{t('unlimited')}</td><td>25</td></tr>
                            <tr><th scope="row">{t('free.features.reservations.features.1')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                            <tr><th scope="row">{t('free.features.reservations.features.2')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                            <tr><th colSpan="3">{t('free.features.contact-tracing.title')}</th></tr>
                            <tr><th scope="row">{t('free.features.contact-tracing.features.0')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                            <tr><th colSpan="3">{t('free.features.analytics.title')}</th></tr>
                            <tr><th scope="row">{t('free.features.analytics.features.0')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                            <tr><th colSpan="3">{t('free.features.support.title')}</th></tr>
                            <tr><th scope="row">{t('free.features.support.medium')}</th><td><CheckIcon /></td><td><CheckIcon /></td></tr>
                            {/* <tr><th scope="row">{t('free.features.support.whatsapp')}</th><td><CheckIcon /></td><td></td></tr> */}
                            <tr><th scope="row">{t('free.features.support.priority')}</th><td><CheckIcon /></td><td></td></tr>
                            {/* <tr><th scope="row">{t('free.features.support.manager')}</th><td><CheckIcon /></td><td></td></tr> */}
                        </tbody>
                    </table>
                </div>
            </div >
        </>
    }
}

const PricingPageTranslated = withTranslation('pricing')(PricingPage)
export default PricingPageTranslated;
