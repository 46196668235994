import { config } from '../Constants'
import { queryJsonData } from './MenuUtils'


export function bestLanguage(autoLanguage = true, selectedLanguage = null, supportedLanguages = ["en"]) {
    if (supportedLanguages.includes(selectedLanguage)) return selectedLanguage;
    if (autoLanguage & supportedLanguages.includes(browserLanguage())) return browserLanguage();
    return "en";
}

export function browserLanguage() {
    if ("language" in navigator && navigator.language !== "") {
        // The navigator.language returns locales including the country (en-US for example).
        // It's probably overkill to provide localization per country, so we are only interested
        // in the language part.
        return navigator.language.substr(0, 2);
    } else {
        return "en";
    }
}

export function changeLanguagePreference(lang, renewCredentials) {
    queryJsonData({
        url: config.url.API + config.endpoints['business-language'],
        verb: "PATCH",
        body: { lang },
        renewCredentials
    })
}