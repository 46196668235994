import { ArrowForward, Close, East } from '@mui/icons-material';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { config } from '../Constants';
import { get, set } from 'lodash'; // optimize import


export default function EditPrices({ menuInfo, callback, cancel, moreThanOneLanguage }) {
    const [increasePercentage, setIncreasePercentage] = useState(0)
    const [decreasePercentage, setDecreasePercentage] = useState(0)
    const [changing, setChanging] = useState(false)
    const { t } = useTranslation('app');

    return <div className="fullscreen"> {/** TODO: check how it looks like */}
        <div className="content">
            <div className="tas-title">{t('change-all-prices')}</div>
            <div>
                <div style={{
                    display: "flex"
                }}>
                    <div>{t('increase-all-prices')}</div>
                    <div style={{
                        display: "flex"
                    }}>
                        <input name={t('increase-perc')} placeholder="0" min="0" type="number" style={{ textAlign: "center", width: "3rem" }} onChange={(e) => setIncreasePercentage(parseFloat(e.target.value))} /> %
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    margin: "1rem 0"
                }}>
                    <div>{t('decrease-all-prices')}</div>
                    <div style={{
                        display: "flex"
                    }}>
                        <input name={t('decrease-perc')} min="0" placeholder="0" type="number" style={{ textAlign: "center", width: "3rem" }} onChange={(e) => setDecreasePercentage(parseFloat(e.target.value))} /> %
                    </div>
                </div>
            </div>
            <div
                onClick={() => {
                    setChanging(true)
                    callback(increasePercentage, decreasePercentage)
                }}
                className="tas-button"
                style={{ width: "100%", marginTop: "1rem" }}>
                {changing ? <>{t('changing')}...</> : <span>{t('change-prices')}</span>}
            </div>
            {moreThanOneLanguage && <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>{t('change-note.0')}<b>{menuInfo.name}</b> / <b>{menuInfo.lang}</b>. {t('change-note.1')}</div>}
            <div style={{
                fontSize: "80%",
                margin: "1rem auto 0 auto",
                color: "grey",
                cursor: "pointer"
            }}
                onClick={(e) => {
                    e.preventDefault()
                    cancel()
                }}>{t('edit.cancel')}</div>
        </div>
    </div>
}

function SyncImages({ fromLn, toLn, selectedMenu, selectedMenuData, restaurantId }) {
    const [syncing, setSyncing] = useState(false)
    const [syncStatus, setSyncStatus] = useState(undefined)
    const [failed, setFailed] = useState([])
    const { t } = useTranslation('app');

    async function sync() {
        if (syncing) return

        setSyncing(true)
        setSyncStatus(undefined)

        var failedNew = []

        const url = `${config.url.API}/menu/${restaurantId}/${selectedMenu}/${toLn}`
        const resp = await fetch(url, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            signal: AbortSignal.timeout(10000),
        })

        setSyncing(false)

        if (resp.status === 403) {
            return setSyncStatus("Error")
        } else if (resp.status === 404) {
            return setSyncStatus("Error")
        }

        const data = await resp.json()
        var menuData = data.menuData

        selectedMenuData.forEach((section, sectionIndex) => {
            const sectionPath = `${sectionIndex}`
            const sectionName = section.name || section.id
            const dataSection = get(menuData, sectionPath)

            if (dataSection?.id === section.id) {
                if (section.images) {
                    set(menuData, sectionPath + '.images', section.images)
                } else {
                    set(menuData, sectionPath + '.images', [])
                }

                section.items?.forEach((item, itemIndex) => {
                    const itemPath = sectionPath + `.items[${itemIndex}]`
                    const dataItem = get(menuData, itemPath)

                    if (dataItem?.id === item.id) {
                        if (item.images) {
                            set(menuData, itemPath + '.images', item.images)
                        } else {
                            set(menuData, itemPath + '.images', [])
                        }
                    } else {
                        failedNew.push({ type: "item", name: sectionName + " / " + (item.name || item.id) })
                    }
                })

                section.subsections?.forEach((subsection, subsectionIndex) => {
                    const subsectionPath = sectionPath + `.subsections[${subsectionIndex}]`
                    const subsectionName = subsection.name || subsection.id
                    const subsectionData = get(menuData, subsectionPath)

                    if (subsectionData?.id === subsection.id) {

                        if (subsection.images) {
                            set(menuData, subsectionPath + '.images', subsection.images)
                        } else {
                            set(menuData, subsectionPath + '.images', [])
                        }

                        subsection.items?.forEach((item, itemIndex) => {
                            const itemPath = subsectionPath + `.items[${itemIndex}]`
                            const dataItem = get(menuData, itemPath)

                            if (dataItem?.id === item.id) {
                                if (item.images) {
                                    set(menuData, itemPath + '.images', item.images)
                                } else {
                                    set(menuData, itemPath + '.images', [])
                                }
                            } else {
                                failedNew.push({ type: "item", name: sectionName + " / " + subsectionName + " / " + (item.name || item.id) })
                            }
                        })
                    } else {
                        failedNew.push({ type: "subsection", name: sectionName + " / " + subsectionName })
                    }
                })
            } else {
                failedNew.push({ type: "section", name: sectionName })
            }
        })

        setFailed([...failedNew])
        const saveResponse = await fetch(`${config.url.API}/menu/${restaurantId}/${selectedMenu}/${toLn}`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                menu: JSON.stringify(menuData),
                originalVersion: 0,
                method: "overwrite"
            })
        })

        if (saveResponse.status === 200) {
            setSyncStatus(t('edit.saved'))
        }
    }

    return <>
        <div style={{ marginBottom: "1rem", display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <div>Sync <em style={{ textTransform: "uppercase" }}>{fromLn}</em><ArrowForward /> <em style={{ textTransform: "uppercase" }}>{toLn}</em></div>
            {syncing ? <div>{t('changing')}...</div> : <div className='tas-button' onClick={sync}>Sync</div>}
            {syncStatus === "Success" && <div style={{ color: "green" }}>Success</div>}
            {syncStatus === "Error" && <div style={{ color: "red" }}>Error</div>}
        </div>
        {failed.length > 0 && <div style={{ border: "1px solid #F17800", padding: "0.5rem", marginBottom: "1rem" }}>
            <div style={{ color: "#F17800" }}><b>{t("Warning")}</b></div>
            <div>{t("failed-desc")}</div>
            <ul>{failed.map(failedEle => <li>
                {`${failedEle.type}: ${failedEle.name}`}
            </li>)}</ul>
        </div>}
    </>
}

function SyncPrice({ fromLn, toLn, selectedMenu, selectedMenuData, restaurantId }) {
    const [syncing, setSyncing] = useState(false)
    const [syncStatus, setSyncStatus] = useState(undefined)
    const [failed, setFailed] = useState([])
    const { t } = useTranslation('app');

    async function sync() {
        if (syncing) return

        setSyncing(true)
        setSyncStatus(undefined)
        var failedNew = []

        const url = `${config.url.API}/menu/${restaurantId}/${selectedMenu}/${toLn}`
        const resp = await fetch(url, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            signal: AbortSignal.timeout(10000),
        })

        setSyncing(false)

        if (resp.status === 403) {
            // if (renewCredentials) renewCredentials()
            return setSyncStatus("Error")
        } else if (resp.status === 404) {
            return setSyncStatus("Error")
        }

        const data = await resp.json()
        var menuData = data.menuData

        selectedMenuData.forEach((section, sectionIndex) => {
            const sectionPath = `${sectionIndex}`
            const sectionName = section.name || section.id
            const dataSection = get(menuData, sectionPath)

            if (dataSection?.id === section.id) {
                section.items?.forEach((item, itemIndex) => {
                    const itemPath = sectionPath + `.items[${itemIndex}]`
                    const itemName = item.name || item.id
                    const dataItem = get(menuData, itemPath)

                    if (dataItem?.id === item.id) {
                        if (item.price) {
                            set(menuData, itemPath + '.price', item.price)
                        } else {
                            set(menuData, itemPath + '.price', [])
                        }
                    } else {
                        failedNew.push({ type: "item", name: sectionName + " / " + itemName })
                    }
                })

                section.subsections?.forEach((subsection, subsectionIndex) => {
                    const subsectionPath = sectionPath + `.subsections[${subsectionIndex}]`
                    const subsectionName = subsection.name || subsection.id
                    const subsectionData = get(menuData, subsectionPath)

                    if (subsectionData?.id === subsection.id) {
                        subsection.items?.forEach((item, itemIndex) => {
                            const itemPath = subsectionPath + `.items[${itemIndex}]`
                            const itemName = item.name || item.id
                            const dataItem = get(menuData, itemPath)

                            if (dataItem?.id === item.id) {
                                if (item.price) {
                                    set(menuData, itemPath + '.price', item.price)
                                } else {
                                    set(menuData, itemPath + '.price', [])
                                }
                            } else {
                                failedNew.push({ type: "item", name: sectionName + " / " + subsectionName + " / " + itemName })
                            }
                        })
                    } else {
                        failedNew.push({ type: "subsection", name: sectionName + " / " + subsectionName })
                    }
                })
            } else {
                failedNew.push({ type: "section", name: sectionName })
            }
        })

        setFailed([...failedNew])

        const saveResponse = await fetch(`${config.url.API}/menu/${restaurantId}/${selectedMenu}/${toLn}`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({
                menu: JSON.stringify(menuData),
                originalVersion: 0,
                method: "overwrite"
            })
        })

        if (saveResponse.status === 200) {
            setSyncStatus(t('edit.saved'))
        }
    }

    return <>
        <div style={{ marginBottom: "1rem", display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <div>Sync <em style={{ textTransform: "uppercase" }}>{fromLn}</em> <ArrowForward /> <em style={{ textTransform: "uppercase" }}>{toLn}</em></div>
            {syncing ? <div>{t('changing')}...</div> : <div className='tas-button' onClick={sync}>Sync</div>}
            {syncStatus === "Success" && <div style={{ color: "green" }}>Success</div>}
            {syncStatus === "Error" && <div style={{ color: "red" }}>Error</div>}
        </div>
        {failed.length > 0 && <div style={{ border: "1px solid #F17800", padding: "0.5rem", marginBottom: "1rem" }}>
            <div style={{ color: "#F17800" }}><b>{t("Warning")}</b></div>
            <div>{t("failed-desc")}</div>
            <ul>{failed.map(failedEle => <li>
                {`${failedEle.type}: ${failedEle.name}`}
            </li>)}</ul>
        </div>}
    </>
}

export function SyncLanguages({ selectedLanguage, selectedMenu, selectedMenuLabel, allLanguages, selectedMenuData, close, restaurantId }) {
    const { t } = useTranslation('app');


    return <div className="fullscreen"> {/** TODO: check how it looks like */}
        <div className="content">
            <div style={{ position: "absolute", right: "1rem", cursor: "pointer" }} onClick={close}><Close /></div>
            <div className="tas-title">{t('sync-languages')} - {selectedMenuLabel}</div>
            <div>{t('sync-desc')}</div>
            <p><b>{t('Warning')}:</b>{" "}{t('sync-warning')}</p>
            <h2>{t('sync-prices')}</h2>
            {allLanguages.map(ln => {
                if (ln.code === selectedLanguage) return <></>

                return <SyncPrice fromLn={selectedLanguage} toLn={ln.code} selectedMenu={selectedMenu} selectedMenuData={selectedMenuData} restaurantId={restaurantId} />
            })}
            <br />
            <h2>{t('sync-images')}</h2>
            {allLanguages.map(ln => {
                if (ln.code === selectedLanguage) return <></>

                return <SyncImages fromLn={selectedLanguage} toLn={ln.code} selectedMenu={selectedMenu} selectedMenuData={selectedMenuData} restaurantId={restaurantId} />
            })}
        </div>
    </div>
}