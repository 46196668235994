import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
// var QRCode = require('qrcode.react');
import { QRCodeSVG } from 'qrcode.react';


class QRcodeCanvas extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundSet: false,
            topImageDrawn: false,
            canvasSent: false,
            withCountour: (props.contourColor !== null),
        }

        this.w = (this.props.width) ? this.props.width : 300
        this.h = (this.props.height) ? this.props.height : 300

        this.qrcodeW = this.state.withCountour ? 250 : 300
        this.qrcodeH = this.state.withCountour ? 250 : 300

        this.imageW = (this.props.topFigure) ? this.w / 1.55 : 0  // TODO: rename to topImageW
        this.imageH = (this.props.topFigure) ? this.h / 1.55 : 0  // TODO: same

        this.canvasW = this.w
        this.canvasH = this.imageW + this.h + ((props.text || props.tableNumber) ? 75 : 0) // TODO: Why imageW?
    }

    drawImage(image, dx, dy, dWidth, dHeight) {
        const can = findDOMNode(this.canvasRef);
        const ctx = can.getContext("2d");
        ctx.drawImage(image, dx, dy, dWidth, dHeight);
        this.props.callback(can);
    }

    drawRoundRect(x, y, w, h, r, ctx, linewidth, linecolor) {
        if (w < 2 * r) r = w / 2;
        if (h < 2 * r) r = h / 2;
        ctx.beginPath();

        ctx.lineWidth = linewidth;
        ctx.strokeStyle = linecolor;

        ctx.moveTo(x + r, y);
        ctx.arcTo(x + w, y, x + w, y + h, r);
        ctx.arcTo(x + w, y + h, x, y + h, r);
        ctx.arcTo(x, y + h, x, y, r);
        ctx.arcTo(x, y, x + w, y, r);
        ctx.closePath();

        ctx.stroke()
    }

    componentDidMount() {
        const can = findDOMNode(this.canvasRef);
        const ctx = can.getContext("2d");
        ctx.fillStyle = this.props.bgColor ? this.props.bgColor : "white";
        ctx.fillRect(0, 0, can.width, can.height);

        if (this.props.text && !this.props.tableNumber) {
            ctx.font = "25px Montserrat";
            ctx.fillStyle = this.props.fgColor;
            ctx.textAlign = "center";
            ctx.fillText(this.props.text, 300 / 2, can.height - 37);
        }

        if (this.props.tableNumber) {
            ctx.font = "25px Montserrat";
            ctx.fillStyle = this.props.fgColor;
            ctx.textAlign = "center";
            ctx.fillText(`Table ${this.props.tableNumber}`, 300 / 2, can.height - 37);
        }

        this.setState({ backgroundSet: true })
    }

    componentDidUpdate() {

        const svgQRCode = document.getElementById(`${this.props.id}-dynamic-QR-Code`);

        const can = findDOMNode(this.canvasRef);

        if (svgQRCode.style.display !== "none") {

            if (this.props.pattern === "dot") {
                const defsNode = document.createElement("defs")
                const patternNode = document.createElement("pattern")
                patternNode.setAttribute('id', 'pattern1')
                patternNode.setAttribute('x', '1')
                patternNode.setAttribute('y', '1')
                patternNode.setAttribute('width', '1')
                patternNode.setAttribute('height', '1')
                patternNode.setAttribute('patternUnits', 'userSpaceOnUse')

                const circleNode = document.createElement("circle")
                circleNode.setAttribute('cx', '0.5')
                circleNode.setAttribute('cy', '0.5')
                circleNode.setAttribute('r', '0.45')
                circleNode.setAttribute('stroke', 'none')
                circleNode.setAttribute('fill', this.props.fgColor)

                patternNode.appendChild(circleNode)
                defsNode.appendChild(patternNode)

                svgQRCode.appendChild(defsNode)
                const childrenNodes = svgQRCode.childNodes
                childrenNodes[1].setAttribute('fill', `url(#pattern1)`)

                // Redraw the SVG
                svgQRCode.innerHTML += ""
            }

            const ctx = can.getContext("2d")
            var svgURL = new XMLSerializer().serializeToString(svgQRCode)

            let image = new Image();
            image.onload = () => {
                const dx = this.state.withCountour ? (300 - 250) / 2 : 0 // TODO: compute
                ctx.drawImage(image, dx, this.imageW + dx); // TODO: why W?

                if (this.state.withCountour) {
                    this.drawRoundRect(dx * 2 / 3, this.imageW + dx * 2 / 3, 250 + dx * 2 / 3, 250 + dx * 2 / 3, 45, ctx, 15, this.props.contourColor)
                }

                this.props.callback(can);

                if (this.props.middleFigure) {
                    let middleImage = new Image();
                    middleImage.onload = () => {
                        const middledx = this.state.withCountour ? (300 - 250) / 2 : 0 // TODO: compute
                        ctx.drawImage(middleImage, middledx + 127, 127 + middledx + this.imageW, 46, 46); // TODO

                        this.props.callback(can);
                    }
                    middleImage.crossOrigin = "anonymous"
                    middleImage.src = this.props.middleFigure;
                };
            }

            image.src = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svgURL);
            svgQRCode.style.display = 'none'; // For firefox to display the SVG
        }
    }

    // Load metadata
    render() {
        return (<>
            <canvas
                id={this.props.id}
                ref={(canvas) => { this.canvasRef = canvas; }}
                width={this.canvasW}
                height={this.canvasH}
            // style={{ border: "1px solid lightgrey", borderRadius: "4px" }}
            >canvas not supported</canvas>
            {/* TODO: merge in one (no need for backend svg) */}
            {(this.state.backgroundSet) && <>
                <QRCodeSVG
                    size={this.qrcodeW}
                    includeMargin={true}
                    level="L"
                    renderAs="svg"
                    id={`${this.props.id}-dynamic-QR-Code`}
                    bgColor={(this.props.bgColor ? this.props.bgColor : "#ffffff")}
                    fgColor={(this.props.fgColor ? this.props.fgColor : "#000000")}
                    imageSettings={{
                        // src: "https://takeaseat.io/static/media/icons/logo-220929.svg",
                        height: 46,
                        width: 46,
                        excavate: this.props.excavate ? true : false // To have a clean crop. Unfortunately, src will not work in our case
                    }}
                    value={this.props.qrCodeUrl} />
                {(this.props.topFigure) && <img
                    alt="Your logo"
                    crossOrigin="anonymous"
                    src={this.props.topFigure}
                    width={this.w} 
                    height={this.h}
                    onLoad={(e) => {
                        var width_height_ratio = e.target.naturalWidth / e.target.naturalHeight;

                        if (width_height_ratio > 1) {
                            this.drawImage(e.target, (this.w - this.imageH) / 2, (this.imageW - this.imageH / width_height_ratio) / 2, this.imageH, this.imageH / width_height_ratio)
                        } else {
                            this.drawImage(e.target, (this.w - this.imageH * width_height_ratio) / 2, 15, this.imageH * width_height_ratio, this.imageH)
                        }
                    }}
                    style={{ display: "none" }} />
                }
            </>}
        </>)
    }
}

export default QRcodeCanvas;